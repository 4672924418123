import React from 'react';
import styles from './HeaderSelection.css'

type iProps = {
    item: any;
    urlImg: any;
}


function HeaderSelection({item, urlImg}: iProps) {

    return (
        <div className={styles.imageSlider} style={{overflow: 'hidden', width: '100%', height: 'calc(0.1646 * 100vw)', maxHeight: '100%', position: 'relative'}}>
            <div style={{
                verticalAlign: 'top',
                height: '100%',
                backgroundImage: "url(" + urlImg + ")",
                backgroundSize: 'cover',
                display: 'flex'
            }}>
                <div className={styles.headerSelectionsContents}>
                    <div className={styles.headerSelectionsTop} style={{maxWidth: '1440px'}}>
                        <div>
                            <span>{item.name}</span>
                        </div>
                        <div style={{}} className={styles.headerSelectionsTop + ' ' + styles.description}>
                        <span>
                            {item.description}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderSelection;
