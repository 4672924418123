import React from 'react';
import {
  CarouselContentCards,
  Headband,
  PageHeader,
} from "../index";

type OffersPageProps = {
  contents: any;
  category: any;
};

function OffersPage({contents, category}: OffersPageProps) {



  return (
    <>
      <PageHeader
        image={category.image}
        title={category.name}
        description={category.description}
      />
      <Headband
        title={'Les offres du moment'}
        description={'Craquez pour des réductions exceptionnelles ' +
        'sur vos produits préférés : coffrets, maquillage ou soins visages à petits prix.'}
        showButton={false}
      />
      <CarouselContentCards
        itemCarousel={contents}/>
    </>
  );
}

export default OffersPage;
