import React from 'react';
import {formatPrice} from '../../utils';

import * as styles from './handleButton.css';

type Props = {
  props?: any;
};

function handleButton({...props}: Props) {


  const {
    // @ts-ignore
    'aria-valuemax': ariaValueMax,
    // @ts-ignore
    'aria-valuemin': ariaValueMin,
    // @ts-ignore
    'aria-valuenow': ariaValueNow,
    // @ts-ignore
    'aria-disabled': ariaDisabled,
    // @ts-ignore
    'data-handle-key': dataHandleKey,

  } = props
  return (
    <button
      className={styles.handle}
      role={'slider'}
      aria-valuemin={ariaValueMin}
      aria-valuemax={ariaValueMax}
      aria-valuenow={ariaValueNow}
      onKeyDown={props.onKeyDown}
      onTouchStart={props.onTouchStart}
      onMouseDown={props.onMouseDown}
      aria-disabled={ariaDisabled}
      data-handle-key={dataHandleKey}
      disabled={props.disabled}
      style={{left: props.style.left, position: 'absolute' }}
    >
      <span>{dataHandleKey === 1 ? formatPrice(ariaValueNow) + '€' : formatPrice(ariaValueNow) + '€'}</span>
    </button>

  );
}

export default handleButton;
