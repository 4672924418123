import React from 'react';
import * as styles from './CMSPage.css';

type CMSPageProps = {
  content: string
};

function CMSPage({content}: CMSPageProps) {
  return (
    <div
      className={styles.wrapper}
      dangerouslySetInnerHTML={{__html: content}}/>
  );
}

export default CMSPage;
