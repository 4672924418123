import React, {MouseEvent} from 'react';
import {Link} from "react-router-dom";

import * as styles from './Level2List.css';

type Level2ListProps = {
  items: any;
  selectedIndex: number;
  onSelect: (index: number) => void;
};

export default function Level2List({items, selectedIndex, onSelect}: Level2ListProps) {

  const onClick = (e: MouseEvent) => {
    if (window.innerWidth <= 768) {
      e.preventDefault();
    }
  }

  return (
    <ul className={styles.list}>
      {items.map((item: any, index: number) =>
        <li
          key={'cat-2-ote' + index}
          className={styles.item + (selectedIndex === index ? ' active' : '')}>
          <Link
            to={item.urlPath}
            onMouseEnter={() => onSelect(index)}
            onClick={onClick}
          >
            {item.name}
          </Link>
        </li>
      )}
    </ul>
  );
}
