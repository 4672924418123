import React from 'react';
import styles from './WatchAttributeSet.css';

type WatchAttributeSetProps = {
  attributes?: any;

};

const sections = [
  {
    label: "Boîtier",
    attributes: [
      {
        code: "caseShape",
        label: "Forme",
      },
      {
        code: "caseDimension",
        label: "Dimension",
      },
      {
        code: "caseMaterial",
        label: "Matière",
      }
    ]
  },
  {
    label: "Mouvement et fonctions",
    attributes: [
      {
        code: "movement",
        label: "Mouvement",
      }
    ]
  }
]

const WatchAttributeSet = ({attributes}: WatchAttributeSetProps) => (
  <div className={styles.container}>
    {sections.map((section: any, index: number) =>
      <div className={styles.section}>
        <h4>{section.label}</h4>
        <dl>
          {section.attributes.map(({code, label}) => {
            const attribute = attributes.find((a: any) => a.attributeCode === code)
            if (attribute) {
              return (
              <div>
                <dt>{label}</dt>
                <dd>{attribute.value}</dd>
              </div>
              )}
          })}
        </dl>
      </div>
    )}
  </div>
);

export default WatchAttributeSet;
