import React from 'react';
import styles from "./SidebarLayout.css";

type SidebarLayoutProps = {
    visible?: any;
    side?: any;
    children?: any;
};

function SidebarLayout({
                      visible,
                      side,
                      children,
                  }: SidebarLayoutProps) {


    return (
        <div className={visible ? styles.SidebarOpen : styles.SidebarClose}>
            {children}
        </div>

    );
}

export default SidebarLayout;
