import React, {useState} from 'react';
import {CardDescription, SliderImg} from "../index";
import styles from './ProductsSlider.css';

type ProductsSliderProps = {
  title: string;
  items: any;
};

function ProductsSlider({items, title}: ProductsSliderProps) {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  return (
    <div className={styles.cardDescriptionProductContainer}>
      <h2 className={styles.titleMobile}>{title}</h2>
      <SliderImg
        items={items}
        selectedIndex={selectedItemIndex}
        onSelect={(index: number) => setSelectedItemIndex(index)}
      />
      <div className={styles.cardDescriptionProduct}>
        <h2 className={styles.titleDesktop}>{title}</h2>
        {items.map((item: any, index: number) =>
          <CardDescription
            selected={index === selectedItemIndex}
            item={item}
            key={index}
          />
        )}
      </div>
    </div>
  );
}

export default ProductsSlider;
