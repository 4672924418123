import React from 'react';
import HorizontalScrollList from "../HorizontalScrollList/HorizontalScrollList";
import {ContentCards} from "../index";
import * as styles from './CarouselContentCards.css';

type CarouselContentCardsProps = {
  itemCarousel?: any;
  semiScroll?: boolean;
};

const CarouselContentCards = ({itemCarousel, semiScroll}: CarouselContentCardsProps) =>
  <HorizontalScrollList semiScroll={semiScroll}>
    <div className={styles.wrapper}>
      <ContentCards items={itemCarousel} asCarousel/>
    </div>
  </HorizontalScrollList>

export default CarouselContentCards;
