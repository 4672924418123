import React from 'react';
import {Link} from "react-router-dom";

import * as styles from './Level3LeftList.css';

type Level3LeftListProps = {
  items: any;
  path: any;
};

function Level3LeftList({items, path}: Level3LeftListProps) {
  const highlightedItems = items.filter((child: any) => child.highlighted);

  return (
    <ul className={styles.wrapper}>
      {highlightedItems.map((level3Item: any, level3Index: number) =>
        <li key={level3Index}>
          <Link to={level3Item.urlPath}>{level3Item.name}</Link>
        </li>
      )}
      <li>
        <Link className={styles.seeAllLink} to={path}>Voir tout</Link>
      </li>
    </ul>
  );
}

export default Level3LeftList;
