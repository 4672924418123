import React from "react";
import styles from "./Input.css";

type InputProps = Omit<JSX.IntrinsicElements["input"], "value"> & {
  value?: string;
  button?: any;
  label?: string;
};

function Input({ button, label, required, ...props }: InputProps) {
  return (
    <>
      {label && (
        <label
          style={{
            fontWeight: 600,
            color: "#444",
            marginBottom: "4px",
            fontSize: "13px",
          }}
          htmlFor={props.name}
        >
          {label}{" "}
          {required ? <span style={{ color: "#EF394F" }}>*</span> : null}
        </label>
      )}
      <input
        spellCheck="false"
        {...props}
        className={button ? styles.button : styles.input}
      />
    </>
  );
}

export default Input;
