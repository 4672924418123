import React, {useState} from 'react';
import * as styles from './CheckoutCartSummary.css';
import {Button, ButtonTypes, CartItemRow, CheckoutItemRow} from "../index";
import {formatPrice} from '../../utils';
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

type CheckoutCartSummaryProps = {
    products: any;
    onApplyCouponCode: (code: string) => void;
    loadingCouponCode: boolean;
    errorCoupon?: any;
    shippingMethod: any;
    loading: boolean
  onPlaceOrder: () => void
  placeOrderAllowed: boolean
};


function CheckoutCartSummary({loading, onPlaceOrder, placeOrderAllowed, products, onApplyCouponCode, loadingCouponCode, errorCoupon, shippingMethod}: CheckoutCartSummaryProps) {
    const subtotal = products.totals.find((total: any) => total.code === 'subtotal');
    const grandTotal = products.totals.find((total: any) => total.code === 'grand_total');
    const shipping = products.totals.find((t:any) => t.code === 'shipping');
    const discount = products.totals.find((t:any) => t.code === 'discount');
    let discountTitle = '';

    if (discount) {
      try {
        discountTitle = /Discount \((.*)\)/.exec(discount.title)[1];
      } catch (e) {}
    }
    const [couponCode, setCouponCode] = useState("");
    const [couponCodeInputError, setCouponCodeInputError] = useState<string|null>(null);

    const submitApplyCouponCodeForm = () => {
        if (couponCode.trim().length === 0) {
            setCouponCodeInputError("Code invalide");
            return;
        }

        setCouponCodeInputError(null);
        onApplyCouponCode(couponCode);
    }

    const handleCouponCodeInputChange = (e: any) => {
        const {value} = e.target;
        setCouponCode(value);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.qtyProducts}><span>Récapitulatif -&nbsp;</span>
              <span>{products.itemsQty} {products.itemsQty > 1 ? 'articles' : 'article'}</span>
            </div>
            <div className={styles.divider}/>
            <div className={styles.scrollItem} style={{maxHeight: '300px'}}>
                {products.items.map((product: any, index: any) => (
                    <div key={index}>
                        <CheckoutItemRow item={product}/>
                    </div>
                ))}
            </div>
            {/*<div className={styles.bonusFidelity}>*/}
            {/*    <span>Bonus fidélité récoltés avec cette commande :</span>*/}
            {/*</div>*/}
            <div className={styles.codeCoupon}>
                <input
                    className={styles.inputCoupon}
                    placeholder={"Code de réduction"}
                    onChange={handleCouponCodeInputChange}
                />
              <Button
                type={ButtonTypes.Coupon}
                as={'button'}
                loading={loadingCouponCode}
                onClick={submitApplyCouponCodeForm}
                text={'Appliquer'}
              />
            </div>
            {errorCoupon &&
              <div className={styles.invalidCoupon}>Coupon invalide</div>
            }
            <div className={styles.divider}/>
            <div className={styles.blockTotalDelivery}>
              <div className={styles.spaceBetweenSpan}>
                <span>Sous-total ({products.itemsQty} {products.itemsQty > 1 ? 'articles' : 'article'})</span>
                <span>{formatPrice(subtotal.value)} €</span>
              </div>
              {products.couponCode && (
                <div className={styles.spaceBetweenSpan}>
                  <span>Code {products.couponCode}</span>
                  {discount && (
                    <span>{formatPrice(discount.value) + ' €'}</span>
                  )}
                </div>
              )}
              {!products.couponCode && discount && (
                <div className={styles.spaceBetweenSpan}>
                  <span>{discountTitle}</span>
                  <span>{formatPrice(discount.value) + ' €'}</span>
                </div>
              )}
              <div className={styles.spaceBetweenSpan}>
                <span>{shippingMethod ? shippingMethod.methodTitle : 'Livraison'}</span>
                <span>{shipping.value === 0 ? 'Offerte' : formatPrice(shipping.value) + ' €'}</span>
              </div>
            </div>
            <div className={styles.divider}/>
            <div className={styles.subTotal}>
                <div>Total</div>
                <div className={styles.secondDiv}>TVA incluse</div>
                <div>{formatPrice(grandTotal.value)} €</div>
            </div>
            
        </div>
    );
}

export default CheckoutCartSummary;
