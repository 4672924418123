import React from 'react';
import {Card} from "../index";
import HorizontalScrollList from "../HorizontalScrollList/HorizontalScrollList";
import {formatPrice} from '../../utils';

type CarouselCardProps = {
    items?: any;
    semiScroll?: boolean;
    categoryName?: string;
    imgLazyLoading?: boolean;
};

// @ts-ignore
function CarouselCard({items, semiScroll, categoryName, imgLazyLoading = false}: CarouselCardProps) {
  return (
    <HorizontalScrollList semiScroll={semiScroll}>
      {items.map((item: any, index: number) =>
        <Card
          key={new Date().getTime() + '-' + item.id}
          item={item}
          urlImg={item.thumbnail}
          brand={item.brandName}
          description={item.shortDescription}
          name={item.name}
          isNew={item.new}
          categoryName={categoryName}
          isConfigurable={item.configurableOptions && item.configurableOptions.length > 0 && item.configurableOptions[0].values.length > 1}
          cardLink={item.urlPath}
          imgLazyLoading={imgLazyLoading || index > 3}
        />
      )}
    </HorizontalScrollList>
  );
}

export default CarouselCard;
