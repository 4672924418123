import React, {useEffect, useRef, useCallback, useState} from 'react';
import cx from 'classnames';

import pricing from './benlux-offre-noel-pop-up.png'

import * as styles from './index.css';
import {Button, ButtonTypes} from "../Button";

function ChristmasOfferPopIn() {
  const [visible, setVisible] = useState(true)

  const onClose = () => {
    setVisible(false);
  }

  return (
    <>
      <div className={cx(styles.modal, {visible: visible})} role="alert">
        <div className={styles.cross} onClick={onClose} role="button"/>
        <div className={styles.content}>
          <span className={styles.title}>Offre de Noël</span>
          <div className={styles.description}>
            <span>Jusqu'au 14 décembre</span>
          </div>
          <img src={pricing}/>
          <Button
            style={{background: '#fff', color: '#B04141'}}
            text="J'en profite !"
            type={ButtonTypes.Primary}
            as="button"
            onClick={onClose}
          />
        </div>

      </div>
      <div className={cx(styles.modalBackDrop,  {visible: visible})} onClick={onClose} />
    </>
  );
}

export default ChristmasOfferPopIn;
