import React from "react";
import styles from "./Select.css";

type SelectProps = Omit<JSX.IntrinsicElements["select"], "value"> & {
  value?: string;
  options: [
    {
      value: string;
      label: string;
    }
  ];
  label?: string;
  onChange?: (value: string) => void;
};

function Select({ options, label, required, ...props }: SelectProps) {
  return (
    <>
      {label && (
        <label
          style={{
            fontWeight: 600,
            color: "#444",
            marginBottom: "4px",
            fontSize: "13px",
          }}
          htmlFor={props.name}
        >
          {label}{" "}
          {required ? <span style={{ color: "#EF394F" }}>*</span> : null}
        </label>
      )}
      <select
        className={styles.input}
        autoComplete={props.autoComplete}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        defaultValue={props.defaultValue}
      >
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </>
  );
}

export default Select;
