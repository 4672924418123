import React, {useState} from 'react';
import * as styles from './SearchBrandsFilter.css'
import {Link} from "react-router-dom";
import searchBrand from "../../assets/search-brand.png";
import arrowfilters from "../../assets/filters-arrow.svg";

type SearchBrandsFilterProps = {
  items: any;
  refine?: any;
};

function SearchBrandsFilter({
                              refine,
                              items,
                            }: SearchBrandsFilterProps) {

  let [brandsListVisible, setBrandsListVisible] = useState(false);

  function searchBrandValue() {
    let input = (document.getElementById("searchbar") as HTMLInputElement).value;
    input = input.toLowerCase();
    let value = document.getElementsByClassName(styles.brand);


    let i;
    for (i = 0; i < value.length; i++) {
      if (!value[i].innerHTML.toLowerCase().includes(input)) {
        // @ts-ignore
        value[i].style.order = 2;
      } else {
        // @ts-ignore
        value[i].style.order = 1;
      }
    }
  }

  const toggleBrandsListVisible = () => setBrandsListVisible(!brandsListVisible);

  return (
    <div style={{position: 'relative'}}>
      <div className={styles.menu + (brandsListVisible ? ' active' : '')} onClick={() => setBrandsListVisible(!brandsListVisible)}>
        <span>Marques</span>
        <img src={arrowfilters} alt={'filter'}/>
      </div>
      <div className={styles.dropdown + (brandsListVisible ? ' active' : '')}>
        <input
          id={'searchbar'}
          className={styles.valueInput}
          onKeyUp={searchBrandValue}
          placeholder={'Rechercher une marque...'}
        />
        <div className={styles.valueInputImg}>
          <img src={searchBrand} alt={'search brand'}/>
        </div>

        <div className={styles.filterBrandsWrapper}>
          {items.map((brand: any, index: number) => (
              <Link
                key={index}
                className={styles.brand + (brand.isRefined ? ' active' : '')}
                to={'#'}
                onClick={event => {
                event.preventDefault();
                refine(brand.value);
              }}>
                {brand.label} ({brand.count})
              </Link>
          ))}
        </div>
      </div>
    </div>

  );
}

export default SearchBrandsFilter;
