import React from 'react';
import {Link} from 'react-router-dom';

import styles from './SiteMap.css';

type Props = {
  categories?: any;
};


function SiteMap({categories}: Props) {
  const topCategories = categories[0].children;
  const othersCategories = categories.filter((cat: any) => cat.urlPath != "/categories")

  return (
    <div className={styles.planContainer}>
      <div className={styles.gridGlobal}>
        <div>
          <div className={styles.grid}>
            {topCategories.map((category: any, index: number) => {
              const highlightedItems = category.children.filter((child: any) => child.highlighted);
              const notHighlighted = category.children.filter((child: any) => !child.highlighted);

              return (
                <div key={index}>
                  <Link to={category.urlPath}
                        className={styles.subCategoryName + ' ' + (index === 0 && styles.first)}><span>{category.name}</span></Link>
                  <div className={styles.displayContent}>
                    <ul>
                      {highlightedItems.map((level3Item: any, level3Index: number) =>
                        <li key={level3Index}>
                          <Link to={level3Item.urlPath}
                                className={styles.children}>{level3Item.name}</Link>
                        </li>
                      )}
                    </ul>
                    <ul className={styles.notHighlightedItems}>
                      {notHighlighted.map((level3Item: any, level3Index: number) =>
                        <li key={level3Index}>
                          <Link
                            to={level3Item.urlPath}
                            className={styles.level2Name}
                          >
                            {level3Item.name}
                          </Link>
                          <div>
                            {level3Item.children.map((level2: any, index: number) => (
                              <div key={index}>
                                <Link
                                  to={level2.urlPath}
                                  className={styles.children}
                                >
                                  {level2.name}
                                </Link>
                              </div>
                            ))
                            }
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
          <>
            <div className={styles.subCategoryName}><span>Autres</span></div>
            <ul className={styles.gridCategory}>
              {othersCategories.map((otherCategory: any, index: number) =>
                <li key={index}>
                  <Link
                    to={otherCategory.urlPath}
                    className={styles.othersCategoryName}>
                    {otherCategory.name}
                  </Link>
                </li>
              )}
            </ul>
          </>
        </div>
      </div>
    </div>
  );
}

export default SiteMap;


