import React, {useState} from 'react';
import * as styles from './PriceFilter.css'
import 'rheostat/initialize';
// @ts-ignore
import Rheostat from 'rheostat';
import 'rheostat/css/rheostat.css';
import {handleButton} from "../index";
import arrowfilters from "../../assets/filters-arrow.svg";

type PriceFilterProps = {
  min?: number
  max?: number
  onChangePrice?: any;
  filterObject?: any;
  opened: boolean;
  onToggle: () => void;
};

function PriceFilter({
  min,
  max,
  onChangePrice,
  onToggle,
  opened,
  filterObject
}: PriceFilterProps) {
  const [newMin, setNewMin] = useState(min);
  const [newMax, setNewMax] = useState(max)

  const newValues = (price: any) => {
    setNewMin(price.values[0])
    setNewMax(price.values[1])
    onChangePrice(price)
  }

  return (
    <div className={styles.priceContainer}>
      <div className={styles.wrapper}>
        <div
          className={styles.filterButton + ' ' + (opened ? styles.active : '')}
          onClick={onToggle}>
          <span>Prix</span>
          <img src={arrowfilters} alt={'filter'}/>
        </div>
          <div
            className={styles.filtersContent + ' ' + (opened ? styles.active : '')}
            onClick={onToggle}>
            <div className={styles.filterPrice}>
              <div className={styles.middle}>
                <Rheostat
                  min={newMin}
                  max={newMax}
                  values={[newMin, newMax]}
                  onChange={(price: any) => newValues(price)}
                  snap
                  handle={handleButton}
                />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default PriceFilter;
