import React, {useEffect} from 'react';
import styles from './TextSwatch.css';

type TextSwatchProps = {
  configurableOption: {
    attributeId: string
    values: [
      {
        inStock: []
        id: string
        valueIndex: number
        hexColor: string
        label: string
      }
    ]
  }
  onChange: (ev: object, selectedIndex: number) => void
  error?: any;
  selectedIndex: number;
};

function TextSwatch({configurableOption, onChange, error, selectedIndex}: TextSwatchProps) {

  const {values, attributeId} = configurableOption;

  useEffect(() => {
    const value = values[selectedIndex];
    onChange({target: {name: attributeId, value: value.valueIndex}}, selectedIndex);
  }, [selectedIndex]);

  return (
    <div className={styles.productOptions}>
      <div className={styles.blockOptions}>
        {configurableOption.values.map((value: any, idx: number) =>
          <div className={styles.configurableOptions} key={idx}>
            <input
              // disabled={value.inStock.length === 0}
              key={value.id}
              type={'radio'}
              className={styles.variation}
              onChange={(ev) => onChange(ev, idx)}
              value={value.valueIndex}
              name={attributeId}
              defaultValue={value.valueIndex[0]}
              defaultChecked={idx === selectedIndex}
            />
            <span>{value.label}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TextSwatch;
