import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import {
  AllProductsDropdown,
  DropDownContent,
  NavigationBrandsDropDown,
} from "../index";
import * as styles from "./NavBarMenu.css";
import arrowLink from "../../assets/arrowMenu.png";

type NavigationBarProps = {
  items: [any];
  onSelectClose?: any;
  isOpen?: any;
  contents: object;
  allProductsDropdownRef?: any;
  setOpenedMenuIndex?: any;
  openedMenuIndex?: number;
};

function NavigationBar({
  items,
  onSelectClose,
  isOpen,
  contents,
  allProductsDropdownRef,
  openedMenuIndex,
  setOpenedMenuIndex,
}: NavigationBarProps) {
  const [openDesktopMenu, setOpenDesktopMenu] = useState<number>();

  const onClickCloseMenu = () => {
    setOpenedMenuIndex(undefined);
    allProductsDropdownRef.current?.resetSelectedIndex(undefined);
  };

  const allProductsCurrentRef = (id: any) => {
    setOpenDesktopMenu(id);
    if (window.innerWidth >= 768) {
      allProductsDropdownRef.current?.resetSelectedIndex(0);
    }
  };

  const onClickMenu = (id: string, e: MouseEvent) => {
    if (window.innerWidth <= 768) {
      if (["703", "19", "1079", "793", "862"].indexOf(id) > -1) {
        onClickCloseMenu();
        return;
      }

      e.preventDefault();
      setOpenedMenuIndex(id);
    }
  };

  useEffect(() => {
    window.addEventListener("click", function () {
      setOpenDesktopMenu(undefined);
    });
  }, [isOpen, openDesktopMenu]);

  const findContents = (name: string) => {
    switch (name) {
      case "#Inspiration":
        return contents.inspirationsContents.content;
      case "Marques":
        return contents.brandsContents.content;
      case "Nouveautés":
        return contents.noveltiesContents.content;
      case "Promotions":
        return contents.soldesContents.content;
      case "Idées Cadeaux Saint-Valentin":
        return contents.giftsContents.content;
      case "Offres":
        return contents.offersContents.content;
      case "Sélections":
        return contents.selectionsContents.content;
      case "Cadeaux":
        return contents.giftsContents.content;
      case "Beauty Days":
        return contents.beautyDaysContents.content;
      case "Black Friday":
        return contents.blackFridayContents.content;
      default:
        return "[]";
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {items.map((item, index) => (
          <div
            className={cx(styles.tab, {
              [styles.highlighted]: item.highlighted,
              [styles.closeHover]: openDesktopMenu === item.id,
              [styles.greenBeauty]: item.name === "Green Beauty",
            })}
            key={"cat-" + item.id}
            onMouseLeave={() => setOpenDesktopMenu(undefined)}
          >
            {item.name === "Catégories" ? (
              <span
                className={styles.categoriesTab}
                onMouseEnter={() => {
                  allProductsCurrentRef(item.id);
                }}
              >
                {item.name}
              </span>
            ) : (
              <Link
                to={item.urlPath}
                onClick={(e: any) => onClickMenu(item.id, e)}
                onMouseEnter={() => {
                  allProductsCurrentRef(item.id);
                }}
              >
                {item.name === "Promotions"
                  ? "Bons Plans"
                  : item.name === "Idées Cadeaux Saint-Valentin"
                  ? "Saint-Valentin"
                  : item.name === "Idées Cadeaux Noël"
                  ? "Noël"
                  : item.name}
                {[
                  "Club Benlux",
                  "Green Beauty",
                  "Fête des mères",
                  "Fête des pères",
                  "Black Friday",
                  "Idées Cadeaux Noël",
                ].indexOf(item.name) === -1 && (
                  <img src={arrowLink} className={styles.arrowLink} alt="" />
                )}
              </Link>
            )}
            {item.name === "Catégories" && (
              <AllProductsDropdown
                ref={allProductsDropdownRef}
                item={item}
                isOpen={isOpen}
                onMouseLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
            {item.name === "Marques" && (
              <NavigationBrandsDropDown
                items={item}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
                onSelectClose={onSelectClose}
              />
            )}
            {item.name === "Nouveautés" && (
              <DropDownContent
                items={{
                  name: "Nouveautés",
                  urlPath: "",
                  children: [
                    {
                      name: "Toutes les nouveautés",
                      urlPath: "/nouveautes",
                      children: [
                        {
                          name: "Maquillage",
                          urlPath: "/nouveautes-maquillage",
                        },
                        {
                          name: "Parfum",
                          urlPath: "/nouveautes-parfum",
                        },
                        {
                          name: "Soin du visage",
                          urlPath: "/nouveautes-soin-visage",
                        },
                        {
                          name: "Corps et bain",
                          urlPath: "/nouveautes-corps-bain",
                        },
                        {
                          name: "Parapharmacie",
                          urlPath: "/nouveautes-parapharmacie",
                        },
                        {
                          name: "Homme",
                          urlPath: "/nouveautes-homme",
                        },
                        {
                          name: "Montres et bijoux",
                          urlPath: "/nouveautes-montres-bijoux",
                        },
                        {
                          name: "Maroquinerie",
                          urlPath: "/nouveautes-accessoires",
                        },
                      ],
                    },
                  ],
                }}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
            {item.name === "Promotions" && (
              <DropDownContent
                items={{
                  name: "Bons Plans",
                  urlPath: "",
                  children: [
                    {
                      name: "Tous les bons plans",
                      urlPath: "/soldes",
                      children: item.children,
                    },
                  ],
                }}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
            {item.name === "Idées Cadeaux Saint-Valentin" && (
              <DropDownContent
                items={{
                  name: "Idées Cadeaux Saint-Valentin",
                  urlPath: "",
                  children: [
                    {
                      name: "Toutes les idées cadeaux",
                      urlPath: item.urlPath,
                      children: item.children,
                    },
                  ],
                }}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
            {[
              "Fête des mères",
              "Fête des pères",
              "Marques",
              "Nouveautés",
              "Sélections",
              "Promotions",
              "Soldes",
              "Idées Cadeaux Saint-Valentin",
              "Black Friday",
              "Idées Cadeaux Noël",
              "Catégories",
            ].indexOf(item.name) === -1 &&
              item.children &&
              item.children.length > 0 && (
                <DropDownContent
                  items={item}
                  opened={openedMenuIndex === item.id}
                  onClickCloseMenu={onClickCloseMenu}
                  contents={JSON.parse(findContents(item.name))}
                  onMenuLeave={() => setOpenDesktopMenu(undefined)}
                />
              )}
            {item.name === "Sélections" && (
              <DropDownContent
                items={{
                  name: "Sélections",
                  urlPath: "/selections",
                  children: [
                    {
                      name: "Toutes les sélections",
                      urlPath: "/selections",
                      children: item.children,
                    },
                  ],
                }}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
            {item.name === "#Inspiration" && (
              <DropDownContent
                items={{
                  name: "#Inspiration",
                  urlPath: "/inspirations",
                  children: [
                    {
                      name: "Toutes les inspirations",
                      urlPath: "/inspirations",
                      children: item.children,
                    },
                  ],
                }}
                //onClickResetMenu={(e: any) =>  onClickMenu(item.id, e)}
                opened={openedMenuIndex === item.id}
                onClickCloseMenu={onClickCloseMenu}
                contents={JSON.parse(findContents(item.name))}
                onMenuLeave={() => setOpenDesktopMenu(undefined)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavigationBar;
