import React from 'react';
import {Button, ButtonTypes} from "../index";
import styles from './CardStore.css';

type CardStoreProps = {
  title: string;
  position?: any;
  urlPath?: any;
  description: any;
  button?: boolean;
  inStorePage?: boolean;
  contentsInStorePage?: any;
  buttonText?: any;
  address?: any;
};

function CardStore({
  description,
  title,
  position,
  urlPath,
  button,
  inStorePage,
  buttonText,
  contentsInStorePage,
  address,
}: CardStoreProps) {

  return (
    <div className={styles.cardStore + ' ' + (inStorePage ? styles.inStore : '')}>
      <div className={styles.container}>
        <h2>{title}</h2>
        {!inStorePage && <div className={styles.position}>{address.address1}, {address.postalCode} {address.city}</div>}
        <p dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}/>
        {button &&
          <Button
            href={urlPath}
            text={buttonText}
            as={'link'}
            type={ButtonTypes.Tertiary}/>
        }
        {inStorePage &&
        <div className={styles.contentStore}>
          <div>
            <div>{contentsInStorePage.address.company}</div>
            <div>{contentsInStorePage.address.address1}</div>
            <div>{contentsInStorePage.address.postalCode} {contentsInStorePage.address.city}</div>
          </div>
          <div className={styles.blockRight}>
            <div><span>{contentsInStorePage.open}</span> aujourd'hui jusqu'à 18h45</div>
            <div className={styles.grid}>
              {contentsInStorePage.schedules.map((schedule: any) =>
                <>
                  <div>{schedule.days}</div>
                  <div>{schedule.times}</div>
                </>
              )}
            </div>
          </div>
        </div>
        }
      </div>
    </div>

  );
}

export default CardStore;
