import React from 'react';
import * as styles from './HelpLayout.css';
import helpHeader from '../../assets/helpHeader.png'
import Input from "../Input";
import {CardHelp, FooterHelpPage} from "../index";
import {Link} from "react-router-dom";

type HelpLayoutProps = {
  content?: any
  contentFooter?: any;
};

function HelpLayout({content, contentFooter}: HelpLayoutProps) {

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
          {/*<img src={helpHeader}/>      */}
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundImage: "url(" + helpHeader + ")",
              backgroundSize: 'cover',
            }}
          >
          <div className={styles.questionForm}>
            <h2>Vous avez une question ?</h2>
            <Input placeholder={'Tapez votre question...'}/>
          </div>
          </div>
        <div className={styles.blockRight}>
          <div className={styles.blockRightQuestions}>
            <h2>Question les plus fréquentes</h2>
            <Link to={'#'}>Je souhaite suivre l'état d'avancement de ma commande : que faire ? </Link>
            <Link to={'#'}>Dans quels pays puis-je me faire livrer  ? </Link>
            <Link to={'#'}>J'ai déjà une carte BENLUX : comment créer mon compte en ligne ? </Link>
          </div>
        </div>
      </div>
      <div className={styles.middleBox}>
        {content.sections.map((section: any) =>
          <CardHelp description={section.title} thumbnail={section.thumbnail} url={{pathname: `/aide/${section.slug}`, state: {contents: section.contents, title: section.title}}}/>
        )}
      </div>
      <FooterHelpPage content={contentFooter}/>
    </div>
  );
}

export default HelpLayout;
