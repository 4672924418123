import React from 'react';
import {Link} from 'react-router-dom';
import cross from "../../assets/cross.png";
import * as styles from './CartItemRow.css';
import {formatPrice} from '../../utils';

type CartItemRowProps = {
  number?: any;
  error?: any;
  onRemove?: () => void;
  onQtyUpdate: (newQty: number) => void
  updatingQty?: boolean
  item: {
    name: string
    urlKey: string
    thumbnailUrl: string
    rowTotalInclTax: number
    price: number;
    regularPriceInclTax: number
    manufacturer: string
    qty: number
    itemOptions: [
      {
        value: string
        label: string
      }
    ]
    shortDescription: string
  };
};


function CartItemRow({item, onRemove, onQtyUpdate, updatingQty, error}: CartItemRowProps) {

  const {name, thumbnailUrl, rowTotalInclTax, manufacturer, itemOptions, shortDescription, qty, urlKey, regularPriceInclTax} = item;
  const optionsLabel = itemOptions.map((o: { value: any; }) => o.value).join(" - ");
  const rowTotalRegularPrice = Math.round(regularPriceInclTax * qty * 100) / 100;

  return (
    <div className={styles.wrapper}>
      {
        rowTotalInclTax > 0 && (
          <img
            className={styles.crossRemove}
            src={cross}
            onClick={onRemove}
          />
        )
      }
      <div className={styles.cartItem}>
        <Link to={'/' + urlKey}>
          <img className={styles.thumbnail} src={thumbnailUrl}/>
        </Link>
        <div className={styles.blockItem}>
          <Link to={'/' + urlKey + '.html'} className={styles.blockLink}>
            <div className={styles.manufacturer}>{manufacturer}</div>
            <div>{name}</div>
            <div>{shortDescription}</div>
            <div>{optionsLabel}</div>
          </Link>
          <div className={styles.blockNumber}
               style={{visibility: (rowTotalInclTax > 0 ? 'initial' : 'hidden')}}>
            {updatingQty ? (
              <span className={styles.loader}>Chargement...</span>
            ) : (
              <>
                <button onClick={() => onQtyUpdate(qty - 1)}>-</button>
                <span>{qty}</span>
                <button onClick={() => onQtyUpdate(qty + 1)}>+</button>
              </>
            )}
          </div>
        </div>
        <div className={styles.blockFinal}>
          {
            rowTotalInclTax > 0 ? (
              <>
                <span className={styles.regularPrice}
                      style={{display: rowTotalRegularPrice > rowTotalInclTax ? 'flex' : 'none'}}>
                  {formatPrice(rowTotalRegularPrice)}&nbsp;€
                </span>
                <span className={(rowTotalRegularPrice > rowTotalInclTax) ? styles.specialPrice : styles.price}>
                  {formatPrice(rowTotalInclTax)}&nbsp;€
                </span>
              </>
            ) : (
              <span className={styles.price}>Offert</span>
            )
          }
        </div>
      </div>
      <div className={styles.divider}/>
      {error &&
      <div>Quantité non disponible</div>
      }
    </div>
  );
}

export default CartItemRow;
