import React from "react";
import { Button, ButtonTypes, Card } from "../index";
import styles from "./ProductsList.css";
import { getPath } from "../../utils";
import ProductListPagination from "../ProductListPagination";

type ProductsListProps = {
  products: any;
  searchProps?: any;
  hasMore?: any;
  categoryName?: string;
  onClickRefineNext?: any;
  totalItems: number;
  fetchMore: () => void;
  loading: boolean;
  pagination?: {
    currentPage: number;
    perPage: number;
    nextPage: number | null;
    totalItems: number;
  };
  query: any;
};

function getPrice(item: any, type: string) {
  if (item.configurableOptions && item.configurableOptions.length > 0) {
    // Item is a configurable, get lowest price from values
    const cheapestItem = item.configurableOptions[0].values.reduce(
      (prev: any, curr: any) => {
        return prev.price[type] < curr.price[type] ? prev : curr;
      }
    );
    return cheapestItem.price[type];
  } else {
    // Item is a simple
    return item.price[type];
  }
}

const extractPriceFromFormatted = (price: string) => {
  return parseFloat(price.slice(0, -2).replace(",", ".").replace(" ", ""));
};

function ProductsList({
  products,
  searchProps,
  hasMore,
  onClickRefineNext,
  categoryName,
  loading,
  fetchMore,
  pagination,
  query,
}: ProductsListProps) {
  const { currentPage, perPage, totalItems, nextPage } = pagination;
  const seenItems = nextPage ? currentPage * perPage : totalItems;
  const totalPages = Math.ceil(totalItems / perPage);

  return (
    <div className={styles.products}>
      <div className={styles.list}>
        {products.map((item: any, index: number) => {
          if (searchProps) {
            const price = item.price.EUR.default_original_formated
              ? extractPriceFromFormatted(
                  item.price.EUR.default_original_formated
                )
              : item.price.EUR.default;
            const specialPrice = item.price.EUR.default_original_formated
              ? item.price.EUR.default
              : null;

            item.price.regular = price;
            item.price.special = specialPrice;
            item.promotionalBadge = item.promotional_badge;
            item.thumbnail = item.image_url.replace("http://", "https://");
            item.shortDescription = item.short_description;
            item.brandName = item.manufacturer;
            item.urlPath = getPath(item.url);
          }
          return (
            <div className={styles.productsCards} key={index}>
              <Card
                item={item}
                categoryName={categoryName}
                imgLazyLoading={index > 3}
                isConfigurable={
                  searchProps
                    ? item.price.EUR.default_max &&
                      item.price.EUR.default < item.price.EUR.default_max
                    : item.configurableOptions &&
                      item.configurableOptions.length > 0 &&
                      item.configurableOptions[0].values.length > 1
                }
              />
            </div>
          );
        })}
      </div>
      <div className={styles.pagination}>
        <p>
          Vous avez vu {seenItems} article{seenItems > 1 ? "s" : ""} sur{" "}
          {totalItems}
        </p>
        {nextPage && (
          <div className={styles.showMore}>
            <Button
              type={ButtonTypes.Secondary}
              onClick={fetchMore}
              text={"Voir plus"}
              as={"button"}
              loading={loading}
            />
          </div>
        )}
        <ProductListPagination
          currentPage={currentPage}
          totalPages={totalPages}
          query={query}
        />
      </div>
    </div>
  );
}

export default ProductsList;
