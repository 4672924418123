import React from 'react';
import {Link} from 'react-router-dom';
import * as styles from './CheckoutItemRow.css';
import {formatPrice} from '../../utils';

type CheckoutItemRowProps = {
    item: {
        name: string
        urlKey: string
        thumbnailUrl: string
        rowTotalInclTax: number
        price: number;
        regularPriceInclTax: number
        manufacturer: string
        qty: number
        itemOptions: [
            {
                value: string
                label: string
            }
        ]
        shortDescription: string
    };
};


function CheckoutItemRow({item}: CheckoutItemRowProps) {
    const {name, thumbnailUrl, rowTotalInclTax, manufacturer, itemOptions, shortDescription, qty, urlKey, regularPriceInclTax} = item;
    const optionsLabel = itemOptions.map((o: { value: any; }) => o.value).join(" - ");
    const rowTotalRegularPrice = Math.round(regularPriceInclTax * qty * 100) / 100;
    return (
        <div className={styles.wrapper}>
            <div className={styles.cartItem}>
                <div className={styles.blockLeft}>
                    <img className={styles.thumbnail} src={thumbnailUrl}/>
                    <div className={styles.blockItem}>
                        <div className={styles.blockLink}>
                            <div className={styles.manufacturer}>{manufacturer}</div>
                            <div>{name}</div>
                            <div>{optionsLabel}</div>
                        </div>
                    </div>
                </div>
                <div>Quantité : {qty}</div>
                <div className={styles.blockFinal}>
                {
                  rowTotalInclTax > 0 ? (
                    <>
                      <span className={styles.regularPrice} style={{display: rowTotalRegularPrice > rowTotalInclTax ? 'flex' : 'none'}}>{formatPrice(rowTotalRegularPrice)}&nbsp;€</span>
                      <span className={(rowTotalRegularPrice != rowTotalInclTax) ? styles.specialPrice : styles.price}>{formatPrice(rowTotalInclTax)}&nbsp;€</span>
                    </>
                  ) : (
                    <span className={styles.price}>Offert</span>
                  )
                }
                </div>
            </div>
            <div className={styles.divider}/>
        </div>
    );
}

export default CheckoutItemRow;
