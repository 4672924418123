import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
// @ts-ignore
import InnerImageZoom from "react-inner-image-zoom";
import arrow from "../../assets/arrowProductMobile.svg";
import styles from "./ProductImages.css";

type ProductImagesProps = {
  selectedValueIndex: number;
  product: {
    name: string;
    thumbnail: string;
    brand: {
      name: string;
    };
    promotionalBadge: string;
    shortDescription: string;
    gallery: [
      {
        thumbnail: string;
        full: string;
      }
    ];
  };
};

type CustomArrowProps = {
  onClick: () => void;
  side: string;
};

const CustomArrow = ({ onClick, side }: CustomArrowProps) => {
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className={side === "left" ? styles.leftArrow : styles.rightArrow}
    >
      <img src={arrow} />
    </button>
  );
};

function YouTubeGetID(embedUrl: string) {
  let url = embedUrl.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

const YoutubeEmbed = ({ videoUrl }: { videoUrl: string }) => (
  <iframe
    style={{ width: "100%", height: "100%" }}
    src={`https://www.youtube.com/embed/${YouTubeGetID(videoUrl)}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

function isNew(item: any) {
  if (!item || !item.attributes) return false;

  const newsToDate = item.attributes.find(
    (attr: any) => attr.attributeCode === "newsToDate" && attr.value.length > 0
  );
  const newsFromDate = item.attributes.find(
    (attr: any) =>
      attr.attributeCode === "newsFromDate" && attr.value.length > 0
  );

  if (!newsToDate && !newsFromDate) return false;

  return (
    (!newsToDate || new Date(newsToDate.value) > new Date()) &&
    (!newsFromDate || new Date(newsFromDate.value) < new Date())
  );
}

function isGreen(item: any) {
  if (!item || !item.attributes) return false;

  const green = item.attributes.findIndex(
    (attr: any) => attr.attributeCode === "greenBeauty" && attr.value === "Yes"
  );

  return green > -1;
}

function ProductImages({ product, selectedValueIndex }: ProductImagesProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const carousel = useRef(null);
  const imageAlt =
    product.name +
    " " +
    product.brand.name +
    " " +
    product.shortDescription +
    " pas cher";
  let gallery =
    product.configurableOptions.length > 0
      ? product.configurableOptions[0].values[selectedValueIndex].gallery
      : product.gallery;

  if (gallery.length <= 0) {
    gallery = product.gallery;
  }
  return (
    <div className={styles.container}>
      <div className={styles.thumbnailList}>
        {gallery.length > 0 ? (
          <>
            {gallery.map((image: any, index: number) => (
              <div
                key={index}
                style={{ position: "relative" }}
                className={
                  styles.content +
                  " " +
                  (selectedIndex === index ? "active" : "")
                }
                onClick={() => {
                  setSelectedIndex(index);
                  carousel.current?.goToSlide(index);
                }}
                onMouseEnter={() => {
                  setSelectedIndex(index);
                  carousel.current?.goToSlide(index);
                }}
              >
                <img src={image.thumbnail} alt={imageAlt} />
                {image.type === "external-video" && (
                  <div className={styles.playIcon}>
                    <div>
                      <i></i>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className={styles.content + " active"}>
            <img src={product.thumbnail} alt={imageAlt} />
          </div>
        )}
      </div>
      <div>
        <div className={styles.carouselWrapper}>
          {product && isNew(product) && (
            <span className={styles.isNewBadge}>Nouveauté</span>
          )}
          <div className={styles.badges}>
            {product && product.promotionalBadge && (
              <div className={styles.promotionalBadge}>
                <span>{product.promotionalBadge}</span>
              </div>
            )}
            {isGreen(product) && <span className={styles.greenBadge} />}
          </div>
          {/* {product && product.promotionalBadge && (
            <span className={styles.blackFridayBadge}>Black Friday</span>
          )} */}
          {gallery.length > 0 ? (
            <Carousel
              ref={carousel}
              containerClass={styles.carousel}
              dotListClass={styles.carouselDots}
              itemClass={styles.item}
              swipeable
              draggable={false}
              customLeftArrow={<CustomArrow side={"left"} />}
              customRightArrow={<CustomArrow side={"right"} />}
              ssr
              showDots
              removeArrowOnDeviceType={["tablet", "desktop"]}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 768,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 768,
                  },
                  items: 1,
                },
              }}
            >
              {gallery.map((image: any, index: number) => (
                <div
                  style={{ height: "100%" }}
                  className={
                    styles.fullImage +
                    " " +
                    (index === 0 ? styles.mainImage : "")
                  }
                  key={index}
                >
                  {image.type === "external-video" ? (
                    <YoutubeEmbed videoUrl={image.embedUrl} />
                  ) : (
                    <InnerImageZoom
                      src={image.full}
                      zoomSrc={image.full}
                      alt={imageAlt}
                      zoomType="hover"
                    />
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            <div className={styles.placeholder}>
              <img src={product.thumbnail} alt={imageAlt} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductImages;
