import React from 'react';
import {
  CarouselContentCards,
  Headband,
  InstagramPosts,
  PageHeader,
} from "../index";

type InspirationsPageProps = {
  contents: any;
  category: any;
  instagramPosts: [
    {
      url: string,
      thumbnailUrl: string,
      caption: string
    }
  ] | null;
};

function InspirationsPage({contents, category, instagramPosts = null}: InspirationsPageProps) {

  const findContents = (name: string) => {
    switch (name) {
      case '#Inspiration':
        return contents.inspirationsContents.content;
      case 'Sélections':
        return contents.selectionsContents.content;
      default:
        return '[]'
    }
  }

  return (
    <>
      <PageHeader
        image={category.image}
        title={category.name}
        description={category.description}
      />
      <Headband
        title={'Edito BENLUX'}
        description={'Vous avez toujours eu envie de savoir quel shampooing est adapté ' +
        'à vos cheveux ou quelle crème nourrissante ravira votre peau ? ' +
        'L’équipe BENLUX vous partage chaque semaine ses conseils et astuces.'}
        showButton={false}
      />
      <CarouselContentCards
        itemCarousel={JSON.parse(findContents('#Inspiration'))}/>
      <Headband
        title={'Posts Instagram'}
        description={'Vous avez besoin d\'une dose d\'inspiration quotidienne ? Retrouvez chaque jour nos conseils, idées cadeaux et présentations produits directement sur notre compte Instagram !'}
        urlPath={'https://www.instagram.com/benluxparis'}
        externalLink
        showButton
        textButton={'Découvrir notre Instagram'}
      />
      <InstagramPosts posts={instagramPosts}/>
      {/*<Headband*/}
      {/*  title={'Sélections'}*/}
      {/*  description={'Vous avez toujours eu envie de savoir quel shampooing est ' +*/}
      {/*  'adapté à vos cheveux ou quelle crème nourrissante ravira votre peau' +*/}
      {/*  ' ? L’équipe BENLUX vous partage chaque semaine ses conseils et astuces.'}*/}
      {/*  showButton={false}*/}
      {/*/>*/}
      {/*<CarouselContentCards itemCarousel={JSON.parse(findContents('Sélections'))} />*/}
    </>
  );
}

export default InspirationsPage;
