import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { ThemeProvider } from "@deity/falcon-ui";
import ScrollToTop from "@deity/falcon-client/src/components/ScrollToTop";
import { LocaleProvider, SearchProvider } from "@deity/falcon-ecommerce-uikit";
import { ThemeEditorState } from "@deity/falcon-theme-editor";
import loadable from "src/components/loadable";
import { ErrorBoundary } from "src/components/ErrorBoundary";
import { SidebarContainer } from "src/pages/shop/components/Sidebar";
import { deityGreenTheme, globalCss } from "./theme";
import "benlux-ecommerce-ui/dist/main.css";
// import { GWPPopIn } from "benlux-ecommerce-ui";
import smoothscroll from "smoothscroll-polyfill";
import TagManager from "react-gtm-module";

const websiteSchemaData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  url: "https://www.benlux.fr",
  name: "BENLUX",
  potentialAction: {
    "@type": "SearchAction",
    target: "https://www.benlux.fr/search/{search_term_string}",
    "query-input": "required name=search_term_string"
  }
};

// TODO: add logo to schema
const organizationSchemaData = {
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "BENLUX",
  url: "https://www.benlux.fr",
  sameAs: [
    "https://www.facebook.com/Benluxlouvre",
    "https://www.instagram.com/benluxparis/"
  ]
};

let lastPath = null;
const onHelmetChange = ({ metaTags }) => {
  if (
    "undefined" !== typeof window &&
    lastPath !== window.location.pathname &&
    metaTags.filter(m => m.name === "page-loaded" && m.content === "true")
      .length > 0
  ) {
    TagManager.dataLayer({
      dataLayer: {
        event: "PageView",
        page: {
          location: document.location.href,
          referrer: document.referrer,
          title: document.title
        }
      }
    });

    lastPath = window.location.pathname;
  }
};

const HeadMetaTags = () => (
  <Helmet
    onChangeClientState={onHelmetChange}
    defaultTitle="BENLUX ‣ Parfum, maquillage, parapharmacie, bijoux & montres"
    titleTemplate="%s ‣ BENLUX"
  >
    <meta
      name="description"
      content="Retrouvez les plus grandes marques de parfums, soins, cosmétiques, maquilage, parapharmacie, bijoux, montres, sacs & accessoires chez BENLUX. 💄"
    />
    <meta name="theme-color" content="#fff" />
    <meta name="format-detection" content="telephone=yes" />
    <meta
      property="og:title"
      content="BENLUX ‣ Parfum, maquillage, parapharmacie, bijoux & montres"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:description"
      content="Retrouvez les plus grandes marques de parfums, soins, cosmétiques, maquilage, parapharmacie, bijoux, montres, sacs & accessoires chez BENLUX. 💄"
    />
    <meta property="og:url" content="https://www.benlux.fr" />
    <meta name="page-loaded" content="false" />
    <script type="application/ld+json">
      {JSON.stringify(websiteSchemaData)}
    </script>
    <script type="application/ld+json">
      {JSON.stringify(organizationSchemaData)}
    </script>
  </Helmet>
);

const Checkout = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/checkout" */ "./pages/shop/Checkout"
  )
);
const SidebarContents = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/SidebarContents" */ "./pages/shop/components/Sidebar/SidebarContents"
  )
);

import { Sidebar } from "./pages/shop/components/Sidebar/Sidebar";
import DefaultLayout from "./components/DefaultLayout";

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

const App = ({ history }) => (
  <LocaleProvider>
    <ScrollToTop />
    <ThemeEditorState initial={deityGreenTheme}>
      {props => (
        <SearchProvider>
          <ThemeProvider theme={props.theme} globalCss={globalCss}>
            <HeadMetaTags />
            <div>
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/commande" component={Checkout} />
                  <Route path="/" component={DefaultLayout} />
                </Switch>
                <SidebarContainer>
                  {sidebarProps => (
                    <Sidebar {...sidebarProps}>
                      <SidebarContents
                        contentType={sidebarProps.contentType}
                        error={sidebarProps.error}
                        contextKey={sidebarProps.contextKey}
                        contextItemId={sidebarProps.contextItemId}
                      />
                    </Sidebar>
                  )}
                </SidebarContainer>
              </ErrorBoundary>
            </div>
            {/* <GWPPopIn />*/}
          </ThemeProvider>
        </SearchProvider>
      )}
    </ThemeEditorState>
  </LocaleProvider>
);

export default withRouter(App);
