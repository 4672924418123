import React from 'react';
import notFound from '../../assets/404.jpg';
import {CardTopCategory, PageHeader} from "../index";

type NotFoundProps = {
  categories?: any;
};

const item = {
  image: notFound,
  title: 'Une erreur s\'est produite...',
  description: 'Il semblerait que la page que vous souhaitiez voir soit momentanément indisponible !'
}

import styles from './InternalError.css';

function InternalError({
  categories,
}: NotFoundProps) {
  return (
    <div className={styles.wrapperFound}>
      <PageHeader image={item.image} notCategory title={item.title} description={item.description}/>
      <div className={styles.secondBlock}>
        <div className={styles.secondTitle}>Laissez-vous guider...</div>
        <div className={styles.topCategories}>
          {categories[0].children.map((category: any, index: number) =>
            <CardTopCategory key={index} category={category}/>
          )}
        </div>
      </div>
    </div>
  );
}

export default InternalError;
