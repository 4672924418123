import React from 'react';
import styles from './ReviewsList.css';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
const moment = require('moment');

moment.locale("fr");

type ReviewsListProps = {
  productReviews?: any;
};


function ReviewsList({productReviews}: ReviewsListProps) {

  const {productReviews: {reviews}} = productReviews

  return (
        <div className={styles.opinions}>
          {reviews.map((review: any) =>
            <div className={styles.review}>
              <div className={styles.reviewTitle}>
                <h2>{review.title}</h2>
                <ReactStars
                  size={16}
                  value={review.rating}
                  isHalf={true}
                  edit={false}
                  color={'#E2E2E2'}
                  activeColor={'#EF394F'}
                />
              </div>
              <p>{review.detail}</p>
              <p className={styles.author}>{review.nickName} {moment(review.createdAt).fromNow()}</p>
              <div className={styles.separator}/>
            </div>
          )}
        </div>
  );
}

export default ReviewsList;
