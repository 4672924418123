import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export const RECORD_KILIBA_VIEWS = gql`
  mutation RecordKilibaViews($input: RecordKilibaViewsInput!) {
    recordKilibaViews(input: $input)
  }
`;

export class RecordKilibaViewsMutation extends Mutation {
  static defaultProps = {
    mutation: RECORD_KILIBA_VIEWS
  };
}
