import React from 'react';
import * as styles from './ContactFormPage.css';

type ContactFormPageProps = {
  children?: any;
};

function ContactFormPage({children}: ContactFormPageProps) {

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Vous avez une question ?</div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default ContactFormPage;
