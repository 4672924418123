import React from 'react';
import * as styles from './ClubBenlux.css';
import {Button, ButtonTypes} from "../index";
import ClubCard from '../../assets/ClubCard.png';
import VIP from '../../assets/VIP.svg';
import Gateau from '../../assets/Gâteau.svg';
import Delivery from '../../assets/Boîte aux lettres.svg';
import VIPYellow from '../../assets/VIPYellow.svg';
import Picture from '../../assets/VIPImage.png';
import QuestionsDivider from '../../assets/questionsDivider.png';

// Club Benlux for Account layout /!\


type ClubBenluxProps = {};

function ClubBenlux({}: ClubBenluxProps) {

  return (
    <div className={styles.wrapper}>
      <div className={styles.blockCard}>
        <div className={styles.cardClub}>
          <div className={styles.container}>
            <div style={{display: 'flex'}}>
              <img src={VIP}/>
              <div className={styles.blockText1}>
                <h2>Mon porte-monnaie fidélité</h2>
                <p>Votre cagnotte fidélité s’élève actuellement à 0 €,
                  utilisables partiellement ou en totalité, dès maintenant. En
                  tant que membre du Club Benlux, vous ajoutez 2,5% du montant
                  total dans votre porte-monnaie fidélité à chaque achat.</p>
              </div>
              <div className={styles.credits}>Montant : <span>0 €</span></div>

            </div>
            <div className={styles.block2}>
              <img src={ClubCard}/>
              <div className={styles.blockText2}>
                <h2>Ma carte fidélité</h2>
                <p>Scannez votre carte virtuelle en magasin pour profiter de vos
                  avantages fidélité.</p>
                <Button
                  text={'Afficher la carte en plein écran'}
                  type={ButtonTypes.Tertiary}
                  as={'link'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.blockVIP}>
        <img src={Picture}/>
        <div className={styles.cardVIP}>
          <div className={styles.firstBlock}>
            <h2>Devenez membre VIP</h2>
            <p>Pour seulement 19,90 €/an, devenez membre VIP et profitez
              d’encore plus d’avantages !</p>
          </div>
          <div className={styles.secondBlock}>
            <div className={styles.bonusVIPBlock}>
              <img src={VIPYellow}/>
              <span>Les membre VIP ajoutent <strong>5%</strong> du montant total de chaque achat dans leur porte-monnaie fidélité.</span>
            </div>
            <div className={styles.bonusVIPBlock}>
              <img src={Delivery}/>
              <span>Profitez de la <strong>livraison gratuite</strong> pour toutes vos commandes sans minimum d’achat.</span>
            </div>
            <div className={styles.bonusVIPBlock}>
              <img src={Gateau}/>
              <span>Obtenez <strong>5%</strong> de réduction sur tout le site pendant toute la semaine de votre <strong>anniversaire</strong>.</span>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              as={'link'}
              type={ButtonTypes.Primary}
              text={'Devenir membre VIP'}
              style={{width: '100%'}}
            />
          </div>
        </div>
      </div>

      <div className={styles.questionsBlock}>
        <div className={styles.questions}>
          <h2>Question Fréquentes</h2>
          <p>Quel est le délai de livraison pour la livraison illimitée en tant
            que membre VIP ?</p>
          <p>Le bonus fidélité est-il valable pour les articles en promotion
            ?</p>
          <p>Puis-je bénéficier du programme fidélité si je ne réside pas en
            France ?</p>
        </div>
        <img src={QuestionsDivider}/>
      </div>
    </div>
  );
}

export default ClubBenlux;
