import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation SubscribeToNewsletter($input: SubscribeToNewsletterInput!) {
    subscribeToNewsletter(input: $input)
  }
`;

export class SubscribeToNewsletterMutation extends Mutation {
  static defaultProps = {
    mutation: SUBSCRIBE_TO_NEWSLETTER
  };
}

export const CONFIGURE_NEWSLETTER = gql`
  mutation ConfigureNewsletter($input: ConfigureNewsletterInput!) {
    configureNewsletter(input: $input)
  }
`;

export class ConfigureNewsletterMutation extends Mutation {
  static defaultProps = {
    mutation: CONFIGURE_NEWSLETTER
  };
}
