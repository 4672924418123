import React from 'react';
import styles from './FragranceAttributeSet.css';

type Props = {
  attributes: [
    {
      attributeCode: string
      value: string
    }
  ];
};

const displayedAttributes = [
  {
    code: "usageTips",
    label: "Conseils d'utilisation",
  },
  {
    code: "concentration",
    label: "Concentration",
  },
  {
    code: "topNotes",
    label: "Notes de tête",
  },
  {
    code: "heartNotes",
    label: "Notes de coeur",
  },
  {
    code: "bottomNotes",
    label: "Notes de fond",
  },
  {
    code: "ingredients",
    label: "Composition",
  },
]

const FragranceAttributeSet = ({attributes}: Props) => (
  <div className={styles.container}>
    <dl>
    {displayedAttributes.map(({code, label}, index: number) => {
      const attribute = attributes.find((a: any) => a.attributeCode === code);
      if (attribute) {
        return (
          <div key={index}>
            <dt>{label}</dt>
            <dd>{attribute.value}</dd>
          </div>
        )
      }

      return;
    })}
    </dl>
  </div>
);

export default FragranceAttributeSet;
