import React from 'react';
import styles from './OrderPage.css';
import {ButtonTypes, formatPrice} from "../../index";
import {Button} from "../index";
import {Link} from "react-router-dom";

const moment = require('moment');

moment.locale("fr");

type OrderPageProps = {
  order?: any;
};

function OrderPage({
                     order,
                   }: OrderPageProps) {

  const findContents = (name: string) => {
    switch (name) {
      case 'processing':
        return 'En cours de préparation';
      case 'complete':
        return 'Expédiée';
      case 'payment_review':
        return 'En cours de validation';
      default:
        return '[]'
    }
  }
  return (
    <div className={styles.wrapper}>
      <h1>Commande n°{order.incrementId} du {moment(order.createdAt).format('DD/MM/YY')}</h1>
      <div className={styles.block}>
        <div className={styles.alignMobile}>
          <h2>{findContents(order.status)}</h2>
          <div
            className={styles.mobilePrice}>{formatPrice(order.grandTotal)} €
          </div>
        </div>
        <div className={styles.orderData}>
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Numéro de commande :</span>
            <span className={styles.secondSpan}>#{order.incrementId}</span>
          </div>
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Date de la commande :</span>
            <span
              className={styles.secondSpan}>{moment(order.createdAt).format('D MMMM YYYY')}</span>
          </div>
          {/*<div className={styles.orderBlockText}>*/}
          {/*    <span className={styles.firstSpan}>Date d'expédition :</span>*/}
          {/*    /!*<span>{item.createdAt}</span>*!/*/}
          {/*</div>*/}
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Montant total :</span>
            <span
              className={styles.price}>{formatPrice(order.grandTotal)} €</span>
          </div>
        </div>
        {/*<Button*/}
        {/*  type={ButtonTypes.Secondary}*/}
        {/*  as={'link'}*/}
        {/*  text={'Suivre ma commande'}*/}
        {/*  style={{width: '100%', marginTop: 40, marginBottom: 40}}*/}
        {/*/>*/}
        <div className={styles.blockProducts}>
          {order.items.map((product: any) =>
            <div className={styles.cardProduct}>
              <img src={product.thumbnailUrl}/>
              <div className={styles.blockTextProduct}>
                <div>{product.manufacturer} </div>
                <div>{product.name} </div>
                <div>Quantité : {product.qty}</div>
                <div>{formatPrice(product.rowTotalInclTax)} €</div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.divider}/>
        <div className={styles.secondHeader}>
          <div>Informations sur la commande :</div>
          {order.invoices && order.invoices.length > 0 && order.invoices.length < 2 && (
            <div className={styles.invoiceLink}>
              <a href={order.invoices[0].url}>Télécharger la facture</a>
            </div>
          )}
          {order.invoices && order.invoices.length > 1 &&
          <div className={styles.dropdown}>
            <span className={styles.invoiceLink}>Télécharger les facture</span>
            <div className={styles.dropdownInvoices}>
              {order.invoices.map((invoice: any, index: number) =>
                <a href={invoice.url}>Facture n°{index}</a>
              )}
            </div>
          </div>
          }
        </div>
        <div className={styles.orderData}>
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Moyen de paiement :</span>
            <span
              className={styles.paymentMethod}>{order.paymentMethodName}</span>
          </div>
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Mode de livraison :</span>
            <span
              className={styles.paymentMethod}>{order.shippingDescription}</span>
          </div>
        </div>
        <div className={styles.orderBlockTextDelivery}>
          <div className={styles.firstSpan}>Adresse de livraison :</div>
          <div>
            <div
              className={styles.address}>{order.customerFirstname} {order.customerLastname}</div>
            {order.billingAddress.company && <span
              className={styles.address}>{`${order.billingAddress.company}, `}</span>}
            <div
              className={styles.address}>{order.billingAddress.street[0]}</div>
            <div
              className={styles.address}>{`${order.billingAddress.postcode} ${order.billingAddress.city}, ${order.billingAddress.countryId}`}</div>
          </div>
        </div>
        <div className={styles.orderBlockTextDelivery}>
          <div className={styles.firstSpan}>Adresse de facturation :</div>
          <div>
            <div
              className={styles.address}>{order.customerFirstname} {order.customerLastname}</div>
            {order.billingAddress.company && <span
              className={styles.address}>{`${order.billingAddress.company}, `}</span>}
            <div
              className={styles.address}>{order.billingAddress.street[0]}</div>
            <div
              className={styles.address}>{`${order.billingAddress.postcode} ${order.billingAddress.city}, ${order.billingAddress.countryId}`}</div>
          </div>
        </div>
        <div className={styles.blockButton}>
          <Button text={'Un problème avec la commande ?'}
                  type={ButtonTypes.Tertiary} as={'link'} href={'/aide'}/>
          <Button text={'Information sur les retours'}
                  type={ButtonTypes.Tertiary} as={'link'} href={'/aide'}/>
        </div>
      </div>
    </div>
  );
}

export default OrderPage;
