import React from 'react';
import store from '../../assets/store.png'
import {CardStore} from "../index";
import styles from './StoreDescription.css';

type StoreDescriptionProps = {
  content: any;
};

function StoreDescription({content}: StoreDescriptionProps) {
  const { address, image } = content;

  return (
    <div className={styles.storeDescriptionContainer}>
      <img src={image.url} className={styles.imgDesktop} alt={image.alt} loading="lazy"/>
      <CardStore
        button
        address={address}
        title={content.title}
        position={content.address}
        urlPath={content.urlPath}
        description={content.description}
        buttonText={content.buttonText}
      />
    </div>
  );
}

export default StoreDescription;
