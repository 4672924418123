import React, {useState} from 'react';
import styles from './ProductSearchBar.css';
import {Link} from "react-router-dom";
import formatPrice from "../../utils/formatPrice";
import {getPath} from "../../utils/getPath";

type ProductSearchbarProps = {
  hit: any;
  onClick: () => void;
};

function ProductSearchbar({
  hit,
  onClick,
}: ProductSearchbarProps) {
  const price = hit.price.EUR;
  return (
    <Link
      className={styles.card}
      to={getPath(hit.url)}
      onClick={onClick}
    >
      <img src={hit.image_url.replace('http://', 'https://')}/>
      <div className={styles.row}>
        <h2>{hit.manufacturer}</h2>
        <span className={styles.name}>{hit.name}</span>
        <span className={styles.shortDesc}>{hit.short_description}</span>
        <div className={styles.price}>
          {price.default_max && (
            <span className={(price.default_original_formated ? styles.specialPrice : '')}>à partir de&nbsp;</span>
          )}
          <span className={(price.default_original_formated ? styles.specialPrice : '')}>
            {formatPrice(price.default)}&nbsp;€
          </span>
          {price.default_original_formated && (
            <span className={styles.strikedPrice}>
              {price.default_original_formated}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}

export default ProductSearchbar;
