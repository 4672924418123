import React, { useEffect, useRef, useState } from "react";
import * as styles from "./AttributeFilter.css";
import searchBrand from "../../assets/search-brand.png";
import arrowfilters from "../../assets/filters-arrow.svg";

type AttributeFilterProps = {
  options: any;
  searchable?: any;
  value: any;
  onChangeOptions?: any;
  removeFilter?: any;
  multiple?: any;
  defaultValues?: any;
  onToggle: () => void;
  opened: boolean;
  title: string;
};

function AttributeFilter({
  options,
  searchable,
  value,
  removeFilter,
  onChangeOptions,
  defaultValues,
  multiple,
  title,
  opened,
}: AttributeFilterProps) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpened, setOpened] = useState(false);
  const dropdownRef = useRef();

  function searchBrandValue() {
    let input = (document.getElementById("searchbar") as HTMLInputElement)
      .value;
    input = input.toLowerCase();
    let value = document.getElementsByClassName(styles.labelBrand);
    let block = document.getElementsByClassName(styles.valueLabel);

    let i;
    for (i = 0; i < value.length; i++) {
      if (!value[i].innerHTML.toLowerCase().includes(input)) {
        // @ts-ignore
        value[i].style.display = "none";
        // @ts-ignore
        block[i].style.display = "none";
      } else {
        // @ts-ignore
        value[i].style.display = "list-item";
        // @ts-ignore
        block[i].style.display = "flex";
      }
    }
  }

  const toggleValue = (option: any) => {
    const tmpSelectedValues = selectedValues.filter(() => true);
    const valueIndex = tmpSelectedValues.indexOf(option.value);
    if (valueIndex === -1) {
      tmpSelectedValues.push(option.value);
    } else {
      tmpSelectedValues.splice(valueIndex, 1);
    }

    setSelectedValues(tmpSelectedValues);

    if (tmpSelectedValues.length === 0) {
      removeFilter();
    } else {
      onChangeOptions(tmpSelectedValues);
    }
  };

  const handleClickOutsideDropdown = (event: Event) => {
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("mousedown", handleClickOutsideDropdown);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    }
  }, [isOpened]);

  const optionsCount = options.filter((f: any) => f.count >= 1);

  if (optionsCount.length === 1) {
    return null;
  }

  return (
    <div ref={dropdownRef} className={styles.wrapper}>
      <div
        className={styles.filterButton + " " + (isOpened ? styles.active : "")}
        onClick={() => setOpened(!isOpened)}
      >
        <span>{title}</span>
        {selectedValues.length > 0 && (
          <span
            style={{
              backgroundColor: "black",
              display: "inline-block",
              padding: "5px",
              color: "#fff",
            }}
          >
            {selectedValues.length}
          </span>
        )}
        <img src={arrowfilters} alt={"filter"} />
      </div>
      {isOpened && (
        <div
          className={
            styles.filtersContent +
            " " +
            (searchable ? styles.brandDropdown : "")
          }
        >
          {searchable && (
            <div className={styles.contentSearchbar}>
              <input
                id={"searchbar"}
                className={styles.valueInput}
                onKeyUp={searchBrandValue}
                placeholder={"Rechercher une marque..."}
              />
              <div className={styles.valueInputImg}>
                <img src={searchBrand} alt="" />
              </div>
            </div>
          )}
          {options.map((option: any, index: number) => {
            const filtered = value?.find((f: any) => f === option.value);
            return (
              <>
                <div
                  key={index}
                  className={
                    styles.valueLabel + " " + (filtered ? styles.active : "")
                  }
                  onClick={() => toggleValue(option)}
                >
                  <div
                    style={
                      filtered
                        ? {
                            backgroundColor: "#000000",
                            height: 28,
                            width: 4,
                            borderBottomRightRadius: 20,
                            borderTopRightRadius: 20,
                          }
                        : {}
                    }
                  />
                  <span className={styles.labelBrand}>
                    {option.title} ({option.count})
                  </span>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AttributeFilter;
