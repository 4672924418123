import React from 'react';
import styles from './ProductPrice.css';

import formatPrice from '../../utils/formatPrice';

type Props = {
  price?: any;
};

function ProductPrice({price}: Props) {

  return (
    <div className={styles.productOptions}>
      <div className={styles.blockPrice}>
        {price.suggestedRetail > price.regular &&
          <div className={styles.retailPrice}>
            <span>Prix conseillé :&nbsp;</span><span className={styles.striked}>{formatPrice(price.suggestedRetail)} €</span>
          </div>
        }
        <div>
          <div className={price.special ? styles.strikedPrice : styles.price}>{formatPrice(price.regular)} €
          </div>
          {price.special &&
            <div className={styles.specialPrice}>{formatPrice(price.special)} €</div>
          }
        </div>
      </div>
    </div>
  );
}

export default ProductPrice;
