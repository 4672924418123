import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const SEND_CUSTOMER_REQUEST = gql`
  mutation SendCustomerRequestInput($input: SendCustomerRequestInput!) {
    sendCustomerRequest(input: $input) {
      email
      message
      subject
      firstname
      lastname
    }
  }
`;

export class SendCustomerRequestMutation extends Mutation {
  static defaultProps = {
    mutation: SEND_CUSTOMER_REQUEST,
  };
}
