import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {IsAuthenticatedQuery} from '@deity/falcon-ecommerce-uikit';

export const ProtectedRoute = ({ component, redirectTo, ...rest }) => {
  const Component = component;

  return (
    <Route
      {...rest}
      render={props => (
        <IsAuthenticatedQuery>
          {({ customer }) => {
            if (customer) {
              return <Component {...props} />;
            }

            const { location, staticContext } = props;
            if (staticContext) {
              staticContext.statusCode = 301;
            }

            const { pathname, search } = location;

            return (
              <Redirect
                to={{
                  pathname: redirectTo,
                  search: `?${new URLSearchParams({ next: `${pathname}${search}` })}`,
                  state: { nextLocation: location }
                }}
              />
            );
          }}
        </IsAuthenticatedQuery>
      )}
    />
  );
};

ProtectedRoute.defaultProps = {
  redirectTo: '/connexion'
};
