import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {SendCustomerRequestMutation} from "../mutation/SendCustomerRequestMutation";
import {FormField} from "../pages/account/SignIn/FormField";
import {Button, ButtonTypes, ContactFormPage} from "benlux-ecommerce-ui";
import {Select, Option} from "@deity/falcon-ui";
import {FormField as FormFieldDeity} from "@deity/falcon-ecommerce-uikit";

export const ContactFormLayout = () => {
  return (
    <ContactForm/>
  )
}

const options = [
  {
    name: "Ma commande / Suivi"
  },
  {
    name: "Ma commande / Annulation et remboursement"
  },
  {
    name: "Mon programme de fidélité"
  },
  {
    name: "Les produits ou les offres"
  },
  {
    name: "Mon compte client / Accès"
  },
  {
    name: "Mes données personnelles"
  },
  {
    name: "Le magasin BENLUX"
  },
  {
    name: "Autre"
  },
]

export const SubjectSelector = props => {
  const { value, onChange, ...restProps } = props;
  return (
    <Select
      {...restProps}
      value={value}
      onChange={({ target }) => onChange && onChange(target.value, options.find(x => x.name === value))}
    >
      <Option value selected hidden>
        Sélectionner l'objet de la demande...
      </Option>
      {options.map(x => (
        <Option key={x.name} value={x.name} selected>
          {x.name}
        </Option>
      ))}
    </Select>
  );
};

export const ContactForm = () => {
  const [completed, setCompleted] = useState(false);

  return (
    <SendCustomerRequestMutation>
      {(sendCustomerRequest, {loading, error}) => {
        return (
          <Formik
            initialValues={{email: '', firstname: '', lastname: '', subject: '', message: ''}}
            onSubmit={(values, {resetForm}) => {
              setCompleted(false);
              sendCustomerRequest({
                variables: {
                  input: {
                    ...values,
                  },
                }
              }).then(() => {
                resetForm();
                setCompleted(true);
              })
            }}
          >{({submitForm, setFieldValue}) => {
            return (
              <ContactFormPage>
                <Form>
                  <FormFieldDeity name="subject" required placeholder={'Sujet'} >
                    {({ form, field }) => (
                        <SubjectSelector
                          {...field}
                          onChange={x => form.setFieldValue(field.name, x)}
                        />
                    )}
                  </FormFieldDeity>
                  <FormField
                    name="email"
                    type="email"
                    required
                    placeholder={"Adresse email"}
                  />
                  <FormField
                    name="firstname"
                    type="text"
                    required
                    placeholder={"Prénom"}
                  />
                  <FormField
                    name="lastname"
                    type="text"
                    required
                    placeholder={"Nom"}
                  />
                  <div>
                     <textarea
                       name={"message"}
                       placeholder={'Votre message'}
                       rows={8}
                       cols={'auto'}
                       onChange={(event) => setFieldValue('message', event.target.value)}
                     />
                  </div>
                  <Button type={ButtonTypes.Secondary} onClick={submitForm} as={'button'}
                          text={'Envoyer'} loading={loading}/>
                  {completed && (
                    <p>Nous avons bien reçu votre demande. Un e-mail de confirmation vient d'être envoyé à votre adresse e-mail.</p>
                  )}
                </Form>
              </ContactFormPage>
            )
          }}
          </Formik>
        );
      }}
    </SendCustomerRequestMutation>
  )
}







