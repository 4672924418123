import React, {useState} from 'react';
import styles from "./SignIn.css";
import {Button} from "../index";
import {Link} from "react-router-dom";
import reinsurance1 from '../../assets/registerReinsurance1.png'
import reinsurance2 from '../../assets/registerReinsurance2.png'
import reinsurance3 from '../../assets/registerReinsurance3.png'
import facebook from "../../assets/facebookIcon.png";
import google from "../../assets/googleIcon.png";
import Input from "../Input";


type SignInProps = {
  onCompleted?: any;
  required?: any;
  children?: any;
  childrenSignUp?: any;
};

function SignIn({required, children, childrenSignUp}: SignInProps) {
  const [setValueEmail, valueEmail] = useState();

  return (
    <div className={styles.wrapper}>
      <div className={styles.signInWrapper}>
        <h1>
          Se connecter
        </h1>
        {/*<div className={styles.logWithApp}>
          <a href={"/#"} className={styles.blockAppLog}>
            <img src={facebook} alt={"facebook login"}/>
            <span>Se connecter</span>
          </a>
          <a href={"/#"} className={styles.blockAppLog}>
            <img src={google} alt={"google login"}/>
            <span>Se connecter</span>
          </a>
        </div>
        <p>Se connecter par email</p>*/}
        <p>&nbsp;</p>
        {children}
      </div>

      <div className={styles.registerWrapper}>
        <h1>
          Nouveau sur BENLUX ?
        </h1>
        <p className={styles.optionalText}>Psst, vous possédez déjà une carte
          fidélité BENLUX ? Vous pourrez la lier pendant l’inscription et
          retrouver vos avantages déjà cumulés !</p>
        <div className={styles.blockReinsurance}>
          <div className={styles.reinsurance}>
            <img src={reinsurance1} alt={"reinsurance register"}/>
            <span>Inscription simple et rapide</span>
          </div>
          <div className={styles.reinsurance}>
            <img src={reinsurance2} alt={"reinsurance register"}/>
            <span>Suivi des commandes</span>
          </div>
          <div className={styles.reinsurance}>
            <img src={reinsurance3} alt={"reinsurance register"}/>
            <span>Avantages fidélité</span>
          </div>
        </div>
        {/*<p>S’inscrire avec Facebook ou Google (conseillé, rapide)</p>
        <div className={styles.logWithApp}>
          <a href={"/#"} className={styles.blockAppLog}>
            <img src={facebook} alt={"facebook login"}/>
            <span>Se connecter</span>
          </a>
          <a href={"/#"} className={styles.blockAppLog}>
            <img src={google} alt={"google login"}/>
            <span>Se connecter</span>
          </a>
        </div>
        <p>S’inscrire avec son adresse email</p>*/}
        <p>&nbsp;</p>
        {/*<input placeholder={"Adresse email"} className={styles.inputStyle}/>*/}
        {/*<Input placeholder={"Adresse email"} />*/}
        {childrenSignUp}
        {/*<div className={styles.rightObject}>*/}
        {/*  <Link to={{*/}
        {/*    pathname: '/inscription',*/}
        {/*    state: {*/}
        {/*      email: 'jojo.bernard@gmail.fr'*/}
        {/*    }*/}
        {/*  }} className={styles.buttonConnect}>Créer mon*/}
        {/*    compte</Link>*/}
        {/*</div>*/}
        <p className={styles.optionalTextMobile}>Psst, vous possédez déjà une
          carte fidélité BENLUX ? Vous pourrez la lier pendant l’inscription et
          retrouver vos avantages déjà cumulés !</p>
      </div>
    </div>
  );
}

export default SignIn;
