import React, { useState } from "react";
import { ContentCards, FilterBrandsDropDown } from "../index";
import * as styles from "./NavigationBrandsDropDown.css";
import { Link } from "react-router-dom";
import arrowMenu from "../../assets/arrowMenu.png";
import arrow from "../../assets/arrowMenu.png";

type NavigationBrandsDropDownProps = {
  items: any;
  opened: boolean;
  onClickCloseMenu?: any;
  contents: [
    {
      title: string;
      image: string;
      urlPath: string;
    }
  ];
  onSelectClose?: any;
  onMenuLeave?: any;
};

function alphabeticalSort(aBrand: any, bBrand: any) {
  const aName = aBrand.name.toUpperCase();
  const bName = bBrand.name.toUpperCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}

function NavigationBrandsDropDown({
  opened,
  items,
  onClickCloseMenu,
  contents,
  onSelectClose,
  onMenuLeave,
}: NavigationBrandsDropDownProps) {
  const brandsPerLetter = {};
  items.children.forEach((brand: any, index: number) => {
    let firstCharacter = brand.name[0]
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();

    if (!firstCharacter.match("[A-Z]")) {
      firstCharacter = "#";
    }
    // @ts-ignore
    if (!brandsPerLetter[firstCharacter]) {
      // @ts-ignore
      brandsPerLetter[firstCharacter] = [];
    }
    // @ts-ignore
    brandsPerLetter[firstCharacter].push(brand);
  });

  let [brandsListVisible, setBrandsListVisible] = useState(false);

  const toggleBrandsListVisible = () =>
    setBrandsListVisible(!brandsListVisible);

  const bestSellersBrands = [
    { name: "Tom Ford", urlPath: "/tom-ford" },
    { name: "Lancôme", urlPath: "/lancome" },
    { name: "La Mer", urlPath: "/la-mer" },
    { name: "Estée Lauder", urlPath: "/estee-lauder" },
    { name: "BDK Parfums", urlPath: "/bdk-parfums" },
    { name: "L'Occitane", urlPath: "/l-occitane" },
    { name: "Diesel", urlPath: "/diesel" },
    { name: "Rado", urlPath: "/rado" },
    { name: "Nuxe", urlPath: "/nuxe" },
  ];
  const newestBrands = [
    { name: "Lancôme", urlPath: "/lancome" },
    { name: "Estée Lauder", urlPath: "/estee-lauder" },
    { name: "Rabanne", urlPath: "/rabanne" },
    { name: "Chloé", urlPath: "/chloe" },
    { name: "Tissot", urlPath: "/tissot" },
    { name: "Armani", urlPath: "/armani" },
    { name: "Longines", urlPath: "/longines" },
    { name: "Montblanc", urlPath: "/montblanc" },
    { name: "Caudalie", urlPath: "/caudalie" },
  ];

  const onMouseLeave = () => {
    setBrandsListVisible(false);
    onMenuLeave();
  };

  return (
    <div
      className={styles.navigationTabContent + (opened ? " active" : "")}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.menuMobile}>
        <div className={styles.subMenu}>
          <div
            className={
              styles.menuName + " " + (items.highlighted ? " highlighted" : "")
            }
          >
            {items.name}
          </div>
          <img
            onClick={onClickCloseMenu}
            src={arrowMenu}
            alt={"menu précédent"}
          />
        </div>
        <div className={styles.separator} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div onMouseEnter={() => setBrandsListVisible(true)}>
            <div
              className={styles.buttonBrands}
              onClick={toggleBrandsListVisible}
            >
              <span>De A à Z</span>
              <img
                src={arrow}
                alt={"menu"}
                className={styles.arrow + (brandsListVisible ? " active" : "")}
              />
            </div>
            <div
              className={
                styles.allBrands + " " + (brandsListVisible ? " active" : "")
              }
            >
              {Object.keys(brandsPerLetter)
                .sort()
                .map((letter, index) => (
                  <div className={styles.brandLetter} key={index}>
                    <span className={styles.letter}>{letter}</span>
                    <ul>
                      {
                        // @ts-ignore
                        brandsPerLetter[letter]
                          .sort(alphabeticalSort)
                          .map((brand: any, index: number) => (
                            <li key={index}>
                              <Link onClick={onSelectClose} to={brand.urlPath}>
                                {brand.name}
                              </Link>
                            </li>
                          ))
                      }
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          <FilterBrandsDropDown
            items={bestSellersBrands}
            title={"Tendances marques"}
            onSelectClose={onSelectClose}
            onMouseEnter={() => setBrandsListVisible(false)}
          />
          <FilterBrandsDropDown
            items={newestBrands}
            title={"Marques les plus vues"}
            onSelectClose={onSelectClose}
            onMouseEnter={() => setBrandsListVisible(false)}
          />
        </div>
      </div>
      {!brandsListVisible && (
        <div className={styles.mobileContents}>
          <ContentCards items={contents} onClickContentCards={onSelectClose} />
        </div>
      )}
    </div>
  );
}

export default NavigationBrandsDropDown;
