import React, {useState} from 'react';
import cross from '../../assets/cross.png';
import styles from './SwitchProductSelection.css';

type iProps = {
    item: any;
    selectedIndex: number;
    onSelect: (index: number) => void;
    announce?: any;
    addProduct?: any;
    onClick?: any;
};

function SwitchProductSelection({item, onSelect, selectedIndex, announce, addProduct,onClick}: iProps) {

    const [colorSelected, setColorSelected] = useState(Object);
    const [optionSelected, setOptionSelected] = useState(Object);

    const onClickSetOption = (option: any) => {
        setOptionSelected(option);
    }

    const onClickSetColor = (color: any) => {
        setColorSelected(color);
    }

    const onClickCloseAnnounce = () => {
        announce = document.getElementsByClassName(styles.announcement)[0];
        announce.style.opacity = 0;
        announce.style.transition = 'opacity 0.6s';
    }

    return (
        <div className={styles.switchSelectionsCardWrapper}>
            <h1>{item.label}</h1>
            <div className={styles.container}>
                <div className={styles.contentSelectionsSwitch}>
                    <div className={styles.switchImage}>
                        <div className={styles.thumbnail}>
                            {item.slice(0, 4).map((product: any, index: number) =>
                                <div className={styles.content} onClick={() => onSelect(index)}>
                                    <img src={product.thumbnail} alt={'thumbnail switch'}/>
                                </div>
                            )}
                        </div>
                        <div className={styles.large}>
                            {item.map((product: any, index: number) =>
                                <div key={index}
                                     className={styles.selectionsLargeImg + ' ' + (selectedIndex === index ? styles.active : '')}>
                                    <img src={product.big} alt={'product'}/>
                                </div>
                            )}
                            {item.announcement &&
                            <div className={styles.announcement}>
                                <div style={{
                                    borderRadius: 10,
                                    width: 8,
                                    height: 8,
                                    backgroundColor: '#EC5D30',
                                    marginRight: 6,
                                    marginLeft: 12,
                                }}/>
                                <h1>Offres :</h1>
                                <span>{item.announcement}</span>
                                <div className={styles.cross} onClick={onClickCloseAnnounce}>
                                    <img src={cross} alt={'close announcement'}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.selectionsSwitchDescription}>
                    <div className={styles.switchDescription}>
                        {item.map((product: any, index: number) =>
                            <div className={styles.selectionsDescription + ' ' + (selectedIndex === index ? styles.active : '')}>
                                <h1>{product.manufacturer}</h1>
                                <h2>{product.type}</h2>
                                <a className={styles.sawMore} href={'/#'}>Voir plus d'informations</a>
                                {product.colors ? (
                                    <>
                                        <div style={{display: 'flex', marginTop: 33, alignItems: 'center'}}>
                                            {product.colors.map((color: any, index: number) =>
                                                <div className={styles.color + ' ' + (colorSelected === color ? styles.active : '')}
                                                     style={{backgroundColor: color.color}}
                                                     onClick={() => onClickSetColor(color)}/>
                                            )}
                                            <span className={styles.colorName}>{colorSelected.name}</span>
                                        </div>
                                        <p style={{
                                            width: 425,
                                            height: 145,
                                            marginTop: 0,
                                            marginBottom: 56
                                        }}>{product.description}</p>
                                    </>
                                ) : product.options ? (
                                    <>
                                        <div style={{display: 'flex', marginTop: 33, alignItems: 'center'}}>
                                            {product.options.map((option: any) =>
                                                <div className={styles.option + ' ' + (optionSelected === option ? styles.active : '')}
                                                     onClick={() => onClickSetOption(option)}>
                                                    <img src={option.urlImg} alt={'option'}/>
                                                    <span>{option.value}</span>
                                                </div>
                                            )}
                                        </div>
                                        <p style={{
                                            width: 425,
                                            height: 145,
                                            marginTop: 0,
                                            marginBottom: 56
                                        }}>{product.description}</p>
                                    </>
                                ) : (
                                    <p>{product.description}</p>
                                )}
                                <button className={styles.buttonSelection} type={'submit'} onClick={onClick}>Je les veux ! {product.price.regular} - €</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SwitchProductSelection;

