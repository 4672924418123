import React from 'react';
import styles from './OutOfStock.css';
import vector from "../../assets/Vector.svg";
// import Input from "../Input";
import {Button, ButtonTypes} from "../index";

type OutOfStockProps = {
  inputBackInStock?: any;
};

const OutOfStock = ({ inputBackInStock }: OutOfStockProps) =>
  <div className={styles.wrapper}>
   <div className={styles.form}>
      <div className={styles.headerForm}>
        <span className={styles.dot}/>
        <span className={styles.text}>Ce produit sera bientôt de retour !</span>
      </div>
      <div className={styles.formInput}>
        {inputBackInStock}
      </div>
    </div>
  </div>

export default OutOfStock;
