import React from 'react';
import styles from './HeaderReinsurance.css';

type HeaderReinsuranceProps = {
  textsContent?: {
    content: string
  };
}

const HeaderReinsurance = ({textsContent}: HeaderReinsuranceProps) => {
  const texts = (textsContent ? JSON.parse(textsContent.content) : []);
  return (
    <div className={styles.headerReinsurance}>
      <span className={styles.dot}/>
      <div className={styles.rotateText}>
        {texts.map((text: string, index: number) =>
          <div key={index}>{text}</div>
        )}
      </div>
    </div>
  );
}

export default HeaderReinsurance;
