import React from 'react';
import styles from './CardDescription.css';
import {formatPrice} from '../../utils';
import {Button, ButtonTypes} from "../index";

type CardDescriptionProps = {
  item: any;
  selected: boolean;
};

function CardDescription({
  item,
  selected,
}: CardDescriptionProps) {
  return (
    <div className={styles.content + ' ' + (selected ? styles.active : '')}>
      <h2>{item.brandName}</h2>
      <div className={styles.category}>{item.name}</div>
      <div className={styles.subtitle}>{item.shortDescription}</div>
      <div className={styles.price}>{formatPrice(item.price.regular)} €</div>
      {item.description &&
      <p className={styles.description}>{item.description}</p>
      }
      <Button
        as={'link'}
        type={ButtonTypes.Primary}
        text={'Je le veux'}
        href={item.urlPath}
        style={{margin: 0}}
      />
    </div>
  );
}

export default CardDescription;
