import gql from 'graphql-tag';
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_FOOTER_DATA = gql`
  query FooterData {
    trustpilotData {
      profileUrl,
      ratingCount,
      ratingValue,
      bestRating,
      starsString,
      stars
    }
    cmsBlock(identifier: "footer") {
        title
        content
        active
    }
  }
`;

export class FooterQuery extends Query {
    static defaultProps = {
        query: GET_FOOTER_DATA
    };
}
