import React from 'react';
import {lastOrder} from "../../variable/DataDelivery";
import './style.css'
import styles from './style.module.css';

function CardLastOrder() {
    return (
            <div className={styles.cardLastOrderItem}>
                {lastOrder.slice(0, 1).map(order => (
                    <div className={styles.cardOrder} key={'order'}>
                        <img src={order.urlImg} alt="logo" />
                        <div style={{}}>
                            <div className={styles.status}>{order.status}</div>
                            <div className={styles.date}>Livraison estimée le {order.deliveryDate}</div>
                        </div>
                        <div style={{}}>
                            <div className={styles.productNumber}>{order.productNumber}</div>
                            <div className={styles.price}>{order.price}</div>
                        </div>
                    </div>
                ))}
            </div>
    );
}

export default CardLastOrder;
