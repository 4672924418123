import React, {useState} from 'react';
import * as styles from './SearchPriceFilter.css'
import {Link} from "react-router-dom";
import searchBrand from "../../assets/search-brand.png";
import arrowfilters from "../../assets/filters-arrow.svg";

type SearchPriceFilterProps = {
  items: any;
  children?: any;
};

function SearchPriceFilter({
                              items,
                              children
                            }: SearchPriceFilterProps) {

  let [filterVisible, setfilterVisible] = useState(false);



  return (
    <div style={{position: 'relative'}}>
      <div className={styles.menu + (filterVisible ? ' active' : '')} onClick={() => setfilterVisible(!filterVisible)}>
        <span>Prix</span>
        <img src={arrowfilters} alt={'filter'}/>
      </div>
      <div className={styles.dropdown + (filterVisible ? ' active' : '')}>
        {children}
      </div>
    </div>

  );
}

export default SearchPriceFilter;
