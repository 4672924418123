import React, {useEffect, useRef} from 'react';
import styles from './TintSwatch.css';

type TintSwatchProps = {
  configurableOption: {
    attributeId: string
    values: [
      {
        inStock: []
        id: string
        valueIndex: number
        hexColor: string
        label: string
      }
    ]
  }
  onChange: (ev: object, selectedIndex: number) => void
  error?: any;
  selectedIndex: number;
};

function TintSwatch({
  configurableOption,
  onChange,
  error,
  selectedIndex
}: TintSwatchProps) {
  const {attributeId, values} = configurableOption;

  useEffect(() => {
    const value = values[selectedIndex];
    onChange({target: {name: attributeId, value: value.valueIndex}}, selectedIndex);
  }, [selectedIndex]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {values.map((value, index: number) => {
          return (
            <div
              style={{backgroundColor: '#' + value.hexColor}}
              className={styles.value + (selectedIndex === index ? ' active' : '')}
              key={index}
            >
              <input
                id={'input'}
                disabled={value.inStock.length === 0}
                key={value.id}
                type={'radio'}
                onChange={(ev) => onChange(ev, index)}
                value={value.valueIndex}
                name={attributeId}
                defaultChecked={index === selectedIndex}
              />
            </div>
          )
        })}
      </div>
      <div className={styles.selectedValueLabel}>{values[selectedIndex].label}</div>
      {!!error &&
        <span style={{color: 'red'}}>Sélectionnez les options obligatoires</span>
      }
    </div>
  );
}

export default TintSwatch;
