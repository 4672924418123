import React, { useState } from "react";
import login from "../../assets/LogIn.png";
import { Button, ButtonTypes, CardLastOrder } from "../index";
import { Link } from "react-router-dom";

import styles from "./HeaderAccount.css";
import userIcon from "./user.svg";

type HeaderAccountProps = {
  Login?: any;
  customer?: boolean;
  signOut?: any;
};

function HeaderAccount({
  Login,
  customer = false,
  signOut,
}: HeaderAccountProps) {
  return (
    <div className={styles.wrapper}>
      <Link to={"/compte"} className={styles.icon}>
        <img src={userIcon} alt={"Mon compte"} />
      </Link>
      {!customer ? (
        <div className={styles.dropdown}>
          <div className={styles.dropdownLogin}>
            <img src={login} alt={"login"} />
            <Button
              text={"Se connecter ou s'inscrire"}
              href={Login}
              as={"link"}
              type={ButtonTypes.Tertiary}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dropdown + " " + styles.account}>
          <div className={styles.dropdownAccount}>
            <ul className={styles.right}>
              <li>
                <Link to={"/compte"}>Mon compte</Link>
              </li>
              <li>
                <Link to={"/compte/commandes"}>Mes commandes</Link>
              </li>
              <li>
                <Link to={"/aide"}>Besoin d'aide ?</Link>
              </li>
              <li onClick={signOut}>Se déconnecter</li>
            </ul>
            {/* <div className={styles.left}>
            <h1>Ma dernière commande</h1>
            <CardLastOrder/>
            <h2>Club BENLUX</h2>
            <span>Votre porte-monnaie fidélité contient 4,40€ utilisables dès aujourd'hui !</span>
            <div style={{marginTop: 10, textAlign: 'right'}}>
              <a href={'/club-benlux'}>Envie d'encore + d'avantages ?</a>
            </div>
          </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderAccount;
