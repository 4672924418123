import React, {MouseEvent, useState} from 'react';
import {Level4List} from "../index";
import * as styles from './Level3RightList.css';
import {Link} from "react-router-dom";
import arrow from "../../assets/arrowMenu.png";

type Level3RightListProps = {
  items: any;
};

function Level3RightList({
  items,
}: Level3RightListProps) {
  
  const [level3Selected, setLevel3Selected] = useState<number>();
  const notHighlighted = items.find((child: any) => !child.highlighted);


  const onClickLevel3 = (id: number, e: MouseEvent, level3ItemLength: number) => {
    setLevel3Selected(id)
    if (window.innerWidth <= 768 && level3ItemLength > 0) {
      e.preventDefault();
    }
  }

  return (
    <ul className={styles.grid}>
      {items.map((level3Item: any, level3Index: number) =>
        <li
          className={styles.listWrapper + ((level3Item.id === level3Selected && level3Item.children.length > 0) ? ' active' : '')}
          key={'level-3-item-' + level3Index}
        >
          <div className={styles.dropDown}>
            <Link
              to={level3Item.urlPath}
              onClick={(e: MouseEvent) => onClickLevel3(level3Item.id, e, level3Item.children.length)}
              className={styles.item}
            >
              {level3Item.name}
            </Link>
            {level3Item.children.length > 0 &&
              <img
                src={arrow}
                alt={'menu'}
                className={styles.arrow + ((level3Item.id === level3Selected) ? ' active' : '')}
              />
            }
          </div>
          <Level4List
            items={level3Item}
            level3Selected={level3Selected}
          />
        </li>
      )}
    </ul>
  );
}

export default Level3RightList;
