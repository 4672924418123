import React, {useState} from 'react';
import arrowfilters from "../../assets/filters-arrow.svg";
import * as styles from './Filters.css'
import {SortDropdown} from "../index";
import PriceFilter from "../PriceFilter";
import AttributeFilter from "../AttributeFilter";

interface iProps {
  data?: any;
  onChangeOptions?: any;
  onChangePrice?: any;
  removeFilter?: any;
  excludedFilters: string[];
  priceLimits?: any;
  sortOrdersProps?: any;
}

function Filters({data, onChangeOptions, onChangePrice, removeFilter, excludedFilters = [], priceLimits, sortOrdersProps}: iProps) {
  const [openedIndex, setOpenedIndex] = useState<number|null>(null);

  const toggleFilter = (index: number) => {
    if (index === openedIndex) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  }

  data = data.filter((data: any) => excludedFilters.indexOf(data.field) === -1);

  return (
    <div className={styles.filters}>
      <SortDropdown
        {...sortOrdersProps}
        onToggle={() => toggleFilter(0)}
        opened={openedIndex === 0}
      />
      <PriceFilter
        min={Math.floor(priceLimits.min)}
        max={Math.ceil(priceLimits.max)}
        onChangePrice={(price: any) => onChangePrice('price', price.values)}
        opened={openedIndex === 1}
        onToggle={() => toggleFilter(1)}
      />
      {/*// @ts-ignore*/}
      {data.map(({field, title, options, value}, index) => (
          <div className={styles.container} key={index}>
              <AttributeFilter
                options={options}
                onChangeOptions={(option: any) => onChangeOptions(field, option)}
                value={value}
                title={title === 'Category' ? 'Catégorie' : title}
                searchable={field === 'manufacturer'}
                removeFilter={() => removeFilter(field)}
                defaultValues={value}
                opened={openedIndex === (index + 2)}
                onToggle={() => toggleFilter(index + 2)}
              />
            </div>
      ))}
    </div>

  );
}

export default Filters;
