import React, { useState } from "react";
import styles from "./BrandHeader.css";
import placeholder from "../ContentCards/placeholder.jpg";
import { Link } from "react-router-dom";
import { CarouselsSwitch } from "../index";

type Props = {
  brand: any;
  contents?: [
    {
      title: string;
      image: {
        url: string;
        alt: string;
      };
      urlPath: string;
    }
  ];
  brandPath?: string;
};

const diorItemsNews = [
  {
    id: "1a",
    name: "Nouveauté 1",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "2a",
    name: "Nouveauté 2",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "3a",
    name: "Nouveauté 3",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "4a",
    name: "Nouveauté 4",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "5a",
    name: "Nouveauté 5",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "6a",
    name: "Nouveauté 6",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "7a",
    name: "Nouveauté 7",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "8a",
    name: "Nouveauté 8",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
];

const diorItemsBest = [
  {
    id: "1b",
    name: "Meilleure Vente 1",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "2b",
    name: "Meilleure Vente 2",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "3b",
    name: "Meilleure Vente 3",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "4b",
    name: "Meilleure Vente 4",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "5b",
    name: "Meilleure Vente 5",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "6b",
    name: "Meilleure Vente 6",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "7b",
    name: "Meilleure Vente 7",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
  {
    id: "8b",
    name: "Meilleure Vente 8",
    brandName: "Dior",
    shortDescription: "Eau de Parfum",
    promotionalBadge: null,
    new: false,
    price: {
      regular: 76,
      special: null,
      minTier: null,
      __typename: "ProductPrice",
    },
    thumbnail:
      "https://magento.staging.benlux.sntive.net/pub/media/catalog/product/cache/83bc033498dc8c6f6614cdee54a7b5d2/3/3/3348901571456_0_1.jpg",
    urlPath: "/miss-dior-eau-de-parfum-dior",
    __typename: "Product",
  },
];

export default function BrandHeader({
  brand,
  contents,
  brandPath = null,
}: Props) {
  const [collapse, setCollapse] = useState(false);
  const [universDiorCollapsed, setUniversDiorCollapsed] = useState(true);

  const truncate = (str: any) => {
    const regex = /^(.{100})(?:\s+\w*)?/s;
    const extract = str.match(regex);
    if (extract) {
      return extract[1] + "...";
    }

    return "...";
  };

  const diorChildren = [
    {
      name: "Parfums Femme",
      urlPath: "/parfum-femme-dior",
      children: [
        {
          name: "J'adore",
          urlPath: "/j-adore",
          __typename: "Category",
        },
        {
          name: "Miss Dior",
          urlPath: "/miss-dior",
          __typename: "Category",
        },
        {
          name: "JOY de Dior",
          urlPath: "/joy-de-dior",
          __typename: "Category",
        },
        {
          name: "Poison",
          urlPath: "/poison",
          __typename: "Category",
        },
        {
          name: "Les Escales de Dior",
          urlPath: "/les-escales-de-dior",
          __typename: "Category",
        },
        {
          name: "Dior Addict",
          urlPath: "/dior-addict",
          __typename: "Category",
        },
        {
          name: "Dune",
          urlPath: "/dune",
          __typename: "Category",
        },
        {
          name: "Dolce Vita",
          urlPath: "/dolce-vita",
          __typename: "Category",
        },
        {
          name: "Les Créations de Monsieur Dior",
          urlPath: "/les-creations-de-monsieur-dior",
          __typename: "Category",
        },
      ],
      __typename: "Category",
    },
    {
      name: "Parfums Homme",
      urlPath: "/parfum-homme-dior",
      children: [
        {
          name: "Sauvage",
          urlPath: "/sauvage",
          __typename: "Category",
        },
        {
          name: "Dior Homme",
          urlPath: "/dior-homme",
          __typename: "Category",
        },
        {
          name: "Fahrenheit",
          urlPath: "/dior-fahrenheit",
          __typename: "Category",
        },
        {
          name: "Eau Sauvage",
          urlPath: "/eau-sauvage",
          __typename: "Category",
        },
        {
          name: "Higher",
          urlPath: "/higher",
          __typename: "Category",
        },
        {
          name: "Dune pour homme",
          urlPath: "/dune-pour-homme",
          __typename: "Category",
        },
        {
          name: "Jules",
          urlPath: "/jules",
          __typename: "Category",
        },
      ],
      __typename: "Category",
    },
    {
      name: "Maquillage",
      urlPath: "/maquillage-dior",
      children: [
        {
          name: "Teint",
          urlPath: "/teint",
          __typename: "Category",
        },
        {
          name: "Yeux",
          urlPath: "/yeux",
          __typename: "Category",
        },
        {
          name: "Lèvres",
          urlPath: "/levres",
          __typename: "Category",
        },
        {
          name: "Ongles",
          urlPath: "/dior-ongles",
          __typename: "Category",
        },
      ],
      __typename: "Category",
    },
    {
      name: "Soin",
      urlPath: "/soin-dior",
      children: [
        {
          name: "Démaquillants",
          urlPath: "/demaquillants",
          __typename: "Category",
        },
        {
          name: "Lotions",
          urlPath: "/lotions",
          __typename: "Category",
        },
        {
          name: "Sérums",
          urlPath: "/serums",
          __typename: "Category",
        },
        {
          name: "Crèmes",
          urlPath: "/cremes",
          __typename: "Category",
        },
        {
          name: "Emulsions",
          urlPath: "/emulsions",
          __typename: "Category",
        },
        {
          name: "Soins yeux et lèvres",
          urlPath: "/soins-yeux-et-levres",
          __typename: "Category",
        },
        {
          name: "Protections UV",
          urlPath: "/protections-uv",
          __typename: "Category",
        },
        {
          name: "Cushions",
          urlPath: "/cushions",
          __typename: "Category",
        },
        {
          name: "Gommages et Masques",
          urlPath: "/gommages-et-masques",
          __typename: "Category",
        },
        {
          name: "Autobronzants et Protections solaires",
          urlPath: "/autobronzants-et-protections-solaires",
          __typename: "Category",
        },
      ],
      __typename: "Category",
    },
  ];

  const inDiorBrand = brand.name === "Dior-" || brandPath === "/dior-";

  return (
    <>
      {inDiorBrand && (
        <Link
          to={"/dior"}
          style={{
            display: "block",
            margin: "24px auto 4px",
            padding: "16px 0 10px",
            background: "#000",
            textAlign: "center",
            maxWidth: "1200px",
          }}
        >
          <img
            src={
              "https://www.sephora.fr/on/demandware.static/-/Sites-siteCatalog_Sephora_FR/default/dwda4ff99c/all_brands/Visual_brands/LOGO_DIOR_CAPITALES_BLANC - Copie.svg"
            }
            alt={"Dior"}
            height={"32px"}
          />
        </Link>
      )}
      {brand.image &&
        ["HERMÈS", "Carolina Herrera"].indexOf(brand.name) === -1 &&
        !inDiorBrand && (
          <div className={styles.wrapper}>
            {brand.image && (
              <img
                src={brand.image}
                alt={brand.name}
                style={{
                  position: "absolute",
                  inset: 0,
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
                fetchpriority="high"
              />
            )}
          </div>
        )}
      {brand.name === "HERMÈS" && (
        <Link
          to={"/terre-d-hermes-eau-givree-eau-de-parfum-hermes"}
          style={{ display: "block", fontSize: 0 }}
        >
          <img
            src={brand.image}
            style={{ width: "100%" }}
            alt={"Terre d'Hermès Eau Givrée"}
            fetchpriority="high"
          />
        </Link>
      )}
      {brand.name === "Carolina Herrera" && (
        <Link
          to={"/good-girl-eau-de-parfum-carolina-herrera"}
          style={{ display: "block", fontSize: 0 }}
        >
          <img
            src={brand.image}
            style={{ width: "100%" }}
            alt={"Good Girl de Carolina Herrera"}
            fetchpriority="high"
          />
        </Link>
      )}
      {inDiorBrand && (
        <img
          src={brand.image}
          style={{ maxWidth: "1200px", margin: "auto", display: "block" }}
        />
      )}
      <div
        className={styles.detailsWrapper + " " + (inDiorBrand ? "dior" : "")}
      >
        <div
          className={
            styles.detailsContainer +
            " " +
            (contents ? styles.withContents : "")
          }
        >
          <div className={styles.description}>
            {brand.seo && brand.seo.h1 && brand.seo.h1 !== "" ? (
              <h1>{brand.seo.h1}</h1>
            ) : (
              <h1>{brand.name}</h1>
            )}
            <p>{brand.description}</p>
            <p className={styles.collapseDescription}>
              <span
                className={
                  styles.descriptionText + " " + (collapse ? styles.active : "")
                }
              >
                {brand.description}
              </span>
              <span
                className={styles.showMore}
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? "Réduire" : "En savoir plus"}
              </span>
            </p>
          </div>
          {contents && contents.length && (
            <div className={styles.contents}>
              {contents.map((content: any, index: number) => (
                <div className={styles.cards} key={index}>
                  <Link to={content.urlPath}>
                    <img
                      src={content.image.url ? content.image.url : placeholder}
                      alt={content.image.alt}
                    />
                    <span>{content.title}</span>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {inDiorBrand && (
        <div className={styles.diorWrapper}>
          <div
            className={styles.diorDropdown}
            style={{
              flex: 1,
              fontSize: "14px",
              background: "#000",
              textAlign: "left",
              color: "#FFF",
              textTransform: "uppercase",
              textDecoration: "none",
              position: "relative",
            }}
          >
            <span
              style={{
                color: "#FFF",
                textAlign: "center",
                padding: "12px 24px",
                fontSize: "14px",
                textTransform: "uppercase",
                display: "block",
                cursor: "pointer",
              }}
            >
              Tous les univers Dior
            </span>
            <span
              style={{
                color: "#FFF",
                position: "absolute",
                right: "24px",
                top: "8px",
                fontSize: "18px",
              }}
            >
              +
            </span>
            <ul
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                width: "100vw",
                maxWidth: "1200px",
                background: "#fafafa",
                padding: "24px",
                margin: 0,
                right: 0,
                zIndex: 999,
                display: "flex",
                gap: "24px",
              }}
            >
              {diorChildren.map((child, cIndex) => (
                <li key={cIndex} style={{ flex: 1 }}>
                  <span>
                    <Link to={child.urlPath}>{child.name}</Link> +
                  </span>
                  <ul style={{ marginTop: "2px", marginBottom: "12px" }}>
                    {child.children.map((child2, c2Index) => (
                      <li key={c2Index}>
                        <Link to={child2.urlPath}>{child2.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <span
            className={styles.diorDropdownMask}
            onClick={(e) => e.preventDefault()}
          />
          <Link
            to={"/expertise-dior"}
            style={{
              flex: 1,
              padding: "12px 24px",
              fontSize: "14px",
              background: "#000",
              textAlign: "center",
              color: "#FFF",
              textTransform: "uppercase",
              textDecoration: "none",
            }}
          >
            L'Expertise Dior
          </Link>
        </div>
      )}
      {inDiorBrand && (
        <div>
          <CarouselsSwitch news={diorItemsNews} bestSellers={diorItemsBest} />
        </div>
      )}
      {/*<div>*/}
      {/*    {contents && contents.length &&*/}
      {/*      <div style={{display: 'flex'}}>              */}
      {/*        <div style={{display: 'flex'}}>*/}
      {/*          {contents.map((content: any) =>*/}
      {/*            <div>*/}
      {/*              <img src={content.image.url} alt={content.image.url}/>*/}
      {/*              <div className={styles.title}>*/}
      {/*                <span>{content.title}</span>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*</div>*/}
      {/* <div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 35,
                paddingTop: 24
              }}>
                <h1>{brand.name}</h1>
                {/*<div className={styles.follow} onClick={onClickFollowBrand}>
                  <img src={followBrand} alt={'follow brand'}/>
                  <span>Suivre</span>
                </div>
              </div>
              <span>{brand.description}</span>
            </div>
          )}
        </div>
      </div>
    </div>*/}
    </>
  );
}
