import React from 'react';
import styles from './MaskedOrder.css';
import {OrderCard} from "../index";

type MaskedOrderProps = {
  order?: any;
};



function MaskedOrder({
                       order,
                  }: MaskedOrderProps) {
  return (
    <div className={styles.wrapper}>
      <OrderCard item={order}/>
    </div>
  );
}

export default MaskedOrder;
