import React, {useEffect} from 'react';
import { Portal, Backdrop, Box } from '@deity/falcon-ui';
// import "./Sidebar.css";
import {SidebarLayout} from "benlux-ecommerce-ui";

export const Sidebar = ({ close, isOpen, side, children }) => {

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'initial';

        return () => {
            document.body.style.overflow = 'initial';
        }
    }, [isOpen]);

    return (
        <React.Fragment>
            <SidebarLayout visible={isOpen} side={side}>
                    <Box position="relative" flex={1}>
                        {children}
                    </Box>
            </SidebarLayout>
            <Backdrop as={Portal} visible={isOpen} onClick={() => close && close()} />
        </React.Fragment>
    );
};
