import React from "react";
import { Link } from "react-router-dom";

import styles from "./FooterCategory.css";

type Props = {
  category: {
    longDescription: string;
  };
  hideDescription?: boolean;
  links?: [
    {
      title: string;
      titleUrlPath?: string;
      items: [
        {
          name: string;
          urlPath: string;
        }
      ];
    }
  ];
};

const FooterCategory = ({
  category,
  links,
  hideDescription = false,
}: Props) => (
  <>
    {!hideDescription && (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: category.longDescription }}
      />
    )}
    {links && (
      <ul className={styles.links}>
        {links.map(({ title, titleUrlPath, items }) => {
          if (items.length > 0 || titleUrlPath) {
            return (
              <li key={title}>
                {titleUrlPath ? (
                  <b>
                    <Link to={titleUrlPath}>{title}</Link>
                  </b>
                ) : (
                  <b>{title}</b>
                )}
                <ul>
                  {items.map((item) => (
                    <li key={item.urlPath}>
                      <Link to={item.urlPath}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            );
          }

          return null;
        })}
      </ul>
    )}
  </>
);

export default FooterCategory;
