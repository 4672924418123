import React, {useState} from 'react';
import styles from './SearchPageLayout.css';
import {Filters, ProductListHeader, ProductsList} from "../index";

type SearchPageLayoutProps = {
  category?: any;
  categories?: boolean;
  products?: boolean;
  onClickPages?: any;
  onClickProducts?: any;
  onClickCat?: any;
  indexCategory?: any;
  indexProducts?: any;
  indexPages?: any;
};

function SearchPageLayout({
  categories,
  products,
                            category,
                            onClickCat,
  onClickProducts,
  onClickPages,
                            indexCategory,
                            indexProducts,
                            indexPages
                     }: SearchPageLayoutProps) {
  const [childArray, setChild] = useState(Object);
  const [displayChild, setDisplayChild] = useState(Boolean);
  const [indexCat, setIndex] = useState<number>();

  const onClickIndex = (index: number, cat: Object) => {
    setDisplayChild(true);
    setChild(cat);
    setIndex(index)
  }

  return (

    <div  style={{display: 'flex', justifyContent: 'center'}}>
          <div className={styles.rowButtonCategory}>
            <div
              onClick={onClickCat}
              className={styles.buttonCategory + (indexCategory ? ' active' : '')}
            >
              <span>Catégories</span>
            </div>
            <div
              onClick={onClickProducts}
              className={styles.buttonCategory + (indexProducts ? ' active' : '')}
            >
              <span>Produits</span>
            </div>
            <div
              onClick={onClickPages}
              className={styles.buttonCategory + (indexPages ? ' active' : '')}
            >
              <span>Pages</span>
            </div>
          </div>
    </div>
  );
}

export default SearchPageLayout;
