import React, {useState} from 'react';
import moment from 'moment';

import {formatPrice} from '../../utils';

import styles from './ShippingMethodOption.css';

type CheckboxDeliveryProps =
  Omit<JSX.IntrinsicElements["input"], "value"> & {
  value?: string;
  item?: any;
};

function ShippingMethodOption({value = "", onChange, item, children, ...allProps}: CheckboxDeliveryProps) {
  const [val, setVal] = useState(value);

  return (
    <div>
      <div>
        <input
          id={item.carrierCode}
          value={val}
          type={'radio'}
          {...allProps}
          className={styles.checkbox}
          onChange={e => {
            setVal(() => e.target?.value);
            onChange && onChange(e);
          }}
        />
        <label className={styles.wrapper} htmlFor={item.carrierCode}>
          <div className={styles.description}>
            <div className={styles.option}>
              {item.methodTitle}
            </div>
            <div className={styles.date}>{item.carrierCode === 'clickandcollect' ? (
              <span>{moment(item.estimatedDeliveryDate).calendar({
                sameDay: '[aujourd\'hui à partir de] H[h]mm',
                nextDay: '[demain dès] H[h]',
                nextWeek: 'dddd Do MMMM [à partir de] H[h]',
                sameElse: 'dddd Do MMMM [à partir de] H[h]'
              })}</span>
            ) : (
              <span>à partir du {moment(item.estimatedDeliveryDate).format('dddd Do MMMM')}</span>
            )}
          </div>
          </div>
          <div className={styles.price}>
            {item.amount > 0 ? formatPrice(item.amount) + ' €' : (item.carrierCode === 'clickandcollect' ? 'Gratuit' : 'Offert')}
          </div>
        </label>
      </div>
      {children}
    </div>
  );
}

export default ShippingMethodOption;
