import React, {ReactNode, useState} from 'react';
import * as styles from './ProductListHeader.css';
import {Link} from "react-router-dom";
import resetFilter from '../../assets/resetFilter.png';
import cross from "../../assets/cross.png";
import Breadcrumbs from "../Breadcrumbs";

type Props = {
  clearFilter?: any;
  children?: any;
  breadcrumbs?: any;
  brandName?: string;
  searchQuery?: any;
  categoryName?: any;
};

function ProductListHeader({
  clearFilter,
  children,
  breadcrumbs,
  brandName,
  searchQuery,
  categoryName
}: Props) {
  const [openedFilterMenu, setOpenedFilterMenu] = useState(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.mobileFilter} onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>Filtrer</div>
      <div className={styles.container + ' ' + (openedFilterMenu ? styles.activeMenu : '')}>
        <div className={styles.closeFilter} onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          <div>Filtres</div>
          <img src={cross}/>
        </div>
        { (brandName && !searchQuery) ? (
          <div className={styles.slugContent}>
            <span>Tous les produits {brandName}</span>
          </div>
        ) : (searchQuery ? (
          <div className={styles.slugContent}>
            <span>Résultats pour "{searchQuery.query}"</span>
          </div>
        ) : (
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            currentPageName={categoryName}
            className={styles.breadcrumb}
          />
        ))}
        <div className={styles.flexFilters}>
          <div onClick={clearFilter} className={styles.resetFilters}>
            <span>Réinitialiser les filtres</span>
            <img src={resetFilter} alt={'reset filter'}/>
          </div>
          {children}
        </div>
      </div>
      {searchQuery && (
        <div className={styles.resultMobile}>Résultats pour "{searchQuery.query}"</div>
      )}
    </div>
  );
}

export default ProductListHeader;
