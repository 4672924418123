import React from 'react';
import * as styles from './CardTopCategory.css';
import {Link} from "react-router-dom";

type CardTopCategoryProps = {
  category: any;
};

function CardTopCategory({category}: CardTopCategoryProps) {
  return (
    <Link className={styles.card} to={category.urlPath}>
      {category.name}
    </Link>
  );
}

export default CardTopCategory;
