import React from 'react';
import Carousel from 'react-multi-carousel';

import {
  Button,
  ButtonTypes,
  CardStore,
  CardTopCategory,
  StoreMap
} from '../index';

import arrow from '../../assets/arrow-slider.svg';
import parfum from '../../assets/parfumImage.jpg';

import * as styles from './StorePage.css'

type Props = {
  categories?: any;
  contents: {
    description: string
    title: string
    storyUrl: string
    storyDescription: string
    storyTitle: string
    storyButton: string
    storyImage: {
      url: string
      alt: string
    }
    catalogTitle: string
    catalogShortDescription: string
    gallery: [
      {
        image: {
          url: string
          alt: string
        }
      }
    ]
  };
};

const CustomRightArrow = ({onClick}) =>
  <button
    onClick={onClick}
    className={styles.slideArrow}>
    <img src={arrow} alt=""/>
  </button>

const CustomLeftArrow = ({onClick}) =>
  <button
    onClick={onClick}
    className={styles.slideArrow + ' ' + styles.left}>
    <img src={arrow} alt=""/>
  </button>

function StorePage({categories, contents}: Props) {
  const {description, title, storyUrl, storyDescription, storyTitle, storyButton, gallery, storyImage, catalogTitle, catalogShortDescription} = contents;

  return (
    <div className={styles.store}>
      <div className={styles.blockStore}>
        <CardStore
          inStorePage
          button={false}
          title={title}
          description={description}
          contentsInStorePage={contents}
        />
        <div className={styles.carouselContent}>
          <Carousel
            swipeable
            infinite={true}
            draggable={false}
            customLeftArrow={<CustomLeftArrow/>}
            customRightArrow={<CustomRightArrow/>}
            ssr
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 768,
                  min: 0
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 768
                },
                items: 1,
              }
            }}
          >
            {gallery.map(({image}) => <img src={image.url} alt={image.alt}/>)}
          </Carousel>
        </div>
      </div>
      <StoreMap contents={contents}/>
      <div className={styles.categories}>
        <span>{catalogTitle}</span>
        <div
          className={styles.topCategoriesText}>{catalogShortDescription}</div>
        <div className={styles.blockCategories}>
          <img src={parfum} alt={'catégorie'}/>
          <div className={styles.topCategories}>
            {categories[0].children.map((category: any, index: number) =>
              <CardTopCategory key={index} category={category}/>
            )}
          </div>
        </div>
      </div>
      <div className={styles.blockStory}>
        <div className={styles.story}>
          <span>{storyTitle}</span>
          <p>{storyDescription}</p>
          <Button
            type={ButtonTypes.Tertiary}
            as={'link'}
            href={storyUrl}
            text={storyButton}
          />
        </div>
        <img src={storyImage.url} alt={storyImage.alt}/>
      </div>
    </div>
  );
}

export default StorePage;
