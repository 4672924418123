import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';

import {FormField} from "../pages/account/SignIn/FormField";
import {SubscribeToNewsletterMutation} from "../mutation/NewsletterMutation";
import arrow from "../assets/arrow.svg";
import check from "../assets/check.svg";

const subscribeToNewsletterSchema = Yup.object().shape({
  email: Yup.string().email('').required(''),
});

export const NewsletterForm = () => {
  const [isCompleted, setCompleted] = useState(false);

  useEffect(() =>{
    setTimeout(() => {
      setCompleted(false);
    }, 3000);
  }, [isCompleted]);

  return (
    <SubscribeToNewsletterMutation onCompleted={() => setCompleted(true)}>
      {(subscribeToNewsletter, {loading, error}) => {
        return (
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={subscribeToNewsletterSchema}
            onSubmit={ async (values, {setSubmitting, setStatus, resetForm}) => {
              setSubmitting(true);
              try {
                const result = await subscribeToNewsletter({
                  variables: {
                    input: {
                      ...values,
                    }
                  }
                });
                resetForm();
                setStatus({success: true});
                setTimeout(() => {
                  setStatus(null);
                }, 3000);
              } catch(e) {
                setStatus({success: false});
              }
              setSubmitting(false);
            }}
          >{({isSubmitting, status, values}) => {
            return (
              <Form>
                <FormField
                  id="nl-footer-email"
                  name="email"
                  type="email"
                  placeholder={"Adresse e-mail"}
                />
                <button type="submit" disabled={isSubmitting} aria-label="S'inscrire à la newsletter">
                  {isSubmitting && <span>...</span>}
                  {!isSubmitting && (!status || !status.success) && <img src={arrow} alt=""/>}
                  {!!status && !!status.success && <img src={check} alt=""/>}
                </button>
              </Form>
            )
          }}
          </Formik>
        )
      }}
    </SubscribeToNewsletterMutation>
  );
};
