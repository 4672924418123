import React from 'react';
import styles from './CardBrand.css';
import {Link} from "react-router-dom";

type CardBrandProps = {
  brand?: any;
};

function CardBrand({brand}: CardBrandProps) {
  return (
    <ul className={styles.brandContainer}>
      {brand.map((item: any, index: number) =>
        <li className={styles.cardBrand} key={'card-brand' + index}>
          <Link to={item.urlPath} className={styles.cardLink}>
            <img src={item.urlImg} className={styles.cardImg} alt={item.title} loading="lazy"/>
          </Link>
        </li>
      )}
    </ul>
  );
}

export default CardBrand;
