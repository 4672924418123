import React, {useRef} from 'react';
import brandheader from '../../assets/brandheader.png'
import styles from './BrandsHeader.css';
import Carousel from "react-multi-carousel";
import arrow from "../../assets/arrow-slider.svg";
import Breadcrumbs from "../Breadcrumbs";

type Props = {
    item?: any;
    brandLogo?: any;
};

const images = [
  {
    brandHeader: brandheader,
    description: 'Puissant. Convoité. Inspiré par la mer.'
  }
]

const breadcrumbs = [
  {
    name: 'Nos marques',
    urlPath: '#'
  },
]

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    carouselState: { currentSlide, deviceType }
  } = rest;
  return <button onClick={() => onClick()} className={styles.slideArrow}><img src={arrow} alt={currentSlide}/></button>;
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  return <button onClick={() => onClick()} className={styles.slideArrow + ' ' + styles.left}><img src={arrow} alt={currentSlide}/></button>;
};

function BrandsHeader({item, brandLogo}: Props) {
  const carousel = useRef(null);

    return (
      <div style={{position: 'relative'}}>

      <div className={styles.sliderBrandsHeader}>
        <div className={styles.blockBrandsHeader}>
        <Carousel
          ref={carousel}
          containerClass={styles.carousel}
          dotListClass={styles.carouselDots}
          itemClass={styles.item}
          swipeable
          infinite={true}
          draggable={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          ssr
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 768,
                min: 0
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 768
              },
              items: 1,
            }
          }}
        >
          {images.map((image: any, index: number) =>
            <div style={{
                          verticalAlign: 'top',
                          height: '100%',
                          backgroundImage: "url(" + image.brandHeader + ")",
                          backgroundSize: 'cover',
                          width: '100%',
                          display: 'inline-flex',
                          backgroundPositionX: 'center',
                      }}>
                          <div className={styles.blockText}>
                              <div className={styles.subHeaderBrand}>
                                  <Breadcrumbs breadcrumbs={breadcrumbs} inHeader/>
                                  <div>Revendeur Officiel</div>
                              </div>
                              <div className={styles.headerBrandTop} style={{maxWidth: '1440px'}}>
                                  <div>
                                      <img src={brandLogo} alt={'la mer logo'}/>
                                  </div>
                                  <div style={{}} className={styles.headerBrandTop + ' ' + styles.description}>
                                      <span>
                                        {image.description}
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </div>
          )}
        </Carousel>
      </div>
      </div>
      </div>
    );
}

export default BrandsHeader;
