import React, { useRef, useState } from "react";
import styles from "./InputPassword.css";
import eye from "../../assets/eye.png";
import slashEye from "../../assets/notShow.png";
import { PasswordCheck } from "../index";

type InputPasswordProps = Omit<JSX.IntrinsicElements["input"], "value"> & {
  value?: string;
  passwordValidator?: boolean;
  validPassword?: any;
};

function InputPassword({
  value = "",
  onChange,
  passwordValidator = true,
  validPassword,
  ...allProps
}: InputPasswordProps) {
  const [val, setVal] = useState(value);
  const [show, setShow] = useState(false);
  const pass = useRef() as React.MutableRefObject<HTMLInputElement>;

  const showPassword = () => {
    setShow(!show);
    pass.current.type = show ? "password" : "text";
  };

  const setValidPassword = (valid: boolean) => {
    validPassword(valid);
  };

  return (
    <div>
      <div className={styles.inputPassword}>
        <input
          id={"pass"}
          ref={pass}
          value={val}
          type={"password"}
          spellCheck="false"
          {...allProps}
          className={styles.input}
          onChange={(e) => {
            setVal(() => e.target?.value);
            onChange && onChange(e);
          }}
        />
        {show ? (
          <img onClick={showPassword} src={eye} />
        ) : (
          <img onClick={showPassword} src={slashEye} />
        )}
      </div>
      {passwordValidator && (
        <PasswordCheck
          rules={["length", "number", "capital"]}
          minLength={8}
          value={val}
          onChange={(isValid) => setValidPassword(isValid)}
          validColor={"#4BCA81"}
        />
      )}
    </div>
  );
}

export default InputPassword;
