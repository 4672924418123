import React, {useEffect, useRef, useState} from 'react';
import './style.css'
import styles from './HorizontalScrollList.css';
import arrow from "../../assets/arrow.svg";
import ReactDOM from "react-dom";

type HorizontalScrollListProps = {
    children?: any;
    semiScroll?: boolean;
};

function HorizontalScrollList({
  children,
  semiScroll,
}: HorizontalScrollListProps) {

    const [leftArrowVisible, setLeftArrowVisible] = useState(false);
    const [rightArrowVisible, setRightArrowVisible] = useState(false);

    const carouselRef = useRef(null)
    const wrapperRef = useRef(null)
    let interval: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (carouselRef && carouselRef.current != null) {
            const carousel = carouselRef.current;
            const wrapper = wrapperRef.current;

            // @ts-ignore
            carousel.addEventListener('scroll', handleScroll);

            // @ts-ignore
            interval = setInterval(() => {
                // @ts-ignore
                const width = carousel.offsetWidth - 100;
                // @ts-ignore
                const wrapperWidth = wrapper.offsetWidth - 100;
                // @ts-ignore
                let scrollLeft = carousel.scrollLeft;

                setRightArrowVisible(scrollLeft < (wrapperWidth - width))

            }, 10);
            return () => {
                // @ts-ignore
                const carousel = ReactDOM.findDOMNode(carouselRef.current) // .ReactDOM.getDOMNode();
                // @ts-ignore
                carousel.removeEventListener('scroll', handleScroll);
                if (interval) {
                    clearInterval(interval);
                }
            }
        }

        return;
    }, []);

    const handleCarouselScroll = (direction: any) => {
        // @ts-ignore
        const carousel = carouselRef.current;
        // @ts-ignore
        const wrapper = wrapperRef.current;
        // @ts-ignore
        const width = carousel.offsetWidth - 100;
        // @ts-ignore
        const wrapperWidth = wrapper.offsetWidth - 100;
        // @ts-ignore
        const cardsCount = wrapper.children.length;
        // @ts-ignore
        const cardWidth = wrapper.children[0].offsetWidth;

        const rightMargin = (wrapperWidth - (cardWidth * cardsCount)) / (cardsCount - 1);

        const visiblesCount = Math.floor(width / (cardWidth + rightMargin));
        const visibleWidth = visiblesCount * (cardWidth + rightMargin);
        // @ts-ignore
        let scrollLeft = carousel.scrollLeft;

        if (direction === 'next') {
            const currentIndex = Math.floor(scrollLeft / visibleWidth);
            scrollLeft = (currentIndex + 1) * visibleWidth
        } else {
            const currentIndex = Math.ceil(scrollLeft / visibleWidth);
            scrollLeft = currentIndex - 1 === 1 ? 0 : (currentIndex - 1) * visibleWidth
        }

        // @ts-ignore
        carousel.scrollTo({top: 0, left: scrollLeft, behavior: 'smooth' });
    };

    const handleScroll = () => {
        // @ts-ignore
        const carousel = carouselRef.current;
        // @ts-ignore
        const wrapper = wrapperRef.current;
        // @ts-ignore
        const width = carousel.offsetWidth - 100;
        // @ts-ignore
        const wrapperWidth = wrapper.offsetWidth - 100;
        // @ts-ignore
        let scrollLeft = carousel.scrollLeft;
        setLeftArrowVisible(scrollLeft > 0)
        setRightArrowVisible(scrollLeft < (wrapperWidth - width))
    };

    return (
        <div className={semiScroll ? styles.semiPlanningsList : styles.planningsList}>
            <div
              className={styles.scrollArrow + ' ' + styles.left + ' ' + (leftArrowVisible ? styles.visible:'')}
              style={{backgroundImage: "url(" + arrow + ")"}}
              onClick={handleCarouselScroll.bind(null, 'prev')}
            />
            <div className={semiScroll ? styles.semiScrollList : styles.scrollList} ref={carouselRef}>
                <div className={styles.scrollListWrapper} ref={wrapperRef}>
                    {children}
                </div>
            </div>
            <div className={styles.scrollArrow + ' ' + (semiScroll ? styles.rightSemiScroll : styles.right) + ' ' + (rightArrowVisible ? styles.visible:'')}  style={{backgroundImage: "url(" + arrow + ")"}} onClick={handleCarouselScroll.bind(null, 'next')}/>
        </div>
    );
}

export default HorizontalScrollList;
