import React from 'react';
import {CardBrand, Headband} from "../index";

type iProps = {
    showButton: any;
    brand?: any;
}

function CategoryBrand({showButton, brand}: iProps) {
    return (
        <div>
            <Headband
                showButton={showButton}
                complement={'Les marques trending'}
                textButton={'Voir toutes les marques'}
                urlPath={'../brand'}
                description='Pour connaître les nouveautés de vos marques favorites avant tout le monde,
                suivez les marques que vous préférez.
                Débloquez ainsi une expérience personnalisée sur le site BENLUX.'/>
            <CardBrand brand={brand} />
        </div>
    );
}

export default CategoryBrand;
