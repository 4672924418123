import React from 'react';
import {Headband} from "../../components";
import styles from './HeadbandCards.css';


type HeadbandCardsProps = {
    items: any;
    description?: any;
    complement?: any;
    urlPath?: any;
    showButton?: any;
    title?: any;
    textButton?: any;
};

function HeadbandCards({items, description, complement, urlPath, showButton, title, textButton}: HeadbandCardsProps) {

    return (
            <div>
                <Headband
                    showButton={showButton}
                    title={title}
                    urlPath={urlPath}
                    textButton={textButton}
                    description={description}
                />
                <div className={styles.selectionsCards}>
                    {items.map((card: any) =>
                        <>
                        {card.children.map((child: any, index: number) =>
                            <div className={styles.selectionsCardContent}
                                 style={{backgroundImage: child.image, backgroundSize: 'cover', height: 261}}
                                 key={'headband-cards' + index}
                            >
                                <div className={styles.title}>
                                    <h1>{child.name}</h1>
                                    <p>{child.description}</p>
                                </div>
                            </div>
                        )}
                        </>
                    )}
                </div>
            </div>
    );
}

export default HeadbandCards;
