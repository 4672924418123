import React, { useState } from "react";
import cx from "classnames";
import * as styles from "./index.css";
import { Button, ButtonTypes } from "../Button";

function SalePopIn() {
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  const onClickLink = () => {
    onClose();
  };

  return (
    <>
      <div className={cx(styles.modal, { visible: visible })} role="alert">
        <div className={styles.cross} onClick={onClose} role="button" />
        <div className={styles.background} />
        <Button
          text="J'en profite !"
          as="link"
          type={ButtonTypes.Primary}
          href={"/soldes"}
          onClick={onClickLink}
          style={{ backgroundColor: "#FFF", color: "#000" }}
        />
      </div>
      <div
        className={cx(styles.modalBackDrop, { visible: visible })}
        onClick={onClose}
      />
    </>
  );
}

export default SalePopIn;
