import React, { useState } from "react";
import styles from "./AccountMenu.css";
import { Link, NavLink } from "react-router-dom";
import profile from "../../assets/profile.svg";
import vector from "../../assets/Vector.svg";
import cartImg from "../../assets/cart.svg";
import VIP from "../../assets/VIPAccount.svg";
import BrandAccount from "../../assets/BrandAccount.svg";
import CreditCard from "../../assets/CreditCard.svg";
import Logout from "../../assets/Logout.svg";
import Padlock from "../../assets/Padlock.svg";
import PersonalInfo from "../../assets/PersonalInfo.svg";
import Preference from "../../assets/Preference.svg";
import Sponsor from "../../assets/Sponsorship.svg";
import header from "../../assets/headerAccount.png";
import arrowMenu from "../../assets/arrowWhite.png";

type AccountMenuProps = {
  children?: any;
  signOut?: any;
  customer?: any;
};

function AccountMenu({ customer, children, signOut }: AccountMenuProps) {
  const [child, setChild] = useState(false);

  return (
    <>
      <div className={styles.displayMobile}>
        <img
          src={header}
          style={{ width: "100%" }}
          className={styles.imgHeader}
        />
        <div className={styles.headerMobile + (child ? " active" : "")}>
          <img src={arrowMenu} onClick={() => setChild(false)} />
          <div>Bonjour {customer.firstname} !</div>
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.rowButton}>
          <NavLink
            className={styles.buttonMenu}
            to={"/compte"}
            exact
            onClick={() => setChild(true)}
            activeClassName={styles.selected}
          >
            <img src={profile} alt={"compte"} />
            <span>Aperçu du compte</span>
          </NavLink>
          <NavLink
            to={"/compte/commandes"}
            className={styles.buttonMenu}
            exact
            onClick={() => setChild(true)}
            activeClassName={styles.selected}
          >
            <img src={cartImg} alt={"Mes commandes"} />
            <span>Mes commandes</span>
          </NavLink>
          {/* <NavLink to={"/compte/club-benlux"} exact className={styles.buttonMenu + ' ' + styles.lastElement} onClick={() => setChild(true)} activeClassName={styles.selected}>
                    <img src={VIP} alt={'compte club benlux'}/>
                    <span>Club Benlux</span>
                </NavLink> */}
          <NavLink
            to={"/compte/informations-personnelles"}
            exact
            className={styles.buttonMenu}
            onClick={() => setChild(true)}
            activeClassName={styles.selected}
          >
            <img src={PersonalInfo} alt={"informations personnelles"} />
            <span>Mes informations</span>
          </NavLink>
          <NavLink
            to={"/compte/nouveau-mot-de-passe"}
            exact
            className={styles.buttonMenu + " " + styles.lastElement}
            onClick={() => setChild(true)}
            activeClassName={styles.selected}
          >
            <img src={Padlock} alt={"nouveau mot de passe"} />
            <span>Changer le mot de passe</span>
          </NavLink>
          {/*<NavLink to={"/compte/methode-de-paiement"}  className={styles.buttonMenu + ' ' + styles.lastElement} activeClassName={styles.selected}>*/}
          {/*    <img src={CreditCard} alt={'méthode de paiement'}/>*/}
          {/*    <span>Mes moyens de paiement</span>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/compte/carnet-adresse"} exact className={styles.buttonMenu + ' ' + styles.lastElement} activeClassName={styles.selected}>*/}
          {/*    <img src={Preference} alt={'préférences'}/>*/}
          {/*    <span>Mes préférences</span>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/compte/carnet-adresse/ajouter"} exact className={styles.buttonMenu} activeClassName={styles.selected}>*/}
          {/*    <img src={vector} alt={'wishlist'}/>*/}
          {/*    <span>Wishlist</span>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/compte/carnet-adresse/ajouter"} exact className={styles.buttonMenu} activeClassName={styles.selected}>*/}
          {/*    <img src={BrandAccount} alt={'marques'}/>*/}
          {/*    <span>Marques</span>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/compte/carnet-adresse/ajouter"}  className={styles.buttonMenu} activeClassName={styles.selected}>*/}
          {/*    <img src={Sponsor} alt={'parrainage'}/>*/}
          {/*    <span>Parrainage</span>*/}
          {/*</NavLink>*/}
          <span
            onClick={signOut}
            className={styles.buttonMenu + " " + styles.lastElement}
          >
            <img src={Logout} alt={"se déconnecter déconnexion"} />
            <span>Déconnexion</span>
          </span>
        </div>
        <div className={styles.children + (child ? " active" : " disable")}>
          {children}
        </div>
      </div>
    </>
  );
}

export default AccountMenu;
