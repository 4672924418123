import { RECORD_KILIBA_VIEWS } from "../mutation/RecordKilibaViews";

const LS_VIEWS_KEY = "kvw";
const LS_CUSTOMER_KEY = "kcc";

function getViewsToSend() {
  const views = localStorage.getItem(LS_VIEWS_KEY);
  if (views) {
    return JSON.parse(views);
  }
  return [];
}

function saveKilibaCustomer(customerKey, customerId) {
  const customer = {
    key: customerKey,
    id: customerId !== null ? customerId.toString() : null
  };
  localStorage.setItem(LS_CUSTOMER_KEY, JSON.stringify(customer));

  const views = getViewsToSend();
  if (views.length > 0) {
    sendViewsToKiliba(views, customer);
    localStorage.removeItem(LS_VIEWS_KEY);
  }
}

function trackView(elementType, elementId) {
  let data = {
    url: window.location.href.split("?")[0],
    date: new Date(),
    productId: elementType === "product" ? elementId : "0",
    categoryId: elementType === "category" ? elementId : "0"
  };

  const customer = localStorage.getItem(LS_CUSTOMER_KEY);
  if (!customer) {
    // Save view to local storage
    let views = localStorage.getItem(LS_VIEWS_KEY);
    if (views) {
      views = JSON.parse(views);
    } else {
      views = [];
    }
    views.push(data);
    localStorage.setItem(LS_VIEWS_KEY, JSON.stringify(views));
  } else {
    // Send view to kiliba
    sendViewsToKiliba([data], JSON.parse(customer));
  }
}

function sendViewsToKiliba(views, customer) {
  const data = {
    customerKey: customer.key,
    customerId: customer.id,
    views: views
  };

  const apolloClient = window.__APOLLO_CLIENT__;
  apolloClient.mutate({
    mutation: RECORD_KILIBA_VIEWS,
    variables: { input: data }
  });
}

export { saveKilibaCustomer, trackView };
