import React from 'react';
import PropTypes from 'prop-types';
import { CloseSidebarMutation, EnsureTTI } from '@deity/falcon-ecommerce-uikit';
import { SidebarQuery } from './SidebarQuery';

export const SidebarContainer = ({ children }) => (
  <EnsureTTI>
    {({ isReady, forceReady }) => (
      <SidebarQuery onCompleted={({ sidebar }) => sidebar.isOpen && forceReady()}>
        {({ sidebar }) => {
          return (
              <CloseSidebarMutation>
                {closeSidebar =>
                    isReady
                        ? children({
                          side: sidebar.side,
                          isOpen: sidebar.isOpen,
                          contextKey: sidebar.context.key,
                          contextItemId: sidebar.context.data.itemId,
                          contentType: sidebar.contentType,
                          error: sidebar.error,
                          close: closeSidebar
                        })
                        : null
                }
              </CloseSidebarMutation>
          );
        }}
      </SidebarQuery>
    )}
  </EnsureTTI>
);

SidebarContainer.propTypes = {
  children: PropTypes.func.isRequired
};
