import React from 'react';
import styles from './GridForm.css';

type GridFormProps = {
  children?: any;
};

function GridForm({
                     children
                   }: GridFormProps) {



  return (
    <div className={styles.grid}>
      {children}
    </div>
  );
}

export default GridForm;
