import React from 'react';
import {Link} from 'react-router-dom';

import styles from './SubCategoriesNavigation.css';

type SubCategoriesNavigationProps = {
  categories: [{
    name: string
    urlPath: string
    isSmart: boolean
  }];
};

const excludedNames = ['nouveautés', 'meilleures ventes', 'default category'];

const SubCategoriesNavigation = ({categories}: SubCategoriesNavigationProps) =>
  <ul className={styles.wrapper}>
    {categories
      .filter(c => excludedNames.indexOf(c.name.toLowerCase()) === -1 && !c.isSmart)
      .map((category, index) =>
      <li key={index}>
        <Link to={category.urlPath}>
          {category.name}
        </Link>
      </li>
    )}
  </ul>

export default SubCategoriesNavigation;
