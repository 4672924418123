import React from 'react';
import {Link} from 'react-router-dom';

import * as styles from './ContentCards.css';
import placeholder from './placeholder.jpg';

type ContentCardsProps = {
  items: [
    {
      item: {
        title: string
        name?: string
        urlPath: string
        image: {
          url: string
          alt: string
        },
        className?: string
      }
    }
  ];
  asCarousel?: boolean;
};

const ContentCards = ({items, asCarousel = false}: ContentCardsProps) =>
  <div className={styles.contentCardsList + ' ' + (asCarousel ? styles.asCarousel : '')}>
    {items.map((item: any, levelIndex: number) =>
      <Link
        key={levelIndex}
        to={item.urlPath}
        className={item.className || ''}
      >
        <img
          src={item.image.url ? item.image.url : placeholder}
          alt={item.image.alt}
          loading="lazy"
        />
        <span>{item.name ? item.name : item.title}</span>
      </Link>
    )}
  </div>

export default ContentCards;
