import React from 'react';
import styles from './SearchLinkCard.css';
import {Link} from "react-router-dom";
import {getPath} from "../../utils/getPath";


type SearchLinkCardProps = {
  hits?: any;
};

function SearchLinkCard({
  hits
}: SearchLinkCardProps) {
  const pathCategoryFilter = hits?.filter((hit: any) => hit.path?.substring(0, 10) === 'Catégories');
  const pathBrandFilter = hits?.filter((hit: any) => hit.path?.substring(0, 7) === 'Marques' && hit.level === 3);
  const pagesFilter = hits?.filter((hit: any) => !hit.path);
  return (
    <div className={styles.wrapper}>
      <div className={styles.child}>
          <div className={styles.gridRowChild}>
          {pathCategoryFilter?.map((hit: any, index: number) =>
              <Link
                to={getPath(hit.url)}
                className={styles.childCard}
                key={index}
              >
                {hit.path.replace('Catégories /', '')}
              </Link>
            )}
            {pathBrandFilter?.map((hit: any, index: number) =>
              <Link
                to={getPath(hit.url)}
                className={styles.childCard}
                key={index}
              >
                {hit.path}
              </Link>
            )}
            {pagesFilter?.map((hit: any, index: number) =>
              <Link
                to={`/inspirations${getPath(hit.url)}`}
                className={styles.childCard}
                key={index}
              >
                {hit.name}
              </Link>
            )}
          </div>
      </div>
    </div>
  );
}

export default SearchLinkCard;
