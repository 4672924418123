import React from 'react';
import {Link} from "react-router-dom";

import styles from './BoxCheckout.css';

import logo from "../../assets/BENLUX.svg";
import americanExpress from "../../assets/American Express.svg";
import mastercard from "../../assets/Mastercard.svg";
import visa from "../../assets/Visa.svg";
import paypal from "../../assets/PayPal.svg";

type BoxCheckoutProps = {
  children?: any;
};

function BoxCheckout({
  children
}: BoxCheckoutProps) {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Link to={'../'}>
          <img src={logo} alt={'Retour à l\'accueil'}/>
        </Link>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div className={styles.paymentMethods}>
            <img src={visa} alt=""/>
            <img src={mastercard} alt=""/>
            <img src={americanExpress} alt=""/>
            <img src={paypal} alt=""/>
          </div>
          <span style={{fontSize: '14px', color: '#444'}}>Paiement sécurisé</span>
        </div>
      </div>
      <div className={styles.child}>
        {children}
      </div>
    </div>
  );
}

export default BoxCheckout;