import React from 'react';
import { withApollo } from 'react-apollo';
import { ALGOLIA_SEARCH_CONFIG } from '../queries/algoliaSearchConfig.gql';

let algoliaSearch = null;

export default WrappedComponent => {
  const WithAlgoliaSearchConfig = props => {

    if (!algoliaSearch) {
      const { client } = props;
      const { config } = client.readQuery({ query: ALGOLIA_SEARCH_CONFIG }) || {};
      algoliaSearch = config.algoliaSearch;
    }

    return <WrappedComponent {...props} algoliaSearchConfig={algoliaSearch} />;
  };

  return withApollo(WithAlgoliaSearchConfig);
};
