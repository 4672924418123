import React from 'react';

import notFound from '../../assets/404.jpg';
import {CardTopCategory, PageHeader} from "../index";

import styles from './NotFound.css';

type NotFoundProps = {
  categories?: any;
};

export default function NotFound({
  categories,
}: NotFoundProps) {

  const item = {
    image: notFound,
    title: 'Oups...',
    description: 'Il semblerait que le contenu que vous souhaitiez voir se soit perdu parmi les crèmes et les mascaras !'
  }

  return (
    <div className={styles.wrapperFound}>
      <PageHeader image={item.image} notCategory title={item.title} description={item.description}/>
      <div className={styles.secondBlock}>
        <div className={styles.secondTitle}>Laissez-vous guider...</div>
        <div className={styles.topCategories}>
          {categories[0].children.map((category: any, index: number) =>
            <CardTopCategory key={index} category={category}/>
          )}
        </div>
      </div>
    </div>
  );
}
