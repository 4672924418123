import React, {useState} from 'react';
import * as styles from './ForgotPasswordPopin.css';
import cross from "../../assets/cross.png";

type ForgotPasswordPopinProps = {
  form: any;
};

function ForgotPasswordPopin({form}: ForgotPasswordPopinProps) {
  const [showPopin, setPopin] = useState(false)

  return (
    <>
      <div onClick={() => setPopin(true)} className={styles.title}>
        Mot de passe oublié ?
      </div>
      <div className={styles.modal + ' ' + (showPopin && styles.show)}>
        <div className={styles.cross}>
          <img onClick={() => setPopin(false)} src={cross} height={14}
               width={14}/>
        </div>
        <div className={styles.modalTitle}>
          Vous avez oublié votre mot de passe ?
        </div>
        <p>Entrez l'adresse e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.</p>
        <div className={styles.form}>
          {form}
        </div>
      </div>
      <div className={styles.modalBackDrop + ' ' + (showPopin && styles.show)}/>
    </>
  );
}

export default ForgotPasswordPopin;
