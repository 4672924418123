import React from "react";
import { CustomerQuery } from "../../queries/CustomerQuery";
import { useEffect } from "react";
import { saveKilibaCustomer } from "../../utils/kiliba";

function KilibaCustomerTracker() {
  return (
    <CustomerQuery>
      {props => <KilibaCustomerTrackerLogic {...props} />}
    </CustomerQuery>
  );
}

function KilibaCustomerTrackerLogic(props) {
  const { customer } = props;
  useEffect(() => {
    if (typeof window !== "undefined" && customer) {
      saveKilibaCustomer(customer.kilibaCustomerKey, customer.id);
    }
  }, [customer]);

  return null;
}

export { KilibaCustomerTracker };
