import React from 'react';
import {CarouselCard, Headband} from "../index";
import styles from './HeadbandCarousel.css';

type HeadbandCarouselProps = {
  description?: any;
  title?: any;
  complement?: any;
  textButton?: any;
  urlPath?: any;
  itemCarousel?: any;
  showButton: boolean;
  imgLazyLoading?: boolean;
};

function HeadbandCarousel ({
  title,
  titleTag,
  urlPath,
  textButton,
  description,
  itemCarousel,
  showButton,
  imgLazyLoading = false
  }: HeadbandCarouselProps) {
    return (
      <div className={styles.headbandCarousel}>
        <Headband
          showButton={showButton}
          title={title}
          titleTag={titleTag}
          urlPath={urlPath}
          textButton={textButton}
          description={description}
        />
        <CarouselCard items={itemCarousel} imgLazyLoading={imgLazyLoading} />
      </div>
    );
}

export default HeadbandCarousel;
