import React from "react";
import moment from "moment";
moment.locale("fr");

import { Button, ButtonTypes } from "../Button";
import formatPrice from "../../utils/formatPrice";

import styles from "./Shipment.css";

type ShipmentProps = {
  order?: any;
  isLayout?: boolean;
};

enum ShippingMethod {
  MondialRelay = "Mondial Relay",
  Colissimo = "Colissimo",
  Benlux = "Benlux",
}

const getShipmentTrackingLink = ({
  trackNumber,
  shippingMethod,
}: {
  trackNumber: string;
  shippingMethod: string;
}) => {
  if (shippingMethod === ShippingMethod.MondialRelay) {
    return `https://www.mondialrelay.fr/suivi-de-colis?codeMarque=CC&numeroExpedition=${trackNumber}&language=FR`;
  }

  if (shippingMethod === ShippingMethod.Colissimo) {
    return `https://www.laposte.fr/outils/suivre-vos-envois?code=${trackNumber}`;
  }

  return "";
};

const getShippingMethod = (shippingDescription: string) => {
  return shippingDescription.split("-")[0].trim();
};

const getShippingMethodLabel = (shippingMethod: string) => {
  switch (shippingMethod) {
    case ShippingMethod.MondialRelay:
      return "Mondial Relay";
    case ShippingMethod.Colissimo:
      return "Colissimo";
    case ShippingMethod.Benlux:
      return "Retrait en magasin";
    default:
      return "";
  }
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case "processing":
      return "En cours de préparation";
    case "lpc_readyToShip":
      return "En cours de préparation";
    case "complete":
      return "Expédiée";
    case "payment_review":
      return "En cours de validation";
    case "lpc_transit":
      return "En cours de livraison";
    case "lpc_delivered":
      return "Livrée";
    case "lpc_anomaly":
      return "En cours de livraison";
    default:
      return "";
  }
};

const getInvoiceDownloadUrl = (invoicePath: string) => {
  return `https://magento.prod.benlux.sntive.net/pub/media/invoices/${invoicePath}`;
};

const InvoiceDownloadLink = ({
  invoicePath,
  label = "Télécharger la facture",
}: {
  invoicePath: string;
  label?: string;
}) => {
  return (
    <a
      className={styles.invoiceLink}
      href={getInvoiceDownloadUrl(invoicePath)}
      target="__blank"
    >
      {label}
    </a>
  );
};

function Shipment({ order, isLayout }: ShipmentProps) {
  const shippingMethod = getShippingMethod(order.shippingDescription);

  return (
    <div className={styles.wrapper}>
      <h1>
        Commande n°{order.incrementId} du{" "}
        {moment(order.createdAt).format("D MMMM YYYY")}
      </h1>
      <div className={styles.block}>
        <h2>{getStatusLabel(order.status)}</h2>
        {order.invoices && order.invoices.length > 0 && (
          <div className={styles.dateAndNumber}>
            <span>
              <InvoiceDownloadLink invoicePath={order.invoices[0].url} />
            </span>
          </div>
        )}
        {order.shipments && order.shipments.length > 0 && (
          <div className={styles.shipments}>
            {order.shipments.map((shipment: any, index: number) => (
              <div className={styles.shipmentLayout} key={index}>
                <div className={styles.shipmentTitle}>
                  Colis {index + 1}/{order.shipments.length}
                </div>
                <div className={styles.dateAndNumber}>
                  <span>Numéro de suivi : {shipment.trackNumber}</span>
                  <span>
                    Mode de livraison : {getShippingMethodLabel(shippingMethod)}
                  </span>
                </div>
                <div className={styles.shipmentItems}>
                  {shipment.items
                    .slice(0, 3)
                    .map((shipmentItem: { itemId: string; qty: number }) => {
                      const shipmentOrderItem = order.items.find(
                        (item: any) => item.itemId === shipmentItem.itemId
                      );

                      return (
                        <div className={styles.shipmentItem}>
                          <img
                            src={shipmentOrderItem.thumbnailUrl}
                            height={100}
                          />
                          <span>{shipmentItem.qty}</span>
                        </div>
                      );
                    })}
                </div>
                <Button
                  type={ButtonTypes.Secondary}
                  as={"externalLink"}
                  href={getShipmentTrackingLink({
                    trackNumber: shipment.trackNumber,
                    shippingMethod,
                  })}
                  text={"Suivre le colis"}
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.headerProducts}>Articles</div>
        <div className={styles.blockProducts}>
          {order.items.map((product: any) => (
            <div className={styles.cardProduct}>
              {product.thumbnailUrl && <img src={product.thumbnailUrl} />}
              <div className={styles.blockTextProduct}>
                <div>{product.manufacturer}</div>
                <div>{product.name} </div>
                {product.qty && <div>Quantité : {product.qty}</div>}
                <div>
                  {product.rowTotalInclTax > 0
                    ? formatPrice(product.rowTotalInclTax) + " €"
                    : "Offert"}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.divider} />
        <div className={styles.secondHeader}>
          <div>Informations sur la commande</div>
        </div>
        <div className={styles.orderData}>
          {order.payment && (
            <div className={styles.orderBlockText}>
              <span className={styles.firstSpan}>Moyen de paiement</span>
              <span className={styles.paymentMethod}>
                {order.payment.method === "paypal_express" ? (
                  <>PayPal</>
                ) : (
                  <>Carte bancaire se terminant par : {order.payment.ccLast4}</>
                )}
              </span>
            </div>
          )}
          <div className={styles.orderBlockText}>
            <span className={styles.firstSpan}>Mode de livraison</span>
            <span className={styles.paymentMethod}>
              {getShippingMethodLabel(shippingMethod)}
            </span>
          </div>
          {order.shippingAddress && (
            <div className={styles.orderBlockTextDelivery}>
              <div className={styles.firstSpan}>Adresse de livraison</div>
              <div>
                <div className={styles.address}>
                  {order.shippingAddress.firstname}{" "}
                  {order.shippingAddress.lastname}
                </div>
                {order.billingAddress.company && (
                  <span
                    className={styles.address}
                  >{`${order.shippingAddress.company}, `}</span>
                )}
                <div className={styles.address}>
                  {order.shippingAddress.street[0]}
                </div>
                {order.shippingAddress.street.length > 1 && (
                  <div className={styles.address}>
                    {order.shippingAddress.street[1]}
                  </div>
                )}
                <div
                  className={styles.address}
                >{`${order.shippingAddress.postcode} ${order.shippingAddress.city}`}</div>
              </div>
            </div>
          )}
          <div className={styles.orderBlockTextDelivery}>
            <div className={styles.firstSpan}>Adresse de facturation</div>
            <div>
              <div className={styles.address}>
                {order.billingAddress.firstname} {order.billingAddress.lastname}
              </div>
              {order.billingAddress.company && (
                <span
                  className={styles.address}
                >{`${order.billingAddress.company}, `}</span>
              )}
              <div className={styles.address}>
                {order.billingAddress.street[0]}
              </div>
              {order.billingAddress.street.length > 1 && (
                <div className={styles.address}>
                  {order.billingAddress.street[1]}
                </div>
              )}
              <div
                className={styles.address}
              >{`${order.billingAddress.postcode} ${order.billingAddress.city}`}</div>
            </div>
          </div>
          <div className={styles.totals}>
            <div>Récapitulatif de commande</div>
            <div>Articles : {formatPrice(order.subtotalInclTax)} €</div>
            <div>
              Livraison :{" "}
              {order.shippingInclTax > 0
                ? formatPrice(order.shippingInclTax) + " €"
                : "Offert"}
            </div>
            <div>Total : {formatPrice(order.grandTotal)} €</div>
          </div>
        </div>
        <div className={styles.blockButton}>
          <Button
            text={"Un problème avec la commande ?"}
            type={ButtonTypes.Tertiary}
            as={"link"}
            href={"/aide"}
          />
          <Button
            text={"Information sur les retours"}
            type={ButtonTypes.Tertiary}
            as={"link"}
            href={"/aide"}
          />
        </div>
      </div>
    </div>
  );
}

export default Shipment;
