import React from 'react';
import styles from './OrderCard.css';
import {Button, ButtonTypes} from "../index";
import {formatPrice} from '../../utils';
const moment = require('moment');

moment.locale("fr");


type OrderCardProps = {
    item?: any;
    lastOrder?: any;
};

function OrderCard({item, lastOrder}: OrderCardProps) {
  const findContents = (name: string) => {
    switch (name) {
      case 'processing':
        return 'En cours de préparation';
      case 'complete':
        return 'Expédiée';
      case 'payment_review':
        return 'En cours de validation';
      default:
        return '[]'
    }
  }
    return (
        <>
        {item ? (
            <div className={styles.blockLastOrder}>
                <div className={styles.reOrder}>
                    <span>{findContents(item.status)}</span>
                    {/*<div>Commander de nouveau</div>*/}
                </div>
                <div className={styles.orderData}>
                    <div className={styles.orderBlockText}>
                        <span className={styles.firstSpan}>N° de commande</span>
                        <span>#{item.incrementId}</span>
                    </div>
                    <div className={styles.orderBlockText}>
                        <span className={styles.firstSpan}>Effectuée le</span>
                        <span>{moment(item.createdAt).format('D MMMM YYYY')}</span>
                    </div>
                    <div className={styles.orderBlockText}>
                        <span className={styles.firstSpan}>Total</span>
                        <span className={styles.price}>{formatPrice(item.grandTotal)} €</span>
                    </div>
                  {/*<div className={styles.orderItem}>*/}
                  {/*  {item.items}*/}
                  {/*  <img src={item.thumbnailUrl}/>*/}
                  {/*  <p>{item.name}</p>*/}
                  {/*</div>*/}
                </div>
                <div className={styles.blockButton}>
                    {/*<Button*/}
                    {/*  type={ButtonTypes.Secondary}*/}
                    {/*  as={'link'}*/}
                    {/*  text={'Retourner un article'}*/}
                    {/*/>*/}
                    <Button
                      as={'link'}
                      type={ButtonTypes.Tertiary}
                      text={'Voir la commande'}
                      href={`/compte/commandes/${item.entityId}`}
                    />
                </div>
            </div>
        ) : (
            <div>Pas de dernière commande</div>
            )}
            </>
    );
}

export default OrderCard;
