import React, {useState} from 'react';
import styles from './CookieBanner.css';
import {Button, ButtonTypes} from "../index";


type CookieBannerProps = {
  content: any;
};

function CookieBanner({content}: CookieBannerProps) {
  const [bannerIsOpened, setBannerIsOpened] = useState(true);

  return (
    <div
      className={styles.wrapper + ' ' + (bannerIsOpened ? styles.bannerOpened : '')}>
      <div className={styles.banner}>
        <span className={styles.title}>{content.title}</span>
        <p>{content.description}</p>
        <div className={styles.blockButtons}>
          <Button
            type={ButtonTypes.Secondary}
            text={content.manageButtonLabel}
            as={'button'}
            onClick={() => setBannerIsOpened(false)}
          />
          <Button
            type={ButtonTypes.Primary}
            text={content.acceptButtonLabel}
            as={'button'}
            onClick={() => setBannerIsOpened(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default CookieBanner;
