import React, {useState} from 'react';
import styles from './HelpSidebar.css';
import {Link, NavLink} from "react-router-dom";

type HelpSidebarProps = {
  children?: any;
  sections: [
    {
    title: string,
    slug: string,
    contents: [{
      question: string,
      answer: string,
    }]
  }];
};

function HelpSidebar({
   sections,
  children,
}: HelpSidebarProps) {
  const [child, setChild] = useState(false);
  return (
      <div className={styles.wrapper}>
        <div className={styles.rowButton}>
          {sections.map((link: any) =>
            <NavLink
              className={styles.buttonMenu}
              to={{pathname: `/aide/${link.slug}`, state: {contents: link.contents, title: link.title}}}
              onClick={() => setChild(true)}
              activeClassName={styles.selected}
            >
              <span>{link.title}</span>
            </NavLink>
          )}
        </div>
        <div className={styles.children + (child ? ' active' : ' disable')}>
          {children}
        </div>
      </div>
  );
}

export default HelpSidebar;
