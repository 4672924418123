import React from 'react';
import * as styles from './Level4List.css';
import {Link} from "react-router-dom";

type Level4ListProps = {
  items: any;
  level3Selected?: any;
};

function Level4List({
  items,
  level3Selected,
}: Level4ListProps) {
  return (
    <ul
      className={styles.level4Wrapper + ((items.id === level3Selected) ? ' active' : '')}>
      {items.children?.map((level4Item: any, level4Index: number) => {
        return (
          <li
            className={styles.item}
            key={'level-4-item-' + level4Index}
          >
            <Link to={level4Item.urlPath}>
              {level4Item.name}
            </Link>
          </li>
        )
      })}
    </ul>
  );
}

export default Level4List;
