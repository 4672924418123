import React, {useState} from 'react';
import './style.css';
import Carousel from "react-multi-carousel";
import * as styles from "./ImageSlider.css";
import InnerImageZoom from 'react-inner-image-zoom';


type Props = {
    images: { url: string }[];
};

function ImageSlider({images}: Props) {
    const [slideIndex, setSlideIndex] = useState(0);

    if (slideIndex >= images.length) {
        setSlideIndex(0);
    }
    if (slideIndex < 0) {
        setSlideIndex(images.length);
    }

    return (
        <div className="image-slider" style={{overflow: 'hidden', width: '100%', height: '100%'}}>
          <Carousel
            containerClass={styles.carousel}
            dotListClass={styles.carouselDots}
            itemClass={styles.item}
            swipeable
            draggable={false}
            ssr
            showDots
            removeArrowOnDeviceType={["tablet", "desktop"]}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 768,
                  min: 0
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 768
                },
                items: 1,
              }
            }}
          >
              <div className={styles.fullImage}>
                <div style={{
                  width: (images.length * 100) + '%',
                  height: '100%',
                  position: 'relative',
                  left: - (slideIndex * 100) + '%',
                  transition: 'left .2s ease-in-out'
                }}>
                    <div style={{
                      width: '100%',
                      display: 'inline-block',
                      verticalAlign: 'top',
                      height: 415,
                      backgroundImage: "url(" + images + ")",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}>
                    </div>
                </div>
              </div>
          </Carousel>

              {/*)}*/}

        </div>
    );
}

export default ImageSlider;
