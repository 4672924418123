import React from 'react';
import {Link} from "react-router-dom";

import {Button, ButtonTypes} from "../index";
import arrowMenu from "../../assets/arrowMenu.png";

import styles from './Headband.css';

type HeadbandProps = {
  title: string;
  titleTag?: string;
  description?: string;
  textButton?: any;
  urlPath?: any;
  showButton: boolean;
  externalLink?: any;
};

export default function Headband({
  title,
  titleTag = 'h2',
  urlPath,
  textButton,
  showButton,
  externalLink,
  description
}: HeadbandProps) {

  return (
    <div className={styles.headband}>
      <div>
        <div className={styles.blockTitleLink}>
          {React.createElement(titleTag, {className: styles.title}, title)}
          {urlPath && (
            <>
              {urlPath.indexOf('http') > -1 ? (
                <a href={urlPath}>
                  <img src={arrowMenu} alt=""/>
                </a>
              ) : (
                <Link to={urlPath}>
                  <img src={arrowMenu} alt=""/>
                </Link>
              )}
            </>
          )}
        </div>
        <p>{description}</p>
      </div>
      {showButton &&
      <div className={styles.buttonLink}>
        <Button
          type={ButtonTypes.Tertiary}
          as={externalLink ? 'externalLink' : 'link'}
          text={textButton}
          href={urlPath}
        />
      </div>
      }
    </div>
  );
}
