import React from 'react';
import styles from './Breadcrumbs.css';
import {Link} from "react-router-dom";

type BreadcrumbsProps = {
  breadcrumbs: [
    {
      urlPath: string
      name: string
    }
  ];
  inHeader?: boolean;
  currentPageName?: string;
  className: string;
};

function Breadcrumbs({breadcrumbs, currentPageName, className = styles.breadcrumb}: BreadcrumbsProps) {
  return (
    <nav aria-label={'Breadcrumb'} className={className}>
      <ul>
        <li key={-2}>
          <Link to={'/'}>Benlux</Link>
        </li>
        <li key={-1}>/</li>
        {breadcrumbs
          .filter(b => b.urlPath !== '/categories')
          .map((path: any, index: number) =>
            <>
              <li key={index}>
                <Link
                  to={path.urlPath}
                >
                  {path.name}
                </Link>
              </li>
              <li key={`${index}-d`}>/</li>
            </>
          )}
        {currentPageName && (
          <li key={100} className={styles.current}>{currentPageName}</li>
        )}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
