import React from 'react';
import arrow from "../../assets/arrow.svg";
import styles from './Newsletter.css';
import newsletter from '../../assets/newsletter.png'

type NewsletterProps = {
  onClickEmail?: any;
  onChangeText?: any;
  inputNewsletter?: any;
};

const Newsletter = ({onClickEmail, onChangeText, inputNewsletter}: NewsletterProps) =>
  <div className={styles.newsletter}>
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>La Newsletter</span>
        <span>Ne ratez plus rien, inscrivez-vous.</span>
      </div>
      {inputNewsletter}
    </div>
  </div>

export default Newsletter;
