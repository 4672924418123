import React from 'react';
import styles from './BoxLayout.css';

type BoxLayoutProps = {
    children?: any;
};

function BoxLayout({
                        children
                    }: BoxLayoutProps) {



    return (
        <div className={styles.box}>
            {children}
        </div>
    );
}

export default BoxLayout;
