import React from "react";
import styles from "./Dashboard.css";
import { OrderCard } from "../index";

type DashboardProps = {
  customer?: any;
  lastOrder?: any;
};

function Dashboard({ customer, lastOrder }: DashboardProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <span>Bonjour {customer.firstname} !</span>
        {/* <span>Votre porte-monnaie fidélité est vide.</span> */}
      </div>
      <div className={styles.orderText}>Mes commandes en cours</div>
      <OrderCard lastOrder={lastOrder} />
    </div>
  );
}

export default Dashboard;
