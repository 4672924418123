import React, {useState} from 'react';
import {ContentCards, Level4List} from "../index";
import * as styles from './DropDownContent.css'
import {Link} from "react-router-dom";
import arrowMenu from "../../assets/arrowMenu.png";

type AllProductsDropDownProps = {
  items: any;
  opened: boolean;
  onClickCloseMenu?: any;
  contents: [{
    title: string,
    image: string,
    urlPath: string
  }];
  onMenuLeave?: any;
};

function DropDownContent({
  opened,
  items,
  onClickCloseMenu,
  contents,
  onMenuLeave,
}: AllProductsDropDownProps) {
  const [level3Selected, setLevel3Selected] = useState<number>();
  const onClickLevel3 = (id: number) => {
    setLevel3Selected(id)
  }
  return (
    <div
      className={styles.navigationTabContent + (opened ? ' active' : '')}
      onMouseLeave={onMenuLeave}
    >
      <div className={styles.menuMobile}>
        <div className={styles.subMenu}>
          <div
            className={styles.menuName + ' ' + (items.highlighted ? ' highlighted' : '')}>{items.name}</div>
          <img
            onClick={onClickCloseMenu}
            src={arrowMenu}
            alt={'menu précédent'}
          />
        </div>
        <div className={styles.separator}/>
      </div>
      <ul className={styles.level3Wrapper}>
        {items.children.map((level3Item: any, level3Index: number) => {
            return (
              <div
                className={styles.level3Item + ((level3Item.id === level3Selected) ? ' active' : '')}
                key={'level-3-item-' + level3Index}>
                <li>
                  <Link to={level3Item.urlPath}
                        onClick={() => onClickLevel3(level3Item.id)}>
                    {level3Item.name}
                  </Link>
                </li>
                <div className={styles.level4Wrapper}>
                  <Level4List items={level3Item} level3Selected={level3Item.id}/>
                </div>
              </div>
            )
          }
        )}
      </ul>
      <ContentCards items={contents} />
    </div>
  );
}

export default DropDownContent;
