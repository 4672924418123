import React from 'react';
import {Headband} from "../index";
import CarouselCard from "../CarouselCard";

type SubCategoriesNavigationProps = {
  child: any;
  loading?: any;
  childMenu?: any;
  loader?: any;
  categoryName?: string;
  imgLazyLoading?: boolean;
};

function SubCategoriesHeader({child, loader, loading, childMenu, categoryName, imgLazyLoading = false} : SubCategoriesNavigationProps) {
  return (
    <>
      <Headband
        title={childMenu.name}
        description={child ? child.description : ''}
        urlPath={childMenu.urlPath}
        showButton={true}
        textButton={'Voir tout'}
        loading={loading}
      />
      {loading ? (
        <div style={{position: 'relative', height: '384px', background: '#FAFAFA', margin: '0 calc((100% - 1200px)/2)' }}>...</div>
      ) : (
        <CarouselCard items={child.products.items} categoryName={categoryName} imgLazyLoading={imgLazyLoading}/>
      )}
    </>
  );
}

export default SubCategoriesHeader;
