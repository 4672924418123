import React from 'react';
import styles from './StoreMap.css';
import storeMap from '../../assets/StoreMap.jpg'
import metroLine from '../../assets/metroLine.svg'
import message from '../../assets/message.svg'
import telephone from '../../assets/telephone.svg'
import canceling from "../../assets/canceling.svg";
import delivery from "../../assets/delivery.svg";

type iProps = {
  contents?: any;
};

function StoreMap({contents}: iProps) {
  const {phone, metroLines, metroStop, mapImage, open, entrances, schedules, contact, email, address} = contents

  return (
    <div className={styles.wrapperMap}>
      <img src={mapImage.url} alt={mapImage.alt} className={styles.map}/>
      <div className={styles.storeInfo}>
        <span className={styles.infoTitle}>{contents.storeMapTitle}</span>
        <div className={styles.content}>
          <div className={styles.image}><img src={delivery} alt={'adresse'}/></div>
          <div>
            <div>{address.company}</div>
            <div>{address.address1}</div>
            <div>{address.postalCode} {address.city}</div>
          </div>
          <div className={styles.timetable}>
            <div><span>{open}</span> aujourd'hui jusqu'à 18h45</div>
            <div className={styles.grid}>
                {schedules.map((schedule: any) =>
                  <>
                    <div>{schedule.days}</div>
                    <div>{schedule.times}</div>
                  </>
                )}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.image}><img src={canceling} alt={'entrée'}/></div>
          <div>
            {entrances.map((entrance: any) =>
                <div>{entrance.title}</div>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.image}><img src={metroLine} alt={'ligne metro'}/></div>
          <div>
            <div>{metroLines}</div>
            <div>{metroStop}</div>
          </div>
        </div>
        <span className={styles.titleContact}>{contents.contactTitle}</span>
        <div className={styles.content}>
          <div className={styles.image}><img src={telephone} alt={'contact téléphone'}/></div>
          <div>
            <div>{phone}</div>
            <div>{contact}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.image}><img src={message} alt={'contact mail email'}/></div>
          <div>
            <div>{email}</div>
            <div>{contact}</div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default StoreMap;
