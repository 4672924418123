import React, {useEffect, useRef, useState} from 'react';
import {Link, withRouter} from "react-router-dom";

import {HeaderReinsurance, HeaderUserMenu} from "../index";
import HeaderSearch from "../HeaderSearch";
import NavBarMenu from '../NavBarMenu/NavBarMenu';

import logo from "../../assets/BENLUX.svg";
import menuMobileIcon from "../../assets/menu-icon.png";
import cross from "../../assets/cross.png";

import * as styles from './Header.css';

type HeaderProps = {
  onPressAccount?: any;
  onPressSearch?: any;
  onPressCart?: any;
  onPressVector?: any;
  onChangeText?: any;
  header?: any;
  searchbar?: any;
  items: [any];
  account?: any;
  loginPath?: any;
  signOut?: any;
  onPressOrder?: any;
  location?: any;
  cart?: any;
  contents: {
    reinsuranceContent?: {
      content: string
    }
  };
  searchProvider: any;
};

export default withRouter(function Header({
  history,
  items,
  account,
  loginPath,
  signOut,
  onPressOrder,
  location,
  cart,
  contents,
  searchProvider,
  promotionnalBanner
}: HeaderProps) {
  const [isOpened, setOpened] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const allProductsDropdownRef = useRef();
  const [openedMenuIndex, setOpenedMenuIndex] = useState<number>();

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const wrapperElement = wrapper.current;
      const containerElement = container.current;

      if (containerElement && wrapperElement) {
        const headerHeight = containerElement.offsetHeight;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollY >= headerHeight) {
          wrapperElement.style.position = 'fixed';
          containerElement.style.paddingTop = headerHeight + 'px';


          if (scrollY < lastScrollTop) {
            wrapperElement.style.transition = '.3s ease-in-out';
            wrapperElement.style.top = '0px';
          } else {
            wrapperElement.style.top = - headerHeight + 'px';
          }
        } else if (scrollY === 0) {
          wrapperElement.style.transition = 'none';
          wrapperElement.style.position = 'initial';
          containerElement.style.paddingTop = '0';
        }
      }

      lastScrollTop = scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.overflow = (isOpened ? 'hidden' : 'visible');
  }, [isOpened])

  useEffect(() => {
    const { pathname } = location;
    if (pathname.length > 1) {
      setOpened(false);
      allProductsDropdownRef.current?.resetSelectedIndex(undefined);
      setOpenedMenuIndex(undefined);

    }
  }, [location.pathname]);

  let promotionnalBannerData = null;
  try {
    promotionnalBannerData = JSON.parse(promotionnalBanner.content);
  } catch (e) {
    console.error(e);
  }

  // Switch first element with second element in items
  const itemsCopy = [...items];
  const firstElement = itemsCopy[0];
  itemsCopy[0] = itemsCopy[1];
  itemsCopy[1] = firstElement;

  return (
    <div className={styles.wrapper} ref={container}>
      <div className={styles.top} ref={wrapper}>
        <div className={styles.header + (isOpened ? ' hidden':'')}>
          <div className={styles.headerLeft}>
            <img
              src={menuMobileIcon}
              className={styles.menuIcon}
              alt="Menu"
              onClick={() => setOpened(true)}
            />
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="BENLUX"/>
              </Link>
            </div>
            <HeaderReinsurance textsContent={contents.reinsuranceContent}/>
          </div>
          <div className={styles.headerRight}>
            <HeaderSearch
              searchProvider={searchProvider}
              history={history}
            />
            <HeaderUserMenu
              Login={loginPath}
              customer={account}
              signOut={signOut}
              onPressCart={onPressOrder}
              cart={cart}
            />
          </div>
        </div>
        <div className={styles.navMenu + (isOpened ? ' active':'')}>
          <NavBarMenu
          items={itemsCopy}
          isOpen={isOpened}
          contents={contents}
          setOpenedMenuIndex={setOpenedMenuIndex}
          openedMenuIndex={openedMenuIndex}
          allProductsDropdownRef={allProductsDropdownRef}
          />
          <div className={styles.closeMenu} onClick={() => setOpened(false)}>
            <img src={cross} className={styles.crossMenu} alt=""/>
          </div>
        </div>
        {promotionnalBannerData && promotionnalBannerData.length > 0 && (
          <div
            className={promotionnalBannerData.length > 1 ? styles.message2 : styles.message}>
            {promotionnalBannerData.slice(0, 2).map((row, index) => <span key={index} dangerouslySetInnerHTML={{__html: row}}/>)}
          </div>
        )}
      </div>
    </div>
  );
});
