import React from 'react';
import styles from './AddAddress.css';

type AddAddressProps = {
    openModal?: any;
};

function AddAddress({
                        openModal
                     }: AddAddressProps) {



    return (
        <div className={styles.wrapper} onClick={() => openModal()}>
            <div>
                Ajouter une adresse
            </div>

        </div>
    );
}

export default AddAddress;
