import React from 'react';
import * as styles from './SelectionLayout.css';
import {
  CarouselCard,
  PageHeader,
  Headband,
  HeadbandCarousel,
  ProductsSlider,
  SliderImg,
} from "../index";

import {selection} from "../../variable/dataTestSelection";
import Breadcrumbs from "../Breadcrumbs";

type SelectionLayoutProps = {
  selection: any;
  children: any;
  // selection: {
  //   cover: string
  //   title: string
  //   description: string
  //   breadcrumbs: [
  //     {
  //       urlPath: string
  //     }
  //   ]
  //   contents: [
  //     {
  //       title: string
  //       content: string
  //       image: {
  //         url: string
  //         alt: string
  //       }
  //       products: []
  //       button?: {
  //         urlPath: string
  //         label: string
  //       }
  //     }
  //   ]
  //   suggestions: {
  //     title: string
  //     description: string
  //     contents: [
  //       {
  //         image: string
  //         title: string
  //         url: string
  //       }
  //     ]
  //   }
  // }
};

function SelectionLayout({selection, children}: SelectionLayoutProps) {

  return (
    <div className={styles.wrapper}>
      <PageHeader image={selection.image} title={selection.name} description={selection.description}/>
      <div className={styles.breadcrumbsNavigation}>
        {/*<Breadcrumbs breadcrumbs={selection.breadcrumbs}/>*/}
      </div>
      {selection.children.map((content: any) => (
        <>
          {content.description ? (
            <div className={styles.carousel}>
              <Headband title={content.name} description={content.description} showButton textButton={'Voir la sélection'}/>
              <CarouselCard items={content.products.items}/>
            </div>
          ) : (
            <div>
              <ProductsSlider
                items={content.products.items}
                title={content.name}
              />
            </div>
          )}
        </>
      ))}
      {/*{children}*/}
    </div>
  );
}

export default SelectionLayout;
