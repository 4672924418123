import React from 'react';
import star from "../../assets/star.png";
import styles from './ReviewAverage.css';
// @ts-ignore
import ReactStars from "react-rating-stars-component";

type ReviewAverageProps = {
  rating: {
    average: number
    reviewsCount: number
  };
  starsCounts: [{
    count: number
    value: number
  }];
};


const ReviewAverage = ({rating, starsCounts}: ReviewAverageProps) =>
  <div>
    <div className={styles.note}>
      <div className={styles.averageText}>Note moyenne du produit :</div>
      <div className={styles.average}>
        <div
          className={styles.averageDiv}>{rating.average.toString().replace('.', ',')}</div>
        <ReactStars
          size={18}
          value={rating.average}
          isHalf={true}
          edit={false}
          color={'#E2E2E2'}
          activeColor={'#EF394F'}
        />
      </div>
      <div>
        {starsCounts.map(({value, count}, index: number) =>
          <div className={styles.starGauge} key={index}>
            <span>{value}</span>
            <img src={star} alt={'note star'}/>
            <progress max={rating.reviewsCount} value={count}/>
            <span className={styles.count}>{count}</span>
          </div>
        )}
      </div>
    </div>
  </div>


export default ReviewAverage;
