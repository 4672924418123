import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {ProductInfo, ProductImages} from '../index';
import styles from './Product.css';
import Breadcrumbs from '../Breadcrumbs';

type ProductProps = {
  product: any;
  onClickFollowBrand?: any;
  onChangeQty: (qty: number) => void;
  onChangeVariante?: any;
  error?: any;
  disabledButton?: any;
  setFieldValueOpinion?: any;
  inputBackInStock?: any;
  trustpilotData: {
    profileUrl: string,
    ratingCount: number,
    ratingValue: number,
    bestRating: number,
    starsString: string,
    stars: number,
  }
};

function Product({product, onChangeQty, onChangeVariante, error, disabledButton, inputBackInStock, trustpilotData}: ProductProps) {
  let defaultSelectedValueIndex = 0;
  if (product.selectedProductChildId > 0) {
    defaultSelectedValueIndex = product.configurableOptions[0].values.findIndex(v => v.productId === product.selectedProductChildId);
  } else if (product.configurableOptions.length > 0) {
    const firstInStockValueIndex = product.configurableOptions[0].values.findIndex(v => v.inStock);
    if (firstInStockValueIndex > -1) {
      defaultSelectedValueIndex = firstInStockValueIndex;
    }
  }

  const [selectedValueIndex, setSelectedValueIndex] = useState(defaultSelectedValueIndex);

  return (
    <div className={styles.productWrapper}>
      {product.brand.name === 'Dior' && (
        <Link to={"/dior"} style={{display: 'block', marginTop: '24px', padding: '16px 0 10px', background: '#000', textAlign: 'center'}}>
          <img src={"https://www.sephora.fr/on/demandware.static/-/Sites-siteCatalog_Sephora_FR/default/dwda4ff99c/all_brands/Visual_brands/LOGO_DIOR_CAPITALES_BLANC - Copie.svg"} alt={"Dior"} height={"32px"}/>
        </Link>
      )}
      <Breadcrumbs breadcrumbs={product.breadcrumbs} currentPageName={product.brand.name + ' ' + product.name + ' ' + product.shortDescription}/>
      <div className={styles.productContainer}>
        <ProductImages
          product={product}
          selectedValueIndex={selectedValueIndex}
        />
        <ProductInfo
          inputBackInStock={inputBackInStock}
          error={error}
          product={product}
          onChangeQty={onChangeQty}
          selectedValueIndex={selectedValueIndex}
          onChangeVariante={(ev: any, valueIndex: any) => {
            setSelectedValueIndex(valueIndex);
            onChangeVariante(ev);
          }}
          disabledButton={disabledButton}
          trustpilotData={trustpilotData}
        />
      </div>
    </div>
  );
}

export default Product;
