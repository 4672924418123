import React, {CSSProperties} from 'react';
import * as styles from './Button.css';
import {Link} from "react-router-dom";

// @ts-ignore
global.React3 = require('react');

enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Coupon = 'coupon'
}

type ButtonProps = {
  loading?: boolean;
  text?: string;
  href?: any;
  onClick?: () => void;
  primary?: any;
  secondary?: any;
  type: ButtonTypes
  style?: CSSProperties;
  BlacknWhite?: any;
  as?: string;
  disabled?: boolean;
  darkMode?: boolean;
};

function Button({text, href, onClick, type, style, as = "button", loading, disabled = false, darkMode = false}: ButtonProps) {
  const className = styles.button + ' ' + styles[type] + (darkMode ? ' ' + styles.darkMode : '');
  return (
    <>
      {as === 'button' &&
      <button
        style={style}
        onClick={onClick}
        className={className + ' ' + (loading ? styles.loading : '')}
        type={'button'}
        disabled={disabled || loading}
      >
        {text}
      </button>
      }
      {as === 'link' &&
        <Link
          style={style}
          to={href}
          className={className + ' ' + styles[type]}
          type={type}
          onClick={onClick}
        >
          {text}
        </Link>
      }
      {as === 'submit' &&
        <button
          style={style}
          className={className + ' ' + styles[type] + ' ' + (loading ? styles.loading : '')}
          type={'submit'}
          disabled={loading}
        >
          {text}
        </button>
      }
      {as === 'externalLink' &&
      <a
        href={href}
        style={style}
        className={className + ' ' + styles[type] + ' ' + (loading ? styles.loading : '')}
        target={'_blank'}
        rel={'noopener'}
      >
        {text}
      </a>
      }
    </>
  );
}

export {
  Button,
  ButtonTypes
}
