import React from 'react';
import * as styles from './InstagramPosts.css';

type InstagramPostsProps = {
  posts: [
    {
      url: string,
      thumbnailUrl: string,
      caption: string
    }
  ] | null;
};

function InstagramPosts({posts}: InstagramPostsProps) {
  return (
    <div className={styles.wrapper}>
      {[...Array(6)].map((_, index) => {
        const post = (posts && posts.length > 0 ? posts[index] : {url: '', thumbnailUrl: '', caption: ''});

        return (
          <a href={post.url} target={'_blank'} rel={'noopener'} key={index}>
            <img src={post.thumbnailUrl} alt={`Voir le post n°${index + 1} de Benlux sur Instagram (@benluxparis)`} loading="lazy"/>
          </a>
        );
      })}
    </div>
  );
}

export default InstagramPosts;
