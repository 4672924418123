import { GET_SIDEBAR_STATE } from './pages/shop/components/Sidebar';
/**
 * Defines client-side state resolvers
 */

const sortOrders = [
  {
    name: 'Préférés',
    value: undefined,
    __typename: 'SortOrder'
  },
  {
    name: 'Prix croissant',
    value: {
      field: 'price',
      direction: 'asc',
      __typename: 'SortOrderValue'
    },
    __typename: 'SortOrder'
  },
  {
    name: 'Prix décroissant',
    value: {
      field: 'price',
      direction: 'desc',
      __typename: 'SortOrderValue'
    },
    __typename: 'SortOrder'
  }
];

export default {
  data: {
    sidebar: {
      context: {
        key: null,
        __typename: 'SidebarStatus',

        data: {
          __typename: 'SidebarStatus',

          itemId: null,
        }
      },
      contentType: null,
      error: null,
      side: 'right',
      isOpen: false,
      __typename: 'SidebarStatus'
    }
  },

  resolvers: {
    Query: {
      sortOrders: () => sortOrders
    },

    Mutation: {
      openSidebar: (_, { contentType, side, error, contextKey, contextItemId }, { cache }) => {
        const data = {
          sidebar: {
            context: {
              key: contextKey ? contextKey: null,
              __typename: 'SidebarStatus',

              data: {
                itemId: contextItemId ? contextItemId : null,
                __typename: 'SidebarStatus',

              }
            },
            contentType,
            error: error ? error : null,
            side: side || 'right',
            isOpen: true,
            __typename: 'SidebarStatus'
          }
        };
        cache.writeQuery({ query: GET_SIDEBAR_STATE, data });

        return null;
      },

      closeSidebar: (_, _variables, { cache }) => {
        const queryResponse = cache.readQuery({ query: GET_SIDEBAR_STATE });
        const sidebar = { ...queryResponse.sidebar };
        sidebar.isOpen = false;

        cache.writeQuery({
          query: GET_SIDEBAR_STATE,
          data: { sidebar }
        });

        return null;
      }
    }
  }
};
