const formatPrice = (price: number) => {
  if (price) {
    return price.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  return '';
}

export default formatPrice;
