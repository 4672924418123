import React from 'react';
import * as styles from './ClubBenluxPage.css';
import {Button, ButtonTypes} from "../index";
import Gateau from '../../assets/Gâteau.svg';
import Delivery from '../../assets/Boîte aux lettres.svg';
import VIPYellow from '../../assets/VIPYellow.svg';
import Picture from '../../assets/VIPImage.png';
import QuestionsDivider from '../../assets/questionsDivider.png';
import VIP from '../../assets/VIP.svg';
import largeDiamond from '../../assets/largeDiamond.png';

type ClubBenluxPageProps = {};

function ClubBenluxPage({}: ClubBenluxPageProps) {

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.greyBlock}>
          <div className={styles.clubForm}>
            <h2>Rejoignez le Club BENLUX</h2>
            <p>Lorsque vous créez votre compte en ligne sur BENLUX, vous rejoignez automatiquement
              le Club BENLUX : notre programme de fidélité. Et comme tout Club qui se respecte, nos membres bénéficient d’avantages exclusifs !
            </p>
            <Button
              type={ButtonTypes.Primary}
              as={'link'}
              href={'/connexion'}
              text={"S'inscrire gratuitement"}
            />
          </div>
        </div>
        <div className={styles.blockRight}>
          <div>
            <h2>Vos privilèges</h2>
            <div className={styles.text}>
              <img src={VIP}/>
              <p>En tant que membre du Club BENLUX, vous ajoutez <strong>2,5%</strong> du montant total dans votre porte-monnaie fidélité à chaque achat.</p>
            </div>
            <div className={styles.text}>
              <img src={VIP}/>
              <p>À votre inscription, une <strong>carte de fidélité</strong> vous est attribuée. Présentez votre carte virtuelle en magasin pour profiter de vos avantages fidélité.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <img src={largeDiamond} className={styles.largeDiamond}/>
        <div className={styles.container}>
      <div className={styles.blockVIP}>
        {/*<img src={Picture}/>*/}
        <div
          className={styles.boxPicture}
          style={{
          backgroundImage: "url(" + Picture + ")",
        }}>
        <div className={styles.cardVIP}>
          <div className={styles.firstBlock}>
            <h2>Devenez membre VIP</h2>
            <p>Pour seulement 19,90 €/an, devenez membre VIP et profitez
              d’encore plus d’avantages !</p>
          </div>
          <div className={styles.secondBlock}>
            <div className={styles.bonusVIPBlock}>
              <img src={VIPYellow}/>
              <span>Les membres VIP ajoutent <strong>5%</strong> du montant total de chaque achat dans leur porte-monnaie fidélité.</span>
            </div>
            <div className={styles.bonusVIPBlock}>
              <img src={Delivery}/>
              <span>Profitez de la <strong>livraison gratuite</strong> pour toutes vos commandes sans minimum d’achat.</span>
            </div>
            <div className={styles.bonusVIPBlock}>
              <img src={Gateau}/>
              <span>Obtenez <strong>5%</strong> de réduction sur tout le site pendant toute la semaine de votre <strong>anniversaire</strong>.</span>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              as={'link'}
              type={ButtonTypes.Secondary}
              text={'Devenir membre VIP'}
              style={{width: '100%'}}
            />
          </div>
        </div>
        </div>
      </div>

      <div className={styles.questionsBlock}>
        <div className={styles.questions}>
          <h2>Question Fréquentes</h2>
          <p>Quel est le délai de livraison pour la livraison illimitée en tant que membre VIP ?</p>
          <p>Le bonus fidélité est-il valable pour les articles en promotion ?</p>
          <p>Puis-je bénéficier du programme fidélité si je ne réside pas en France ?</p>
        </div>
        <img src={QuestionsDivider}/>
      </div>
        </div>
      </div>
    </div>
  );
}

export default ClubBenluxPage;
