import React, { useState } from "react";
import { WatchAttributeSet, DefaultAttributeSet } from "../index";
import followBrand from "../../assets/followBrand.png";
import styles from "./ProductDescription.css";
import { Link } from "react-router-dom";
import FragranceAttributeSet from "../FragranceAttributeSet/FragranceAttributeSet";
import SunglassesAttributeSet from "../SunglassesAttributeSet/SunglassesAttributeSet";

type ProductSwitchFormProps = {
  product: any;
  onClickFollowBrand: () => void;
};

function ProductDescription({
  product,
  onClickFollowBrand,
}: ProductSwitchFormProps) {
  const [collapse, setCollapse] = useState(false);

  const { attributeSetName, attributes } = product;

  const attributeSetComponents: {} = {
    Watch: WatchAttributeSet,
    Fragrance: FragranceAttributeSet,
    Sunglasses: SunglassesAttributeSet,
  };

  const AttributeSetComponent = () => {
    // @ts-ignore
    if (attributeSetComponents[attributeSetName]) {
      // @ts-ignore
      const AttributeSetComponent = attributeSetComponents[attributeSetName];
      return <AttributeSetComponent attributes={attributes} />;
    }

    return <DefaultAttributeSet attributes={attributes} />;
  };

  return (
    <div className={styles.description + " " + styles.show}>
      <div className={styles.panelWrapper}>
        <div className={styles.panel}>
          <p
            dangerouslySetInnerHTML={{
              __html: product.description.replace(/\n/g, "<br/>"),
            }}
          />
          {AttributeSetComponent()}
        </div>
        <div className={styles.fade} />
      </div>
      {product.brand && (
        <div className={styles.brand}>
          {product.brand.name !== "Dior" && (
            <Link to={"/" + product.brand.urlKey}>
              <div className={styles.brandLogo}>
                <img
                  src={product.brand.thumbnailUrl}
                  alt={product.brand.name}
                />
              </div>
            </Link>
          )}
          {/* <div className={styles.follow} onClick={onClickFollowBrand}>
                  <img src={followBrand} alt={'follow brand'}/>
                  <span>Suivre</span>
                </div>  */}
        </div>
      )}
    </div>
  );
}

export default ProductDescription;
