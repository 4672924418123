import React from 'react';
import {ImageSlider} from "../index";
import './style.css';
import styles from './SliderImg.css';

type CarouselImgProps = {
    selectedIndex: number;
    items: any[];
    onSelect: (index: number) => void;
};

function SliderImg({selectedIndex, items, onSelect}: CarouselImgProps) {
    return (
        <div className={styles.carouselImg}>
            <div className={styles.container}>
            {items.slice(0, 4).map((item, index) =>
                <div
                  key={index}
                  onClick={() => onSelect(index)}
                  // className={styles.thumbs + ' ' + (selectedIndex === index ? styles.thumbActive : '')}
                  className={styles.thumbs}
                >
                    <img className={styles.thumb} src={item.thumbnail} alt={'thumbnail'}/>
                    <div className={styles.thumbBar + ' ' + (selectedIndex === index ? styles.thumbActive : '')}/>
                </div>
            )}
            </div>
            <div style={{flex: 1}}>
                {items.map((item, index) =>
                    <div key={index} className={styles.big + ' ' + (selectedIndex === index ? styles.active : '')}>
                       <ImageSlider images={item.thumbnail}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SliderImg;
