import React from "react";
import styles from "./SunglassesAttributeSet.css";

type Props = {
  attributes: [
    {
      attributeCode: string;
      value: string;
    }
  ];
};

const displayedAttributes = [
  {
    code: "glassesMaterial",
    label: "Matière principale",
  },
  {
    code: "glassesUvIndex",
    label: "Catégorie UV",
  },
  {
    code: "glassesLength",
    label: "Taille des branches (mm)",
  },
  {
    code: "glassesLensWidth",
    label: "Largeur des verres (mm)",
  },
  {
    code: "glassesGap",
    label: "Taille du pont (mm)",
  },
];

const SunglassesAttributeSet = ({ attributes }: Props) => (
  <div className={styles.container}>
    <dl>
      {displayedAttributes.map(({ code, label }, index: number) => {
        const attribute = attributes.find((a: any) => a.attributeCode === code);
        if (attribute) {
          return (
            <div key={index}>
              <dt>{label}</dt>
              <dd>{attribute.value}</dd>
            </div>
          );
        }

        return;
      })}
    </dl>
  </div>
);

export default SunglassesAttributeSet;
