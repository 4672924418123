import React from 'react';
import {Button, ButtonTypes} from "../index";
import styles from './ProductPresentation.css';

type ProductPresentationProps = {
  // title: string;
  // content: string;
  textButton?: any;
  urlPath?: any;
  content: {
    content: string;
    title: string;
    image: {
      url: string;
      alt: string;
    },
    button?: {
      label: string,
      urlPath: string;
    }
  }
};

function ProductPresentation({content}: ProductPresentationProps) {
  const {button, image} = content;
  return (
    <div className={styles.blockProduct}>
      <div className={styles.headband}>
        <div className={styles.block}>
          <h2>{content.title}</h2>
          <p dangerouslySetInnerHTML={{__html: content.content.replace(/\n/g, '<br/>')}}/>
        </div>
        {button && (
          <div>
            <Button
              type={ButtonTypes.Tertiary}
              as={'link'}
              text={button.label}
              href={button.urlPath}
            />
          </div>
        )}
      </div>
      <img src={image.url} alt={image.alt}/>
    </div>
  );
}

export default ProductPresentation;
