import gql from 'graphql-tag';
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_MENU = gql`
  query Menu {
    brandsContents: cmsBlock(identifier: "header-brands-contents") {
      content
    }
    soldesContents: cmsBlock(identifier: "header-soldes-contents") {
      content
    }
    noveltiesContents: cmsBlock(identifier: "header-novelties-contents") {
      content
    }
    blackFridayContents: cmsBlock(identifier: "header-black-friday-contents") {
      content
    }
    selectionsContents: cmsBlock(identifier: "header-selections-contents") {
      content
    }
    giftsContents: cmsBlock(identifier: "header-gifts-contents") {
      content
    }
    inspirationsContents: cmsBlock(identifier: "header-inspirations-contents") {
      content
    }
    reinsuranceContent: cmsBlock(identifier: "header-reinsurance") {
      content
    }
    promotionnalBanner: cmsBlock(identifier: "promotional-banner") {
      content
    }
    menu {
      id
      categoryId
      name
      urlPath
      cssClass
      highlighted
      children {
        id
        categoryId
        name
        urlPath
        cssClass
        highlighted
        children {
          id
          categoryId
          name
          urlPath
          cssClass
          highlighted
          children {
            id
            categoryId
            name
            urlPath
            highlighted
            cssClass
          }
        }
      }
    }
  }
`;

export class MenuQuery extends Query {
    static defaultProps = {
        query: GET_MENU
    };
}
