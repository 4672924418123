import React from 'react';
import styles from './HomeBox.css';

type HomeBoxProps = {
  children?: any;
};

function HomeBox({
                     children
                   }: HomeBoxProps) {



  return (
    <div className={styles.box}>
      {children}
    </div>
  );
}

export default HomeBox;
