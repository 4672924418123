import React, {useState} from 'react';
import arrowfilters from "../../assets/filters-arrow.svg";
import * as styles from './SortBySearch.css';
import {Link} from "react-router-dom";

type Props = {
  href?: any;
  refine?: any;
  items?: any;
  dropdown?: any;
};

function SortBySearch ({items, refine, href}: Props)  {
  const [filterObject, setFilterObject] = useState({});
  const [labelName, setLabel] = useState('');

  const openFilter = (filter: object) => {
    if (filter === filterObject) {
      setFilterObject('')
    } else {
      setFilterObject(filter)
    }
  }

  const onClickValue = (e: any, value: any, label: any) => {
      e.preventDefault();
      refine(value);
      setLabel(label)
  }

  return (
    <div className={styles.filters}>
      <div className={styles.containerSort}>
        <div className={styles.filterButton + ' ' + (filterObject === items ? styles.active : '')}
             onClick={() => openFilter(items)}>
          <span>{labelName ? labelName : 'Ranger par'}</span>
          <img src={arrowfilters} alt={'filter'}/>
        </div>
        <div className={styles.filtersContent + ' ' + (filterObject === items ? styles.active : '')}>
          {items?.map((item: any, index: number) => {
            return (
              <Link
                key={index}
                to={href(item.value)}
                className={styles.valueLabel}
                onClick={(e) => onClickValue(e, item.value, item.label)}>
                <span>{item.label}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default SortBySearch;
