import React from "react";
import cx from "classnames";

import styles from "./PageHeader.css";
import { SubCategoriesNavigation } from "../index";

type Props = {
  image?: any;
  title: string;
  description: string;
  notCategory?: any;
  categories?: [
    {
      name: string;
      urlPath: string;
      isSmart: boolean;
    }
  ];
  categoriesCards?: SubCategoryLink[];
};

type SubCategoryLink = {
  name: string;
  url: string;
  img: string;
};

import parfumsImage from "./bf-parfums.jpg";
import maquillageImage from "./bf-soin-maquillage.jpg";
import montresImage from "./bf-bijoux-montres.jpg";
import maroquinerieImage from "./bf-maroquinerie.jpg";
import parapharmacieImage from "./bf-parapharmacie.jpg";
import { Link } from "react-router-dom";

const bfSubcategories: SubCategoryLink[] = [
  {
    name: "Parfums",
    url: "/parfums-black-friday",
    img: parfumsImage,
  },
  {
    name: "Maquillage et soins",
    url: "/maquillage-black-friday",
    img: maquillageImage,
  },
  {
    name: "Maroquinerie",
    url: "/maroquinerie-black-friday",
    img: maroquinerieImage,
  },
  {
    name: "Montres et bijoux",
    url: "/montres-black-friday",
    img: montresImage,
  },
];

function PageHeader({
  image,
  title,
  description,
  notCategory,
  categories = [],
  categoriesCards = [],
}: Props) {
  const inBFCategories = title.toLowerCase().indexOf("black friday") > -1;
  if (title === "Black Friday") {
    categoriesCards = bfSubcategories;
  }
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundColor: inBFCategories ? "#11062B" : "#F6B0AC" }}
    >
      <div
        className={
          styles.imageSlider + (notCategory ? styles.heightMobile : "")
        }
      >
        <div
          className={cx(styles.image, {
            fixedHeight:
              categoriesCards.length === 0 && categories.length === 0 && image,
          })}
          style={{ position: "relative" }}
        >
          {image && !inBFCategories && (
            <img
              src={image}
              alt={title}
              style={{
                position: "absolute",
                inset: 0,
                height: "100%",
                width: "100%",
                objectFit: "cover",
                zIndex: 0,
              }}
            />
          )}
          <div className={styles.content} style={{ position: "relative" }}>
            <div className={styles.categoryTop}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.description}>{description}</p>
            </div>
            {categoriesCards.length === 0 && categories.length > 0 && (
              <SubCategoriesNavigation categories={categories} />
            )}
            {categoriesCards.length > 0 && (
              <div>
                <ul className={styles.categoriesCards}>
                  {categoriesCards.map((card) => (
                    <li key={card.url}>
                      <h2>
                        <Link to={card.url}>
                          <img
                            width={438}
                            height={270}
                            src={card.img}
                            alt={`Black Friday ${card.name}`}
                          />
                          <span>{card.name}</span>
                        </Link>
                      </h2>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
