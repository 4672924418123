import React from 'react';
import styles from './AccountForm.css';
import {Button, ButtonTypes} from "../index";

type AccountFormProps = {};

function AccountForm({}: AccountFormProps) {
  return (
    <div className={styles.wrapper}>
      <h1>Mes informations personnelles</h1>
      <h2>BENLUX, enchantés. Et vous, qui êtes-vous ?</h2>
      <input name={'firstname'} placeholder={'Prénom'} type={'text'}/>
      <input name={'lastname'} placeholder={'Nom'} type={'text'}/>
      <input name={'email'} placeholder={'Adresse email'} type={'email'}/>
      <Button type={ButtonTypes.Secondary} text={'Enregistrer'}/>
    </div>
  );
}

export default AccountForm;
