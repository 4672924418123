import React, {
  forwardRef,
  useImperativeHandle,
  useState
} from 'react';
import {Level2List, Level3LeftList, Level3RightList} from "../index";
import * as styles from './AllProductsDropdown.css';
import arrowMenu from "../../assets/arrowMenu.png";

type AllProductsDropdownProps = {
  item: any;
  isOpen?: any;
  isIndex?: any;
  setIndex?: any;
  initialSelectedIndex?: number;
  onMouseLeave?: any;
};

const AllProductsDropdown = forwardRef(({item, initialSelectedIndex, isIndex, setIndex, onMouseLeave}: AllProductsDropdownProps, ref) => {
  let [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  useImperativeHandle(ref, () => ({
    resetSelectedIndex(index: number) {
      setSelectedIndex(index);
    }
  }));

  const onSelect = (index: number) => {
    setSelectedIndex(index);
  }

  const menuGoBack = () => {
    setSelectedIndex(undefined)
    // menu = document.getElementsByClassName(styles.content + ' active')[selectedIndex];
    // menu.style.transform = "translateX(100%)";
  }

  return (
    <div className={styles.dropdown} onMouseLeave={onMouseLeave}>
      <Level2List
        items={item.children}
        selectedIndex={selectedIndex}
        onSelect={onSelect}
      />
      {item.children.map((subItem: any, subIndex: number) => {
        const notHighlighted = subItem.children.filter((child: any) => !child.highlighted);

        return (
          <div key={'level-2-wrapper-' + subIndex}
               className={styles.content + ((selectedIndex === subIndex) ? ' active' : '')}
          >
            <div className={styles.menuMobile}>
              <div className={styles.subMenu}>
                <div className={styles.menuName}>{subItem.name}</div>
                <img onClick={() => menuGoBack()} src={arrowMenu} alt="Retour"/>
              </div>
              <div className={styles.separator}/>
            </div>
            <Level3LeftList items={subItem.children} path={subItem.urlPath}/>
            <Level3RightList items={notHighlighted}/>
          </div>
        )
      })}
    </div>
  );
});

export default AllProductsDropdown;
