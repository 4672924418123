import React from 'react';
import {Link} from 'react-router-dom';
const ReactUltimatePagination = require('react-ultimate-pagination');
import styles from './ProductListPagination.css';

type Props = {
  currentPage: number;
  totalPages: number;
  query: any;
}

function ProductListPagination({currentPage, totalPages, query}: Props) {

  function Page({value, isActive}) {
    const localQuery = new URLSearchParams(query.toString())
    localQuery.set('page', value);

    if (isActive) {
      return (
        <span className={styles.page + ' ' + styles.isActive}>{value}</span>
      )
    }

    return (
      <Link
        className={styles.page}
        to={value === 1 ? '#' : '?' + decodeURIComponent(localQuery.toString())}
      >{value}</Link>
    );
  }

  function Ellipsis() {
    return <span>...</span>
  }

  const itemTypeToComponent = {
    'PAGE': Page,
    'ELLIPSIS': Ellipsis
  };

  const UltimatePagination = ReactUltimatePagination.createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent,
    WrapperComponent: Wrapper
  });

  function Wrapper(props) {
    return <div className={styles.wrapper}>{props.children}</div>
  }

  if (currentPage <= totalPages && totalPages > 1) {
    return <UltimatePagination
      currentPage={currentPage}
      totalPages={totalPages}
      hideFirstAndLastPageLinks
      hidePreviousAndNextPageLinks
    />
  }

  return <></>;
}

export default ProductListPagination;
