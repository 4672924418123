import React from 'react';
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import {Loader} from '@deity/falcon-ecommerce-uikit';
import Query from "@deity/falcon-client/src/components/Query";
import { GET_URL } from "@deity/falcon-client/src/graphql/url.gql";

import loadable from 'src/components/loadable';

const Category = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/category" */ './shop/Category/Category'));
const Product = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/product" */ './shop/Product'));
const Brand = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/brand" */ './shop/Brand'));
const Selections = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/selections" */ './shop/Selections'));
const Cms = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/cms" */ './shop/Cms.js'));
const Inspiration = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/inspiration" */ './shop/Inspiration/Inspiration.js'));
const NotFound = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "not-found" */ '../components/NotFound'));

const DynamicRoute = ({ components, history, location, notFoundComponent}) => {
  const { pathname } = location;
  const path = pathname.startsWith('/') ? pathname.substring(1) : pathname;

  return (
    <Route
      render={props =>
        <Query query={GET_URL} variables={{ path }}>
          {({ data }) => {
            if (!data || data.url === null) {
              const {staticContext} = props;
              if (staticContext) {
                staticContext.statusCode = 404;
              }

              const NotFound = notFoundComponent;
              return <NotFound/>;
            }

            const { url } = data;
            if (url.redirectType !== 0) {
              const {staticContext} = props;
              if (staticContext) {
                staticContext.statusCode = url.redirectType;
              }

              if (url.target.startsWith('http')) {
                return <Redirect to={url.target} />;
              }
              
              return <Redirect to={`/${url.target}`} />;
            }

            const Component = components[url.type];
            if (!Component) {
              return <p>{`Please register component for '${url.type}' content type!`}</p>;
            }

            const {staticContext} = props;

            try {
              return <Component {...url} location={location} history={history} staticContext={staticContext} />;
            } catch (e) {
              const NotFound = notFoundComponent;
              console.error(e);
              staticContext.statusCode = 500;

              return <NotFound/>;
            }
          }}
        </Query>
      }
    />
  );
};

DynamicRoute.propTypes = {
  components: PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default props => (
  <DynamicRoute
    {...props}
    loaderComponent={Loader}
    components={{
      'shop-category': Category,
      'shop-product': Product,
      'shop-brand': Brand,
      'shop-cms_page': Cms,
      'shop-selections': Selections,
      'shop-inspiration': Inspiration,
    }}
    notFoundComponent={NotFound}
  />
);
