import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import styles from './ProductInfo.css';
import {
  OutOfStock,
  ProductInfoAddCart,
  ProductPrice,
  TextSwatch,
  TintSwatch
} from '../index';

type ProductProps = {
  product: any;
  onChangeQty: (qty: number) => void;
  onChangeVariante?: any;
  error?: any;
  disabled?: any;
  disabledButton?: boolean;
  selectedValueIndex?: any;
  inputBackInStock?: any;
  trustpilotData: {
    profileUrl: string,
    ratingCount: number,
    ratingValue: number,
    bestRating: number,
    starsString: string,
    stars: number,
  }
};

function ProductInfo({
  product,
  onChangeQty,
  onChangeVariante,
  selectedValueIndex,
  inputBackInStock,
  disabledButton,
  trustpilotData
}: ProductProps) {
  const price = product.configurableOptions.length > 0 ?
    product.configurableOptions[0].values[selectedValueIndex].price
    : product.price;

  const inStock = (product.configurableOptions && product.configurableOptions.length > 0 ? product.configurableOptions[0].values[selectedValueIndex].inStock : product.stock.isInStock);

  const truncate = (str: any) => {
    const regex = /^(.{140})(?:\s+\w*)?/s;
    const extract = str.match(regex);
    if (extract) {
      return extract[1] + '...';
    }

    return '...';
  }

  const {brand, name, shortDescription, description, rating} = product;

  return (
    <div className={styles.productOptions}>
      <div className={styles.blockTop}>
        <div className={styles.header}>
          <h1>
            <span className={styles.title}>{name}&nbsp;</span>
            <span className={styles.subtitle}>{shortDescription}&nbsp;</span>
            <Link
              to={'/' + brand.urlKey}
              className={styles.brand}
            >
              {brand.name}
            </Link>
          </h1>
          <div className={styles.rating}>
            <ReactStars
              size={15}
              value={rating.average}
              isHalf={true}
              edit={false}
              color={'#E2E2E2'}
              activeColor={'#EF394F'}
            />
            {product.rating.reviewsCount > 0 && (
              <span className={styles.reviewsCounts}>({product.rating.reviewsCount})</span>
            )}
          </div>
        </div>
        <p className={styles.panelWrapper}>
          <span>{truncate(description)}&nbsp;</span>
          <a href="#details">En savoir plus</a>
        </p>
        <div className={styles.blockPrice}>
          <ProductPrice price={price}/>
        </div>
        {(product.configurableOptions && product.configurableOptions.length === 1) && (
          <>
            <div className={styles.blockConfigurable}>
              {(product.configurableAttributeCode.indexOf('tint') === 0 || product.configurableAttributeCode === 'teinte_la_mer' || product.configurableAttributeCode === 'teinte_la_roche_posay' ? (
                <TintSwatch
                  configurableOption={product.configurableOptions[0]}
                  onChange={onChangeVariante}
                  selectedIndex={selectedValueIndex}
                />
              ) : (
                <TextSwatch
                  configurableOption={product.configurableOptions[0]}
                  onChange={onChangeVariante}
                  selectedIndex={selectedValueIndex}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {inStock ? (
        <ProductInfoAddCart
          product={product}
          onChangeQty={onChangeQty}
          selectedValueIndex={selectedValueIndex}
          disabledButton={disabledButton}
          trustpilotData={trustpilotData}
        />
      ) : (
        <OutOfStock inputBackInStock={inputBackInStock}/>
      )}
    </div>

  );
}

export default ProductInfo;
