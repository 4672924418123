import React from 'react';
import {Link} from 'react-router-dom';

import {PageHeader} from '../index';

import * as styles from './SalesEventsPage.css';

type SalesEventsPageProps = {
  content: {
    image: {
      url: string
    }
    title: string
    header: string
    events: [
      {
        url: string,
        image: {
          src: string
          alt: string
        }
        name: string
        description: string
      }
    ]
  }
};

const SalesEventsPage = ({content}: SalesEventsPageProps) =>
  <>
    <PageHeader
      image={content.image.url}
      title={content.title}
      description={content.header}
    />
    <div className={styles.wrapper}>
      {content.events.map((event, index) =>
        <div key={event.url} className={styles.event}>
          <Link to={event.url} className={styles.eventImage}>
            <img src={event.image.src} alt={event.image.alt} style={{width: '100%'}}/>
          </Link>
          <div className={styles.eventDescription}>
            <Link to={event.url}>{event.name}</Link>
            <p>{event.description}</p>
          </div>
        </div>
      )}
    </div>
  </>

export default SalesEventsPage;
