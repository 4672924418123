import React from 'react';
import {Button, CardWishList, HeaderAccount, CartItemRow} from "../index";
import {Link} from "react-router-dom";

import profile from "../../assets/profile.svg";
import vector from "../../assets/Vector.svg";
import arrowRight from "../../assets/arrowOrder.png";
import {order as orderTotal} from "../../variable/dataOrder";

import styles from './HeaderUserMenu.css';
import shoppingCartIcon from './shopping-cart.svg';
import userIcon from '../HeaderAccount/user.svg';

type HeaderUserMenuProps = {
  onPressAccount?: any;
  onPressSearch?: any;
  onPressVector?: any;
  onChangeText?: any;
  Login?: any;
  order?: any;
  customer?: any;
  signOut?: any;
  onPressCart: () => void;
  cart?: any;
};

function HeaderUserMenu({
  onPressAccount,
  onPressVector,
  Login,
  onPressCart,
  signOut,
  customer,
  cart,
}: HeaderUserMenuProps) {
  return (
    <div className={styles.headerUserMenu}>
      <HeaderAccount Login={Login} customer={customer} signOut={signOut}/>
      {/*<div className={styles.headerMenuItem}>*/}
      {/*    <Link onClick={onPressVector} to='/#'>*/}
      {/*        <img src={vector} className={styles.headerItems} alt="vector"/>*/}
      {/*    </Link>*/}
      {/*    <div className={styles.headerItemDropdown + ' ' + styles.wishlist}>*/}
      {/*        <div className={styles.dropdownWishlist}>*/}
      {/*            <h1>Ma wishlist</h1>*/}
      {/*            <CardWishList/>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div
        onClick={onPressCart}
        className={styles.cartIcon}
      >
        <img src={shoppingCartIcon} alt={"Mon panier"}/>
        {cart.itemsQty > 0 && <span>{cart.itemsQty}</span>}
      </div>
    </div>
  );
}

export default HeaderUserMenu;
