import React from 'react';
import * as styles from './FooterHelpPage.css';
import {CardHelp} from "../index";
import phoneService from '../../assets/phoneService.svg';
import mailService from '../../assets/mailService.svg';


type FooterHelpPageProps = {
  content: {
    title: string,
    description: string,
    links: [
      {
        url: string,
        thumbnail: string,
        description: string
      }
    ]
  }
};

function FooterHelpPage({content}: FooterHelpPageProps) {
  return (
      <div className={styles.footerBox}>
        <div className={styles.blockFooter}>
          <h2>{content.title}</h2>
          <span>{content.description}</span>
          <div className={styles.footerCards}>
            {content.links.map((link) =>
              <CardHelp description={link.description} thumbnail={link.thumbnail} url={link.url}/>
            )}
          </div>
        </div>
      </div>
  );
}

export default FooterHelpPage;
