import React, {useState} from 'react';
import {CarouselCard} from "../index";
import styles from './CarouselsSwitch.css';
import cx from 'classnames';

type Props = {
  news?: any;
  bestSellers?: any;
};

function CarouselsSwitch({
  news,
  bestSellers,
}: Props) {
  const [activeType, setActiveType] = useState('news');

  return (
    <div className={styles.container}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <span
          className={cx(styles.buttonCategory, {active: activeType === 'news'})}
          onClick={() => setActiveType('news')}
        >
          Nouveautés
        </span>
        <span
          className={cx(styles.buttonCategory, {active: activeType === 'bestSellers'})}
          onClick={() => setActiveType('bestSellers')}
        >
          Meilleures Ventes
        </span>
      </div>
      {activeType === 'news' && <CarouselCard items={news}/>}
      {activeType === 'bestSellers' && <CarouselCard items={bestSellers}/>}
    </div>
  );
}

export default CarouselsSwitch;
