import React from 'react';
import styles from './H2.css';

type H2Props = {
  title?: any;
  open: any;
};

function H2({title, open}: H2Props) {
  return (
    <span className={styles.h2 + (open ? ' open' : '')}>{title}</span>
  );
}

export default H2;
