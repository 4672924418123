import React from 'react';
import styles from './CardHelp.css';
import {Link} from "react-router-dom";

type CardHelpProps = {
  thumbnail?: any;
  description?: any;
  url?: any;
};

function CardHelp({thumbnail, description, url}: CardHelpProps) {
  return (
    <Link to={url} className={styles.cardHelp}>
      <img src={thumbnail}/>
      <div>{description}</div>
    </Link>
  );
}

export default CardHelp;
