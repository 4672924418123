import React from 'react';
import styles from './Footer.css';
import {FooterBottom, FooterTop, Newsletter} from '../index';

type FooterProps = {
  inputNewsletter?: any
  content: object,
  trustpilotData: {
    profileUrl: string,
    ratingCount: number,
    ratingValue: number,
    bestRating: number,
    starsString: number,
    stars: number,
  }
};

const Footer = ({inputNewsletter, content, trustpilotData}: FooterProps) =>
  <footer className={styles.wrapper}>
    <FooterTop/>
    <Newsletter inputNewsletter={inputNewsletter}/>
    <FooterBottom content={content} trustpilotData={trustpilotData}/>
  </footer>

export default Footer;
