import React, {useState} from 'react';
import {
  Button,
  ButtonTypes,
  ReviewAverage,
  ReviewForm,
  ReviewsList
} from "../index";
import styles from './ProductOpinion.css';

const moment = require('moment');

moment.locale("fr");

type ProductOpinionProps = {
  product: any;
  sendOpinion?: any;
  setFieldValue?: any;
  productReviews?: any;
  isSignedIn: boolean;
  onRedirectToSignIn: () => void;
};


function ProductOpinion({setFieldValue, product, productReviews, isSignedIn, onRedirectToSignIn}: ProductOpinionProps) {
  const [openedForm, setOpenedForm] = useState(Boolean);
  const onClickForm = () => {
    if (isSignedIn) {
      setOpenedForm(true);
      setFieldValue('sku', product.sku);
    } else {
      onRedirectToSignIn && onRedirectToSignIn();
    }
  }

  const onClickCloseForm = () => {
    setOpenedForm(false)
  }

  const onChangeRating = (rating: any) => {
    setFieldValue(rating);
  }

  const {productReviews: {reviews, starsCounts}} = productReviews
  const {rating} = product

  return (
    <div className={styles.wrapper}>
      <div className={styles.opinionContainer}>
        <ReviewAverage starsCounts={starsCounts} rating={rating}/>
        <ReviewsList productReviews={productReviews}/>
      </div>
      <div
        style={openedForm ? {display: 'none'} : {display: "flex"}}
        className={styles.show}
      >
        <Button
          text={'Je donne mon avis'}
          onClick={onClickForm}
          type={ButtonTypes.Tertiary}
          as={'button'}
        />
      </div>
      <ReviewForm
        setFieldValue={setFieldValue}
        openedForm={openedForm}
        closeForm={onClickCloseForm}
      />
    </div>
  );
}

export default ProductOpinion;
