import React from 'react';
import {
  CarouselContentCards,
  Headband, HeadbandCarousel,
  PageHeader,
} from "../index";


type SelectionsPageProps = {
  items: any;
  contents: any;
  category: any;
};

function SelectionsPage({items, contents, category}: SelectionsPageProps) {

  const findContents = (name: string) => {
    switch (name) {
      case 'Sélections':
        return contents.selectionsContents.content;
      default:
        return '[]'
    }
  }

  return (
    <div>
      <PageHeader image={category.image} title={category.name} description={category.description}/>
      <div>
        <Headband
          title={'Nos sélections'}
          showButton={false}
        />
      </div>
      <CarouselContentCards itemCarousel={JSON.parse(findContents('Sélections'))} />
      {category.children.map((child: any) =>
        <HeadbandCarousel
          showButton
          itemCarousel={child.products.items}
          urlPath={child.urlPath}
          title={child.name}
          description={child.description}
          textButton={'Voir la sélection'}
        />
      )}
    </div>
  );
}

export default SelectionsPage;
