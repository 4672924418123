import React from 'react';
import styles from './AddressCard.css';
import {Link} from "react-router-dom";
import edit from '../../assets/edit.svg';
import binAddress from '../../assets/binAddress.svg';
import {AddAddress} from "../index";

type AddressCardProps = {
  address?: any;
  onRemove?: any;
  openModal?: any;
  defaultAddress?: boolean;
};

function AddressCard({
  address,
  onRemove,
  openModal,
  defaultAddress
}: AddressCardProps) {

    const { company, street, postcode, city, countryId, id } = address;


    return (
        <div className={styles.wrapper}>
          {defaultAddress && <h1>Adresse par défaut</h1>}
            <div className={styles.container}>
            <div className={styles.block}>
                <div className={styles.text}>
                    {company && <span>{`${company}, `}</span>}
                    <span>{street}</span>
                    <p>{`${postcode} ${city}, ${countryId}`}</p>
                </div>
                <div className={styles.img}>
                    <Link to={`/compte/carnet-adresse/modifier/${id}`}>
                        <img src={edit}/>
                    </Link>
                    <div onClick={() => onRemove()}>
                        <img src={binAddress}/>
                    </div>
                </div>
            </div>
                {/*<AddAddress openModal={() => openModal()}/>*/}

            </div>
        </div>
    );
}

export default AddressCard;
