import React from 'react';
import {wishlist} from "../../variable/dataWishlist";
import styles from './CardWishList.css';
import {Link} from "react-router-dom";

function CardWishList() {
    return (
        <div className={styles.wishlistCards}>
            {wishlist.map((item: any, index: any) =>
                <div className={styles.cardWishlistItem} key={'wish-list' + index}>
                    <div className={styles.cardWishlist}>
                        <Link to={item.urlPath} className={styles.cardLink}>
                            <img src={item.urlImg} alt="product-wish" />
                        </Link>
                    </div>
                    <span>{item.title}</span>
                    <span style={{fontWeight: 'bold'}}>{item.price}</span>
                </div>
            )}
        </div>
    );
}

export default CardWishList;
