import React from 'react';
import * as styles from './InspirationLayout.css';
import { PageHeader, } from "../index";

import ProductPresentation from "../ProductPresentation";
import Breadcrumbs from "../Breadcrumbs";
import {Link} from "react-router-dom";

type InspirationLayoutProps = {
  inspiration: {
    cover: string
    title: string
    description: string
    breadcrumbs: [
      {
        urlPath: string
      }
    ]
    contents: [
      {
        title: string
        content: string
        image: {
          url: string
          alt: string
        }
        button?: {
          urlPath: string
          label: string
        }
      }
    ]
    suggestions: {
      title: string
      description: string
      contents: [
        {
          image: string
          title: string
          url: string
        }
      ]
    }
  }
};

function InspirationLayout({inspiration}: InspirationLayoutProps) {
  return (
    <div className={styles.wrapper}>
        <PageHeader image={inspiration.cover} title={inspiration.title} description={inspiration.description}/>
        <div className={styles.breadcrumbsNavigation}>
          <Breadcrumbs breadcrumbs={inspiration.breadcrumbs}/>
        </div>
      <div className={styles.products}>
        {inspiration.contents.map((content) =>
          <ProductPresentation content={content}/>
        )}
        <div className={styles.suggestions}>
          <h3>{inspiration.suggestions.title}</h3>
          <p>{inspiration.suggestions.description}</p>
          <div className={styles.blockImg}>
          {inspiration.suggestions.contents.map((block) => (
            <Link
              to={block.url}
              style={(block.image ? {backgroundImage: "url(" + block.image + ")"} : {})}
              className={styles.image}
            >
              <span className={styles.title}>{block.title}</span>
            </Link>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InspirationLayout;
