import React, {useState} from 'react';
import * as styles from './FilterBrandsDropDown.css'
import {Link} from "react-router-dom";

type FilterBrandsDropDownProps = {
  items: any
  title?: any
  onSelectClose?: any;
  onMouseEnter?: () => void
};

function FilterBrandsDropDown({
  items,
  title,
  onMouseEnter,
  onSelectClose
}: FilterBrandsDropDownProps) {

  let [listVisible, setListVisible] = useState(false);
  const toggleListVisible = () => setListVisible(!listVisible);

  return (
    <div onMouseEnter={onMouseEnter}>
      <div className={styles.title} onClick={toggleListVisible}>
        {title}
      </div>
      <ul className={styles.filterBrandsWrapper}>
        {items.map((brand: any, index: number) => (
          <li className={styles.brand} key={'level-3-item-' + index}>
            <Link to={brand.urlPath} onClick={onSelectClose}>
              {brand.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FilterBrandsDropDown;
