import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/BENLUX.svg";
import phone from "../../assets/phone.svg";
import americanExpress from "../../assets/American Express.svg";
import mastercard from "../../assets/Mastercard.svg";
import visa from "../../assets/Visa.svg";
import paypal from "../../assets/PayPal.svg";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import styles from "./FooterBottom.css";

type FooterBottomProps = {
  content: {
    columns: [
      {
        label: string;
        links: [
          {
            label: string;
            urlPath: string;
          }
        ];
      }
    ];
    customerService: {
      title: string;
      phone: string;
      description: string;
      titleShop: string;
      phoneShop: string;
      descriptionShop: string;
    };
    legalLinks: [
      {
        label: string;
        urlPath: string;
      }
    ];
  };
  trustpilotData: {
    profileUrl: string;
    ratingCount: number;
    ratingValue: number;
    bestRating: number;
    starsString: string;
    stars: number;
  };
};

function FooterBottom({ content, trustpilotData }: FooterBottomProps) {
  const { legalLinks, customerService, columns } = content;

  let aggregateRatingSchemaData = null;
  if (trustpilotData !== null) {
    aggregateRatingSchemaData = {
      "@context": "https://schema.org/",
      "@type": "AggregateRating",
      itemReviewed: {
        "@context": "http://schema.org",
        "@type": "Organization",
        name: "BENLUX",
        url: "https://www.benlux.fr",
      },
      ratingValue: trustpilotData.ratingValue.toString(),
      bestRating: "5",
      ratingCount: trustpilotData.ratingCount.toString(),
    };
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.blockLogo}>
          <img src={logo} alt="Benlux" />
          <p>
            174 rue de Rivoli
            <br />
            75001 Paris
          </p>
          <div className={styles.paymentMethods}>
            <img src={visa} alt="Visa" />
            <img src={mastercard} alt="MasterCard" />
            <img src={americanExpress} alt="AmericanExpress" />
            <img src={paypal} alt="PayPal" />
          </div>
        </div>
        {columns.map((column, index) => (
          <div className={styles.column} key={index}>
            <span>{column.label}</span>
            <ul>
              {column.links.map((link, index) => (
                <li key={index}>
                  <Link to={link.urlPath}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className={styles.column + " " + styles.customerServiceColumn}>
          <span>{customerService.titleShop}</span>
          <div className={styles.customerServicePhone}>
            <img src={phone} alt={""} />
            <span>{customerService.phoneShop}</span>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: customerService.descriptionShop,
            }}
          />
          <span>{customerService.title}</span>
          <div className={styles.customerServicePhone}>
            <img src={phone} alt={""} />
            <span>{customerService.phone}</span>
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: customerService.description }}
          />
        </div>
      </div>
      {/* TrustBox widget - Micro Combo */}
      {aggregateRatingSchemaData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(aggregateRatingSchemaData),
          }}
        />
      )}
      <div
        className="trustpilot-widget"
        data-locale="fr-FR"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="61e14bf0ca16dbea8e97dfa0"
        data-style-height="48px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://fr.trustpilot.com/review/benlux.fr"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
      {/* End TrustBox widget */}
      <div className={styles.otherLinks}>
        <ul>
          {legalLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.urlPath}>{link.label}</Link>
            </li>
          ))}
        </ul>
        <div className={styles.socialLinks}>
          <a
            href={"https://www.facebook.com/Benluxlouvre"}
            target={"_blank"}
            rel={"noopener"}
          >
            <img src={facebook} alt={"BENLUX sur Facebook"} />
          </a>
          <a
            href={"https://www.instagram.com/benluxparis"}
            target={"_blank"}
            rel={"noopener"}
          >
            <img src={instagram} alt={"BENLUX sur Instagram"} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
