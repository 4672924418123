import React from 'react';
import * as styles from './HelpSection.css';
import {HelpSectionRow} from "../index";
import {Link} from "react-router-dom";


type HelpSectionProps = {
  section: {
    title: string,
    thumbnail: string,
    contents: [
      {
        question: string,
        answer: string
      }
    ],
  };
};

function HelpSection({section}: HelpSectionProps) {
  return (
    <div className={styles.wrapper}>
      <Link to={'/aide'} className={styles.goBack}>Retourner à l'Aide</Link>
      <div className={styles.block}>
        <div className={styles.headerBlock}>
          <img src={section.thumbnail}/>
          <h2>{section.title}</h2>
        </div>
        {section.contents.map((section: any, index: number) =>
          <HelpSectionRow key={index} section={section}/>
        )}
      </div>
    </div>
  );
}

export default HelpSection;
