import React, {useState} from 'react';
import arrowfilters from "../../assets/filters-arrow.svg";
import * as styles from './SortDropdown.css';

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  items?: [];
  dropdown?: any;
  opened: boolean;
  onToggle: () => void;
};

function SortDropdown({value, items, onChange, dropdown, opened, onToggle}: Props) {
  const [toggledSortBy, setSortBy] = useState<number>(0)



  return (
    <div className={styles.sortFilter}>
      <div className={styles.containerSort}>
        <div
          className={styles.sortButton + ' ' + (opened ? styles.active : '')}
          onClick={onToggle}>
          <span>Trier</span>
          <img src={arrowfilters} alt={'filter'}/>
        </div>
        <div
          className={styles.sortContent + ' ' + (opened ? styles.active : '')}
        >
          {items?.map((item: any, index: number) => {

            return (
              <div
                key={index}
                className={styles.valueLabel + ' ' + (toggledSortBy === index ? styles.active : '')}
                onClick={() =>
                {onChange ? onChange(item) : '';
                setSortBy(index)
                }}
              >
                <div style={toggledSortBy === index ? {
                  backgroundColor: '#000000',
                  height: 28,
                  width: 4,
                  borderBottomRightRadius: 20,
                  borderTopRightRadius: 20,
                } : {}}/>
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default SortDropdown;
