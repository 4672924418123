import React from 'react';
import {Button, ButtonTypes} from "../index";
import styles from './ReviewForm.css';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import Input from "../Input";

type ReviewFormProps = {
  setFieldValue?: any;
  openedForm?: any;
  closeForm?: any;
};



function ReviewForm({setFieldValue, openedForm, closeForm}: ReviewFormProps) {
  const onChangeRating = (rating: number) => {
    setFieldValue('rating', rating);
  }

  return (
    <div>
      {openedForm &&
      <div style={{marginTop: 68}} className={styles.opinionForm}>
        <div className={styles.newReviewHeader}>
          <div>Donner mon avis sur ce produit</div>
        </div>
        <ReactStars
          size={25}
          edit={true}
          color={'#E2E2E2'}
          activeColor={'#EF394F'}
          onChange={(value: any) => onChangeRating(value)}
        />
        <div className={styles.opinionInputFlex}>
          <Input
            placeholder={'Pseudo'}
            onChange={(event: any) => setFieldValue('userName', event.target.value)}
          />
          <Input
            placeholder={'Votre avis en quelques mots*'}
            onChange={(event: any) => setFieldValue('summary', event.target.value)}
          />
        </div>
        <textarea
          placeholder={'Votre avis* \n \n' +
          'Veillez à parler de votre expérience personnelle par rapport au produit et non pas par rapport au service client. ' +
          'Si vous avez besoin de nous contacter, ' +
          'rendez-vous dans l’Aide. Ne placez pas d’informations personnelles, confidentielles, injurieuses ou de liens dans votre commentaire.'}
          onChange={(event: any) => setFieldValue('detail', event.target.value)}
        />
        <div className={styles.opinionFormButton}>
          <Button
            type={ButtonTypes.Tertiary}
            as={'button'}
            text={'Masquer'}
            onClick={closeForm}
          />
          <Button
            type={ButtonTypes.Secondary}
            as={'submit'}
            text={'Soumettre mon avis'}
            />
        </div>
      </div>
      }
    </div>
  );
}

export default ReviewForm;
