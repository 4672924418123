import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";

import styles from './Brands.css';

type Props = {
  brandsPerLetter?: [];
  brands?: any;
};

function brandsSort(aBrand: any, bBrand: any) {
  const aName = aBrand.name.toUpperCase();
  const bName = bBrand.name.toUpperCase();
  return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
}

export default function Brands({brands}: Props) {

  const [selectedFilter, setSelectedFilter] = useState({});
  const brandsPerLetter = {};
  const cosmetic = useRef(null)
  const mode = useRef(null)
  const jewelry = useRef(null)
  const onClickFilter = (id: any) => {
    setSelectedFilter(id);
  }

  let letterSectionsRefs = useRef([]);


  const scrollToLetter = (node: any) => {
    node.current.scrollIntoView({behavior: 'smooth'});
  }

  brands.forEach((brand: any, index: number) => {
    let firstCharacter = brand.name[0]
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();

    if (!firstCharacter.match("[A-Z]")) {
      firstCharacter = '#';
    }
    // @ts-ignore
    if (!brandsPerLetter[firstCharacter]) {
      // @ts-ignore
      brandsPerLetter[firstCharacter] = [];
    }
    // @ts-ignore
    brandsPerLetter[firstCharacter].push(brand);
  });

  const sortedLetters = Object.keys(brandsPerLetter).sort();

  letterSectionsRefs.current = sortedLetters.map(
    (ref, index) => letterSectionsRefs.current[index] = React.createRef()
  )

  return (
    <div>
        <div className={styles.filtersDiv}>
          <div className={styles.filtersTab}>
            <div className={styles.brandFilterSlug}>
              Toutes les marques
            </div>
            {/*<div className={styles.filters}>*/}
            {/*  <div id="1" ref={cosmetic} className={styles.filterBrand + ' ' + (selectedFilter === cosmetic ? styles.selectedFilter : '')} onClick={() => onClickFilter(cosmetic)}>*/}
            {/*    <span>Soins et cosmétiques</span>*/}
            {/*  </div>*/}
            {/*  <div id="2" ref={mode} className={styles.filterBrand + ' ' + (selectedFilter === mode ? styles.selectedFilter : '')} onClick={() => onClickFilter(mode)}>*/}
            {/*    <span>Accessoires, mode</span>*/}
            {/*  </div>*/}
            {/*  <div id="3" ref={jewelry} className={styles.filterBrand + ' ' + (selectedFilter === jewelry ? styles.selectedFilter : '')} onClick={() => onClickFilter(jewelry)}>*/}
            {/*    <span>Horlogerie et bijouterie</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
      </div>
      <div className={styles.brandContainer}>
        <div className={styles.letterChoice}>
          <span>De A à Z</span>
          {sortedLetters.map((letter, index) => (
            <button
              onClick={() => scrollToLetter(letterSectionsRefs.current[index])}
              className={styles.letter}
            >{
              letter}
            </button>
          ))}
        </div>
        <div className={styles.gridLetters}>
          {sortedLetters.map((letter, index) => (
            <div
              className={styles.brandLetter}
              key={index}
              ref={letterSectionsRefs.current[index]}
            >
              <span className={styles.letter}>{letter}</span>
              <ul className={styles.gridBrands}>
                {
                  // @ts-ignore
                  brandsPerLetter[letter].sort(brandsSort).map((brand: any, index: number) => (
                    <li key={index}>
                      <Link to={brand.urlPath}
                            className={styles.brandName}>{brand.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


